import React, { useState, useEffect } from 'react';
import momentJalaali from 'moment-jalaali';
import { Calendar } from 'react-datepicker2';
import { getCustomFormatDayJalali } from 'utils/timeFunctions';

const ReserveCalender = ({ consultationDays, closeDays, handlerSelect }) => {
	const [date, setDate] = useState();
	const [ranges, setRanges] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const now_moment = momentJalaali();
		setDate(now_moment);
		let tmp_closeDay = addAttributeDay(closeDays);
		let tmp_highlight = addAttributeDay(consultationDays, 'highlight');
		setRanges(tmp_closeDay.concat(tmp_highlight));
		setLoading(false);
	}, [consultationDays, closeDays]);

	const handlerSelectDay = (newDate) => {
		setDate({ newDate });
		handlerSelect(getCustomFormatDayJalali(newDate, false, 'jYYYY/jMM/jDD'));
	};

	const addAttributeDay = (days, type = 'disable', color = '#201C68') => {
		let result = days?.map((day) => {
			let option = {
				disabled: type === 'disable' ? true : false,
				start: momentJalaali(day, 'jYYYY/jM/jD').add(1, 'minutes'),
				end: momentJalaali(day, 'jYYYY/jM/jD').add(1, 'minutes'),
				color: type === 'disable' ? '' : color,
			};
			return option;
		});
		return result;
	};

	let enabledRange = {
		min: momentJalaali().add(-1, 'days'),
		max: momentJalaali().add(2, 'weeks'),
	};

	return (
		<div>
			{!loading && (
				<CalendarWrapper ranges={ranges} enabledRange={enabledRange} date={date} handlerSelectDay={handlerSelectDay} />
			)}
		</div>
	);
};

const CalendarWrapper = ({ ranges, enabledRange, date, handlerSelectDay }) => {
	return (
		<div>
			<Calendar
				ranges={ranges}
				min={enabledRange.min}
				max={enabledRange.max}
				value={date}
				isGregorian={false}
				showTodayButton={false}
				className="calender-button bg-reservePage-TimeSchedule"
				onChange={(newDate) => handlerSelectDay(newDate)}
			/>
		</div>
	);
};

export default ReserveCalender;
