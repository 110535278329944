import classnames from 'classnames';
import Avatar from 'react-avatar';
import image from '../../assets/icon/stream.png';

const ProfilePicture = ({ status, img, className, name = '', ...rest }) => {
	return (
		<div className={classnames('relative', className ? className : 'w-48 h-48')}>
			{img && img !== 'null' ? (
				<img className={classnames('rounded-md w-full h-full')} src={img} alt="user" />
			) : name.length > 0 ? (
				<Avatar color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])} size="80" name={name} src={image} />
			) : (
				<Avatar color={'#E7F4FF'} style={{ cursor: 'pointer' }} name={'?'} size="80" fgColor={'#201C68'} src={image} />
			)}
			{rest?.children && <div className="absolute bottom-0 right-0 h-12 w-12 z-2"> {rest.children} </div>}
		</div>
	);
};

export default ProfilePicture;
