import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
	fa: {
		accept: 'قبول',
		i_accept: 'موافقم',
		free: 'رایگان',
		monetary: 'پولی',
		crypto: 'کریپتو',
		general: 'عمومی',
		consultation: 'مشاوره',
		bourse: 'بورس',
		housing: 'مسکن',
		real_state: 'مشاور املاک',
		rate: 'امتیاز',
		total: 'تعداد',
		expert: 'مشاور',
		comment: 'نظر',
		comments: 'نظرات',
		your_comment: 'نظر شما',
		reply: 'پاسخ',
		replyOwn: 'پاسخ  {name}',
		analyzes: 'تحلیل‌ها',
		textual: 'متنی',
		telephonic: 'تلفنی',
		online_only: 'فقط آنلاین',
		search: 'سرچ کنید',
		search_for_your_needs: 'نیاز خود را جست و جو کنید',
		filter: 'فیلتر کردن',
		sort: 'مرتب سازی',
		by: 'بر اساس',
		show_content_by: 'نمایش محتوا بر اساس',
		send: 'ارسال',
		message: 'پیام',
		messages: 'پیام ها',
		send_message_to: 'ارسال پیام به',
		marked: 'نشان شده',
		mark: 'نشان کردن',
		oldest: 'قدیمی ترین',
		newest: 'جدید ترین',
		new: 'جدید',
		new_messages: 'پیام های جدید',
		past_messages: 'پیام های پیشین',
		both: 'هردو',
		bought: 'خریداری شده',
		dont_bought: 'خریداری نشده',
		see_all: 'مشاهده همه',
		pwa_welcome: '! سلام ! خوش اومدی به کوینزآن، خوشحال میشیم وب اپ ما رو ',
		asset_portfolio: 'سبد دارایی',
		profile_CopyTradingPage: {
			copy_course: 'بهترین سبدهای کوینزآن رو کپی کن',
			copy_course_caption: 'با خیال راحت توی بازار ریزشی هم سود کن',
			btn_copy_trade_learning: 'آموزش کامل کپی ترید',
			my_courses: 'سبدهای من',
			all_courses: 'مشاهده همه سبدهای کوینزآن',
		},
		breadcrumb: {
			home: 'خانه',
			experts: 'لیست مشاوران',
			academy: 'آکادمی',
		},
		call_expert: 'برقراری ارتباط تلفنی با مشاور',
		type_message: 'پیام خود را تایپ کنید',
		online: 'آنلاین',
		busy: 'مشغول',
		offline: 'آفلاین',
		waiting: 'درحال انتظار',
		reservations: 'مشاوره',
		about: 'درباره',
		fax: 'دور نگار',
		close: 'بستن',
		cancel: 'انصراف',
		return: 'بازگشت',
		return_trader: 'بازگشت به صفحه تریدر',
		return_home_page: 'بازگشت به خانه',
		submit: 'ثبت',
		confirm: 'تایید',
		edit: 'ویرایش',
		delete: 'حذف',
		continues: 'ادامه',
		register: 'ثبت نام',
		login_to_see: 'برای ادامه به حساب خود ورود کنید',
		see: 'کپی کن',
		number_courses: 'تعداد دوره ها',
		followers: 'تعداد دنبال کننده',
		current_profit: 'سود لحظه ای',
		step: 'مرحله {step}',
		number_trades: 'تعداد معاملات',
		calculator: {
			calculate: 'محاسبه',
			my_fund: 'سرمایه من ',
			initial_fund: 'سرمایه اولیه',
			investment: 'بعد از سرمایه گذاری در کوینزآن',
			dollar: 'دلار',
			period: 'مدت زمان سرمایه گذاری',
			choose: 'انتخاب ...',
			my_fund_after_period: 'سرمایه من بعد از {month} ماه:',
			warning: 'با پذیریش ریسک بازارهای مالی، احتمالا سرمایه شما به این عدد خواهد رسید',
			risk_taking: 'ریسک‌ پذیری',
			low: 'کم',
			medium: 'متوسط',
			high: 'زیاد',
		},
		expertPage: {
			see_all: 'مشاهده همه',
			top_expert_copytrading: 'برترین مشاوران کپی تریندینگ',
		},
		profileExpert: {
			security_call_experts:
				'تمامی مکالمات شما و مشاور و شماره تماس شما محرمانه باقی خواهد ماند. همه مشاورین پس از طی یک فرایند مشخص تایید شده اند',
			response_hours: 'ساعات پاسخگویی',
			metting: 'جلسه ی {val_time} {unit_time} ای {val_price} {unit_price}',
			efficiency_monthly: 'بازدهی ماهانه',
			efficiency_activity: 'بازدهی از زمان فعالیت',
			send_message_to_trader: 'ارسال پیام',
			efficiency: 'بازدهی',
		},
		footer: {
			slogan: 'قطب نمای مسیرسرمایه گذاری در دنیای بازار ها',
			site_right: 'تمامی حقوق این وبسایت متعلق به {0} می باشد',
		},
		traderCard: {
			transaction_risk: 'ریسک معلاملات',
			monthly_profit: 'بازدهی ماهانه',
			copy_btn: 'کپی کن',
			cart_num: 'تعداد سبد ها',
		},
		courseCard: {
			start_course: 'شروع سبد از',
			to_fill_capacity: 'نفر مانده تا تکمیل ظرفیت ',
			stage: 'سبد در استیج ',
			ended: 'پایان یافته در',
			period_days: 'در {number} روز',
			assets_under_management: 'سرمایه تحت مدیریت',
			assets_under_management_brief: 'دارایی تحت مدیریت',
			num_copy_users: 'تعداد کپی کنندگان',
			copy_users: 'کپی کنندگان',
			profit_expectation: 'سود انتظاری',
			loss_expectation: 'ضرر انتظاری',
			validation_course: 'سبد اعتبار سنجی',
			aum: 'تحت مدیریت',
			return_of_investment: {
				amonth: 'بازدهی 1 ماهه',
			},
		},
		experts: {
			title: 'تریدر های کوینزآن',
		},
		CopyTrading: {
			margin: 'مارجین',
			cart_size: 'اندازه فعلی سبد',
			capacity: 'ظرفیت',
			expected_profit: 'سود انتظاری',
			expected_losses: 'ضرر انتظاری',
			risk: 'میزان ریسک',
			course_status: 'وضعیت دوره',
			open_orders: 'معاملات باز',
			orders: 'سفارشات',
			transaction_history: 'تاریخچه معاملات',
			participants: 'افراد شرکت کننده',
			register_stage: 'ثبت نام برای استیج {number}',
			date_start: 'تاریخ شروع',
			date_end: 'تاریخ اتمام',
			max_damage: 'ماکسیمم  ضرر',
			start_amount: 'مبلغ ابتدایی استیج',
			end_amount: 'اندازه سبد در انتهای استیج',
			stage_number: 'stage : {stage_number}',
			during_date: 'از تاریخ {date_start} تا {date_end}',
			remainingـpeople: '+{number} نفر دیگر',
			participants_people: '{number} نفر شرکت کرده اند',
			pending: 'دوره های در انتظار',
			in_progress: 'دوره های در حال برگزاری',
			end: 'دوره های برگزار شده',
			pending_tab: 'در حال ثبت نام',
			in_progress_tab: 'در حال برگزاری',
			end_tab: 'برگزار شده',
			performance_consultant: 'عملکرد سالانه مشاور',
			entry_price: 'قیمت ورود',
			exit_price: 'قیمت خروج',
			profit: 'سود',
			top_price: 'حد سود',
			stop_loss_price: 'حد ضرر',
			price_now: 'قیمت لجظه ای',
			amount: 'اندازه',
			size: 'اندازه',
			value: 'مقدار',
			funds: 'اندازه معادل',
			date_order_time: 'تاریخ',
			order_time: 'زمان معامله',
			side: 'طرف',
			balance: 'اندازه',
			modified_date: 'آخرین تغییر',
			terms_and_condition: 'اساسنامه کپی تریدینگ',
			stage_over: 'این استیج به پایان رسیده است',
			assets_value: 'مقدار سبد',
			tether_size: 'موجودی (تتر)',
			assets: 'دارایی ها',
			price: 'قیمت',
		},
		timeline: 'نمودار پیشرفت',
		copy_trading: 'کپی تریدینگ',
		contents: 'محتوا',
		courses: 'دوره ها',
		latest_update: 'زمان آخرین آپدیت',
		latest_update_tooltip: 'اطلاعاتی که در این صفحه میبینید مربوط به این ساعت و تاریخ میباشند',
		latest_update_refresh_tooltip:
			'اطلاعاتی که در این صفحه میبینید مربوط به این ساعت و تاریخ میباشند.  برای بروزرسانی اطلاعات لطفا از دکمه ریفرش در همین قسمت استفاده کنید',
		start_courses: 'شروع دوره',
		profile: 'پروفایل',
		profile_fa: 'مشخصات',
		public_profile: 'مشخصات عمومی',
		perfect: 'عالی',
		intermediate: 'متوسط',
		short: 'کوتاه',
		long: 'بلند',
		bad: 'بد',
		NotFounded: {
			comment: 'نظری',
			content: 'محتوایی',
			message: 'پیامی',
			courses: 'سبدی',
			page: 'صفحه ای',
			trade: 'معامله ای',
			hours_booking: 'ساعتی برای رزرو',
			content_marked: 'محتوای نشان‌شده‌ای',
			expert_marked: 'مشاور نشان شده ای',
			bill: 'صورت حسابی',
			call: 'تماسی',
			not_found: '{0} برای نمایش وجود ندارد',
			bill: 'صورت حسابی برای نمایش وجود ندارد',
			toTraders: 'مشاهده‌ تریدرها',
			copytrade: 'هنوز تریدری را کپی نکرده‌اید',
		},
		time: {
			few_moments_ago: 'لحظاتی پیش',
			few_minutes_ago: 'چند دقیقه پیش',
			hours: 'ساعت',
			day: 'روز',
			yesterday: 'دیروز',
			today: 'امروز',
			tomorrow: 'فردا',
			past: '{val} {unit} پیش',
			minute: '{minute} دقیقه',
			the_minute: '{minute} دقیقه ای',
			hour: 'ساعت {value}',
			daily: 'روزانه',
			hourly: 'ساعتی',
		},
		response: 'پاسخ',
		expert_answer: 'پاسخ مشاور',
		and: 'و',
		my_calls: 'تماس های من',
		my_reserves: 'رزرو های من',
		reserve: 'رزرو',
		settings: 'تنظیمات',
		mails: 'پیام ها',
		bills: 'صورت حساب ها',
		enter_user: 'ورود',
		change_password: 'تغییر رمز عبور',
		change_phone: 'تغییر شماره همراه',
		account: 'حساب کاربری',
		my_account: 'حساب من',
		account_management: 'مدیریت حساب',
		course_management: 'مدیریت دوره',
		profileContent: {
			changePhoto: 'تغییر عکس',
			credit: 'مانده اعتبار : {0}',
			credit_charge: 'افزایش اعتبار',
			message_site: 'پیام های سایت به شما',
			marked_content: 'محتواهای نشان شده',
			marked_experts: 'مشاوران نشان شده',
			call_expert_one: 'تماس شما با کارشناس {expert} در ساعت {time}',
			call_expert_two: 'برقرار شده و تا ساعت {time} ادامه خواهد داشت',
			copy_trading: 'سبد سرمایه گذاری',
			consulting: 'مشاوره',
		},
		enter_counseling_room: 'ورود به اتاق مشاوره',
		password: 'رمز عبور ',
		passwords: 'رمز عبور ها',
		old_password: 'رمز عبور پیشین',
		new_password: 'رمز عبور جدید',
		confirm_password: 'تکرار رمز عبور',
		current_mobile: 'شماره همراه فعلی',
		new_mobile_number: 'شماره همراه جدید',
		send_activation_code: 'ارسال کد فعال سازی',
		activation_code: 'کد فعال سازی',
		first_name: 'نام',
		last_name: 'نام خانوادگی',
		email: 'ایمیل',
		email_address: 'آدرس ایمیل',
		card_number: 'شماره کارت',
		phone_number: 'شماره موبایل',
		pending_payment: 'در انتظار پرداخت',
		filed: 'با خطا روبه‌رو شده',
		on_hold: 'در انتظار',
		paid: 'پرداخت شده',
		expired: 'منقضی شده',
		invoice_number: 'شماره فاکتور : {0}',
		value_func: '{amount} {unit}',
		call_consulting: '{val} {unit} مشاوره',
		re_call: 'تماس مجدد',
		enter_payment_page: 'ورود به صفحه پرداخت',
		custom_amount: 'مبلغ دلخواه',
		password_placeholder: 'رمز عبور حساب کاربری خود را تعیین کنید',
		send_code: 'کد ۶ رقمی فعال‌سازی برای شما به شماره {phone_number} ارسال گردید',
		validation: {
			email: 'ایمیل معتبر نیست',
			required: 'پر کردن این فیلد الزامی است',
			max: 'تعداد کاراکتر های استفاده شده باید از {max} کمتر باشد',
			min: 'کاراکتر های استفاده شده باید از {min} بیشتر باشد',
			not_match: '{fields} ها با یک دیگر همخوانی ندارند',
			invalid: '{field} وارد شده معتبر نیست',
			duplicate: '{field} وارد شده تکراری است',
			characters_left: '{number} کاراکتر باقی مانده',
			expired_time: 'مدت زمان مجاز برای پر کردن فرم به پایان رسید. لطفا دوباره اقدام کنید',
			string: 'پر کردن این فیلد الزامی است',
		},
		input: {
			account: 'اطلاعات حساب کاربری خود را وارد کنید',
		},
		select_item: {
			account: 'حساب کاربری خود را انتخاب کنید',
		},
		label_input: {
			api_key: 'Api Key',
			pass_phrase: 'Pass phrase',
			api_secret: 'Api secret',
			account_name: 'account_name',
			receive_daraye: 'دریافت اطلاعیه‌ها از طریق پلتفرم کوینزآن',
			receive_email: 'دریافت اطلاعیه‌ها از طریق ایمیل',
			receive_sms: 'دریافت اطلاعیه‌ها از طریق پیامک',
			automatedـsales: 'خرید و فروش خودکار، همراه با معاملات معامله‌گر',
		},
		ordinal_number: {
			1: 'اول',
			2: 'دوم',
			3: 'سوم',
			4: 'چهارم',
			5: 'پنجم',
			6: 'ششم',
			7: 'هفتم',
			8: 'هشتم',
			9: 'نهم',
			10: 'دهم',
		},
		cardinal_number: {
			1: 'یک',
			2: 'دو',
			3: 'سه',
			4: 'چهار',
			5: 'پنج',
			6: 'شش',
			7: 'هفت',
			8: 'هشت',
			9: 'نه',
			10: 'ده',
		},
		timelineComponent: {
			see_more: 'مشاهده',
			damageـrange: 'محدوده ضرر',
		},
		permission: {
			see_more: 'برای مشاهده باید ثبت نام کنید',
		},
		sent: {
			success_code: 'پیام با موفقیت ارسال شد',
			comment: 'نظر شما فرستاده شد',
		},
		registerMessage: {
			success_reserve: 'رزرو شما با موفقیت ثبت شد!',
			success_signinPerid: 'ثبت نام شما در دوره باموفقیت انجام شد',
			already_registered: 'شما قبلا در دوره ثبت نام شده اید',
			error_number: 'پسورد شما باید شامل عدد هم باشد',
			error_string: 'پسورد شما باید شامل حروف بزرگ و کوچک هم باشد',
			error_special_chars: 'پسورد باید شامل حروف خاص(@,!,%,$,...) نیز باشد',
		},
		menu: {
			home_page: 'صفحه اول',
			stock_market: 'بازار سهام',
			expert: 'مشاوره',
			monitor: 'دیده بان',
			copy_trading: 'کپی تریدینگ',
			courses: 'دوره ها',
			content: 'مقالات مشاوران',
			publications: 'آکادمی کوینزآن',
			cryptocurrency: 'ارز دیجیتال',
			trader: 'معامله گر ها',
			traders: 'معامله‌گران',
			investment_courses: 'دوره های سرمایه گذاری',
			courses: 'سبدها',
			housing: 'مسکن',
			top_items: 'کیس های برتر',
			about_us: 'درباره ما',
			start_investing: 'از کجا شروع کنم؟',
		},

		auth: {
			disposable: {
				title: 'کد یکبار مصرف را وارد کنید',
				enter_with_pass: 'ورود با رمز عبور',
			},
			create_account: 'ایجاد حساب کاربری',
			password: 'رمز عبور',
			validation: {
				character_number: 'حداقل {num} کاراکتر وجود داشته باشد',
				captal_character: 'حداقل یک حرف بزرگ وجود داشته باشد',
				small_character: 'حداقل یک حرف کوچک وجود داشته باشد',
				number: 'حداقل یک عدد وجود داشته باشد',
			},
			email: {
				title: 'ورود با ایمیل',
				email: 'ایمیل',
				password: 'رمز عبور',
				login_with_phone: 'ورود با شماره موبایل',
			},
			extra_info: {
				title: 'اطلاعت تکمیلی',
				first_name: 'نام',
				last_name: 'نام خانوادگی',
				email: 'ایمیل',
				choose: 'انتخاب کنید',
			},
			password_login: {
				title: 'رمز عبور خود را وارد کنید',
				password: 'رمز عبور',
				disposable: 'ورود با رمز عبور یکبار مصرف',
			},
			title: 'ورود با حساب کاربری',
			signup: 'ثبت نام با ایمیل',
			login_hint: 'حساب نداری؟ ثبت نام کن',
			signup_hint: 'حساب داری؟ وارد شو',
			optional: 'اختیاری',
			phone_num: 'شماره موبایل',
			login: 'ورود',
			enter: 'ورود',
			change_phone_num: 'تغییر شماره همراه',
			change_email: 'تغییر ایمیل',
			enter_code: 'یک کد 6 رقمی به شماره {phone_number} ارسال شد، لطفا آن را وارد کنید',
			term_of_service: 'قوانین و مقررات کوینزآن',
			accept_term_of_service: 'را خوانده ام و با آن موافقم',
			start: 'شروع کنید',
		},
		reservePage: {
			time_reserve: 'از ساعت {from} الی {to}',
			short_time_reserve: '{from} الی {to}',
			consultationـtime: 'مدت زمان مشاوره',
			list_reservations_day: 'لیست رزرو های مشاوره در این روز',
			response_hours: 'ساعات پاسخگویی',
			date_consultation: 'تاریخ مشاوره',
			resultReserve: 'رزرو شما در تاریخ {0} {1} ثبت خواهد شد',
			select_start_consultation: 'ساعت شروع مشاوره را انتخاب کنید',
			choose_custom_date: 'انتخاب ساعت تاریخ دلخواه',
			return_quick_choice: 'برگشت به انتخاب سریع',
		},
		monitorPage: {
			mostGroupBuy: 'بیشترین خرید گروهی',
			mostPerCapitaBuy: 'بیشترین سرانه خرید',
			mostBuyPower: 'بیشترین قدرت خرید',
			mostPerCapitaSales: 'بیشترین سرانه فروش',
			lowestPurchasingBuy: 'کمترین قدرت خرید',
			buyHighVolume: 'خرید های درشت',
			salesHighVolume: 'فروش های درشت',
			time: 'زمان',
			symbol: 'نماد',
			totalBuy: 'مجموع خرید',
			perCapitaBuy: 'سرانه خرید',
			BuyerPowerToSeller: 'قدرت خریدار به فروشنده',
			totalSale: 'مجموع فروش',
			perCapitaSale: 'سرانه فروش',
			SellerPowerToBuyer: 'قدرت فروشنده به خریدار',
		},
		trader: {
			number_courses: 'تعداد دوره ها',
			followers: 'تعداد دنبال کننده',
			crypto: 'کریپتو',
			exchange: 'بورس',
			forex: 'فارکس',
			efficiency_monthly: 'بازدهی ماهانه',
			efficiency_activity: 'بازدهی از زمان فعالیت',
			see_all_periods: 'مشاهده همه سبد ها',
			courses_being_held: 'جدیدترین دوره های در حال برگزاری',
			meeting: 'مشاوره مجازی با تریدر',
			copy_trade: 'کپی کردن معاملات',
			teaching_copy_trade: 'آموزش قدم به قدم کپی ترید',
			trade_now: 'همین حالا معاملات کوکوین فیوچرز تریدر را کپی کنید',
			pages: {
				trader_performance: 'عملکرد تریدر',
				trades: 'معاملات',
				copiers: 'کپی کنندگان',
			},
			copiers_profit: 'سود کپی کنندگان',
			activation_time: 'مدت زمان فعالیت',
			win_rate: 'Win Rate',
			win_rate_tooltip: 'سود خالص پس از کسر کارمزد معاملات بر تعداد سفارشات',
			pnl_rate: 'Pnl Ratio',
			pnl_rate_tooltip: 'میانگین سود خالص حاصل از سفارشات سود ده تقسیم بر میانگین زیان خالص از تمام سفارشات بدون سود',
			risk: 'ریسک تریدر',
			risk_tooltip:
				'1. امتیاز ریسک 1 تا 5 بر اساس نوسان نرخ سود و زیان معامله گر در 30 روز گذشته رتبه بندی می شود. هر چه نمره بالاتر باشد، ریسک بالاتر است.',
			chart: {
				roi: 'ROI',
				assets: 'دارایی تریدر',
				a_month: '30 روز گذشته',
				week: '7 روز گذشته',
			},
			copy_users: {
				num: 'تعداد کپی کنندگان تا اکنون',
				active: 'تعداد کپی کنندگان فعال',
				profit: 'سود کپی کنندگان تا اکنون',
				title: 'کپی کنندگان',
			},
			copytradepage: {
				title: 'کپی معاملات برای سرمایه من',
				first_step: 'مرحله اول',
				copytrade_account: 'حساب کپی ترید',
				fund_to_connect: 'سرمایه ای که میخواهید متصل کنید',
				fund: 'از دارایی متصل نشده به حساب شما',
				min_fund: 'حداقل سرمایه لازم برای کپی کردن معاملات این تریدر {num}$ میباشد',
				second_step: 'مرحله دوم',
				loss_limit: {
					title: 'حد ضرر',
					desc: 'معاملات، تا حد ضرر تعیین شده برای سرمایه من، کپی شوند',
					check_desc: 'حد ضرر برای سرمایه شما',
					desc_end: 'حداقل ضرر برای کپی کردن معاملات این تریدر {num}% میباشد',
				},
				notifs: {
					title: 'اعلان ها',
					sms: 'دریافت اطلاعیه‌های کپی تریدینگ این تریدر از طریق پیامک',
					email: 'دریافت اطلاعیه‌های کپی تریدینگ این تریدر از طریق ایمیل',
					platform: 'دریافت اطلاعیه‌های کپی تریدینگ این تریدر از طریق پلتفرم کوینزآن',
				},
				third_step: 'مرحله سوم',
				accept_term_of_service_desc: 'با اساسنامه تریدینگ موافقت کنید',
				accept: 'موافقم',
				start_investing: 'سرمایه گذاری را شروع کنید',
				start_investing_desc: 'نکات مهم هنگام کپی کردن معاملات یک تریدر',
				start_investing_submit: 'شروع سرمایه گذاری',
			},
		},
		symbols: {
			colon: '{0}‌ :',
			percent: '% {0}',
		},
		percent_without_space: '{0}%',
		payment: {
			reservation: 'رزرو مشاوره',
			wallet_charge: 'شارژ کیف پول',
			payment_invoice: 'فاکتور پرداخت',
			description: 'توضیحات',
			select_gateway: 'انتخاب درگاه پرداخت',
			pay: 'پرداخت',
			billsPage: 'صفحه صورت حساب ها',
			type_service: 'نوع خدمت',
			date: 'تاریخ',
			hour: 'ساعت',
			invoice_number: 'شماره فاکتور',
			amount: 'مبلغ',
			total_amount: 'مبلغ نهایی',
		},
		currency: {
			toman: '{amount} تومان',
			dollar: '{amount} دلار',
			dollar_sign: '{amount} $',
			rial: '{amount} ﷼',
		},
		countingUnits: {
			peoples: '{count} نفر',
			cousrse: '{count} دوره',
		},
		add_new_api_key: 'افزودن کلید جدید',
		best_baskets: 'سبدهای برتر',
		daraya_baskets: 'سبدهای کوینزآن',
		person: 'نفر',
		all_portfolios: 'همه سبد‌ها',
		portfolio: 'سبد',
		unit_time_minute: 'دقیقه',
		unit_price_toman: 'هزار تومان',
		last_week_profit: 'سود هفت روز گذشته',
		portfolio_profit: 'سود سبد',
		today_profit: 'سود امروز',
		trade: 'معامله',
		portfolio_risk: 'ریسک سبد',
		positions: 'پوزیشن ها',
		history_trades: 'تاریخچه معاملات',
		orders: 'سفارشات',
		position_table: {
			symbol: 'نماد',
			size: 'اندازه',
			equivalent_size: 'اندازه معادل',
			entry_price: 'قیمت ورود',
			current_profit: 'سود لحظه ای',
			tpsl: 'TP/SL',
			time_trade: 'زمان معامله',
		},
		position_spot_table: {
			symbol: 'نماد',
			tether_size: 'اندازه تتر',
			balance: 'موجودی',
		},
		history_spot_table: {
			price: 'قیمت',
			order_time: 'زمان سفارش',
		},
		order_table: {
			order_price: 'قیمت سفارش',
		},
		history_table: {
			final_profit: 'سود نهایی',
			opening_time: 'زمان باز شدن',
			closing_time: 'زمان بسته شدن',
		},
		assets_table: {
			percent_portfolio: 'درصد از سبد',
			aum: 'میزان دارایی',
		},
		number: 'عدد',
		apostrofo: '',
		best_portfolio_expert: 'سبد‌های برتر {username}',
		notifications: 'اعلانات',
		read_all: 'خواندن همه',
		no_notification: '!اعلانی وجود ندارد',
		day_num: '{day} روز',
		week_num: '{week} هفته',
		month_num: '{month} ماه',
		year_num: '{year} سال',
		resend_code_auth: 'ارسال مجدد کد فعال‌سازی',
		more: 'بیشتر',
		less: 'کمتر',
		sidebar: {
			title: 'کپی تریدینگ کوینزآن',
			home: 'خانه',
			my_portfolio: 'سبد کپی ترید من',
			my_wallet: 'کیف پول من',
			traders: 'تریدر‌ها',
			consultations: 'مشاوره‌ها',
			support: 'پشتیبانی',
			academy: 'آکادمی',
			how_to_use: 'نحوه استفاده',
			invitation: 'دعوت از دوستان',
			settings: 'تنظیمات',
			q_trader: 'تریدر هستید؟',
			logout: 'خروج از حساب',
			my_portfolio_short: 'سبد',
			finance: 'پرداخت',
		},
		crypto_exchange: {
			kukoin: 'کوکوین',
			binance: 'بایننس',
			kukoin_spot: 'کوکوین اسپات',
			kukoin_future: 'کوکوین فیوچرز',
			spot: 'اسپات',
			future: 'فیوچرز',
		},
		traders: {
			picked_for_u: 'انتخاب شده برای شما',
			chosen_for_u: 'انتخاب شده برای شما',
			best_traders: 'تریدرهای برتر کوینزآن',
			astronomical_profit: 'برای سود‌های نجومی',
			astronomical_profile_tooltip:
				'توجه داشته باشید که با کیف پول کوینزآن میتوانید تمامی تریدرها را برای خود کپی کنید. در غیر این صورت شما میتونید تنها تریدرهایی را کپی کنید که در آن بازار یک کیف پول دارید',
			exciting_traders: 'تریدرهای هیجان انگیر',
			continuous_investment: 'برای سرمایه گذاری مستمر',
			cautious_traders: 'تریدرهای با احتیاط',
			all_traders: 'همه تریدر‌ها',
			traders_tooltip_your_choose:
				'توجه داشته باشید که با کیف پول کوینزآن میتوانید تمامی تریدرها را برای خود کپی کنید. در غیر این صورت شما میتونید تنها تریدرهایی را کپی کنید که در آن بازار یک کیف پول دارید',
			filter_chips: {
				highest_rank: 'بالاترین رتبه',
				most_aum: 'بیشترین سرمایه تحت مدیریت',
				most_users: 'بیشترین تعداد کپی کننده',
				most_monthly_return: 'بیشترین بازدهی ماهانه',
				most_risk: 'بیشترین ریسک',
				least_risk: 'کمترین ریسک',
			},
		},
		my_wallet: {
			title: 'کیف پول های کپی تریدینگ',
			title_tooltip:
				'اگر میخواهید معاملات تریدرهای دارایا را برای خودتان کپی کنید، ابتدا باید سرمایه‌تان در صرافی های معتبر را به ما متصل کنید یا اینکه آن را در کیف پول دارایا نگه دارید تا معاملات برای سرمایه شما کپی شود',
			wallet: 'کیف پول',
			total_balance: 'کل موجودی',
			fund: 'سرمایه متصل',
			fund_tooltip:
				'این مبلغ شامل سرمایه ای هست که درگیر کپی ترید میباشد. برای آزاد کردن و برداشت از حسابتان میبایست اول کپی ترید های سرمایه مودر نظر خود را متوقف کنید. همچنین در صورت کمتر شدن سرمایه در حساب صرافی شما از سرمایه متصل، تمام کپی ترید های مربوط به کیف پول به صورت خودکار متوقف میشوند.',
			status: 'وضعیت',
			operation: 'عملیات',
			charge: 'شارژ حساب',
			detail: 'جزئیات حساب',
			stop: 'توقف',
			connect_wallet: 'اتصال حساب',
			wallets: {
				daraya: 'کیف پول کوینزآن',
				kukoin: 'کوکوین',
				kukoin_spot: 'کوکوین اسپات',
				kukoin_future: 'کوکوین فیوچرز',
				binance_spot: 'بایننس اسپات',
				binance_future: 'بایننس فیوچرز',
				binance: 'بایننس',
			},
			connect_status: 'متصل',
			disconnect_status: 'عدم اتصال',
			add_wallet: 'افزودن کیف پول جدید',
			add_wallet_tooltip:
				'درصورتی که نیاز به کیف پول های متفاوت در هر یک از صرافی ها احتیاج داشتید، کیف پول جدیدی برای صرافی مورد نظرتان بسازید',
			bills: 'صورت حساب ها',
			number: 'شماره',
			for_what: 'بابت',
			date: 'تاریخ',
			price: 'مبلغ',
			status: 'وضعیت',
			factor_number: 'شماره فاکتور',
			bills: {
				title: 'صورت حساب ها',
				number: 'شماره',
				for: 'بابت',
				date: 'تاریخ',
				cost: 'مبلغ',
				status: 'وضعیت',
				factor_number: 'شماره فاکتور',
				currency: 'تومان',
				successful: 'موفق',
				unsuccessful: 'ناموفق',
				pending: 'در انتظار',
			},
			card_desc:
				'موجودی قابل برداشت شما شامل مبلغی میباشد که در هیچ حساب کپی تریدینگی متصل نیست و کارمزد کوینزآن از آن کسر شده است',
			card_submit: 'ورودی به صرافی',
		},
		settings_page: {
			individual_profile: {
				title: 'مشخصات فردی',
				edit: 'ویرایش',
				submit: 'ثبت تغییرات',
			},
			account_security: {
				title: 'امنیت حساب',
				phone_num: 'شماره همراه',
				change_phone_num: 'تغییر شماره همراه',
				email: 'ایمیل',
				change_email: 'تغییر ایمیل',
				password: 'رمز عبور',
				change_password: 'تغییر رمز عبور',
			},
			change_phone: {
				title: 'ویرایش شماره همراه',
				current_phone: 'شماره همراه فعلی',
				new_phone: 'شماره همراه جدید',
				code: 'کد فعالسازی',
				send_code: 'ارسال کد فعال‌سازی',
				desc: 'کد فعال‌سازی به شماره فعلی ارسال می‌شود',
			},
			change_email: {
				title: 'ویرایش ایمیل',
				current_email: 'ایمیل فعلی',
				new_email: 'ایمیل جدید',
				code: 'کد فعالسازی',
				send_code: 'ارسال کد فعال‌سازی',
				desc: 'کد فعال‌سازی به شماره فعلی ارسال می‌شود',
			},
			change_password: {
				title: 'ویرایش رمز عبور',
				current_password: 'رمز عبور فعلی',
				new_password: 'رمز عبور جدید',
				repeat_password: 'تکرار رمز عبور جدید',
			},
			submit: 'تایید',
		},
		dialog_connect: {
			title: 'اتصال حساب {account} به کوینزآن',
			title_1: ' اتصال حساب',
			title_2: 'به کوینزآن ',
			submit: 'اتصال',
			help: 'آموزش اتصال {account} به کوینزآن',
		},
		my_copyTrade_portfolio: {
			total_fund: 'کل دارایی',
			total_fund_tooltip:
				'این مبلغ شامل تمام دارایی شما در کیف پولتان اعم از دارایی متصل به کپی تریدینگ و دارایی غیر متصل به کپی  تریدینگ میباشد',
			connected_fund: 'دارایی متصل در کپی تریدینگ',
			connected_fund_tooltip: 'این مبلغ شامل تمام مبلغی میباشد که به یک یا چند تریدر متصل است و معاملات روی آن کپی میشوند',
			total_profit: 'سود کل',
			total_profit_tooltip: 'میزان و درصد رشد سرمایه شما از ابتدای فعالیت تا این لحظه',
			profit_at: 'سود در',
			profit_selector: {
				all: 'همه فعالیت ها',
				month: '30 روز گذشته',
				week: '7 روز گذشته',
			},
			profit_selector_tooltip: 'میزان و درصد رشد سرمایه شما از بازه زمانی مشخص شده تا این لحظه',
			active_copy_trade: 'کپی ترید‌های فعال',
			table: {
				name: 'نام',
				trader: 'تریدر',
				market: 'بازار',
				start_date: 'تاریخ شروع',
				initial_fund: 'سرمایه اولیه',
				current_fund: 'دارایی فعلی من',
				loss_limit: 'حد ضرر',
				profit_loss: 'سود و زیان',
				operation: 'عملیات',
				detail: 'مشاهده جزییات',
			},
			total_report: 'گزارش کلی عملکردت رو اینجا ببین',
			total_report_tooltip:
				'گزارش های عملکرد میتوانند در یک بازه زمانی عملکرد شما و تمام تریدرهایی که آنها را کپی کرده اید یکجا نشان دهد',
			total_report_btn: 'گزارش عملکرد سبد من',
			previous_copy_trade: 'مشاهده کپی ترید‌های پیشین',
			previous_copy_trade_title: 'کپی ترید‌های پیشین',
		},
		my_trades: 'معاملات من',
		my_trades_tooltip: 'لیست معاملات کپی شده برای شما به همراه جزئیات و تریدر مرتبط به آن',
		my_portfolio: 'سبد دارایی من',
		home: {
			before: 'قبل از هر چیز',
			discover_risk: 'ریسک پذیری خودت رو کشف کن',
			calculatation: 'محاسبه',
			risk_taking: 'ریسک پذیری',
			risk_taking_tooltip:
				'پروفایل ریسک پذیری شما کمک میکند تا علاوه بر شناخت رفتار سرمایه گذاری خود، تریدرهای همسو با خودتان را پیدا و معاملاتشان را کپی کنید',
			me: 'من',
			evalutation_desc:
				'با کامل کردن آزمون این بخش، سبدهای متناسب با خودت رو پیدا کن و بهترین سرمایه گذاری رو برای خودت رقم بزن',
			start: 'شروع آزمون',
			see_all_traders: 'مشاهده همه تریدرهای کوینزآن',
			best_traders: 'بهترین تریدرهای کوینزآن',
			best_papers: 'مقالات مهم برای شروع سرمایه گذاری',
			database: 'ورود به آکادمی کوینزآن',
			papers: {
				0: 'نحوه شرکت در معاملات Coins On ، گام به گام',
				1: 'واریز به Coins on از طریق Kucoin',
				2: 'چگونه از یک معامله گر کپی کنیم؟',
				3: 'نحوه ویرایش یا توقف معاملات کپی',
				4: 'مدیریت سرمایه در Coins on',
			},
			beginner: 'مبتدی',
			first_step: 'قدم اول سرمایه گذاری ',
			first_step_desc: 'یکی از کیف پول هات رو شارژ کن',
			darya_wallet: 'موجودی کیف پول کوینزآن',
			kukoin_wallet: 'موجودی کیف پول کوکوین',
			binance: 'موجودی کیف پول بایننس',
			disconnect: 'عدم اتصال',
			increase_balance: 'افزایش موجودی',
			learn_connect_daraya: 'آموزش اتصال صرافی به کوینزآن',
			restart: 'برگزاری مجدد آزمون',
			next_step: 'قدم بعدی سرمایه گذاری ',
			next_step_desc: 'یک تریدر انتخاب کن و معاملاتش رو کپی کن',
			login_exchange: 'ورود به صرافی',
			four_step_desc: 'کارمزد کپی تریدت رو بپرداز تا بتونی به کپی کردن معاملات ادامه بدی',
			pay_commision: 'پرداخت کارمزد سبدگردانی',
			pay_commision_desc: 'در صورت عدم پرداخت، امکان ادامه سرمایه گذاری در سبد را ندارید',
			pay_commision_desc_not_found: 'در حال حاضر نیازی به کارمزد کمیسیون نیست',
			sum_pay: 'مجموع مبلغ قابل پرداخت',
			time_pay: 'مهلت پرداخت تا',
			pay_detail: 'جزئیات محاسبه کارمزد',
			pay_commision_submit: 'پرداخت کارمزد',
			pay_footer: 'هزینه استفاده از سرویس کوینزآن چقدر است؟',
			pay_automatic: 'برداشت خودکار کارمزد از کیف پول',
			invitation_title: 'دوستانت رو به کوینزآن دعوت کن و سهمی از کارمزد پرداختی توسط اونها داشته باش',
			invitation_next_step: 'قدم بعدی ',
			my_proper_traders: 'تریدرهای مناسب برای من',
			invite_friends: 'دعوت دوستان',
		},
		risk: 'ریسک',
		favorite_account: 'نام حساب (دلخواه)',
		default_favorite_account: 'حساب شماره 1',
		people: 'نفر',
		update: 'به روز رسانی',
		back: 'بازگشت',
		pay: {
			daraya_wallet: 'موجودی کیف پول کوینزآن',
			daraya_wallet_tooltip:
				'موجودی کیف پول دارایا شامل میزان تتری میباشد که در صرافی دارایا خریداری کرده اید و میتوانید معاملات تریدرها را روی آن کپی کنید',
			daraya_cash: 'موجودی قابل برداشت از کیف پول کوینزآن',
			daraya_cash_tooltip:
				'این مبلغ شامل سرمایه ای هست که در کیف پول شما وجود دارد و به هیچ تریدری متصل نیست. در صورت نیاز میتوانید تمام سرمایه خود را با متوقف کردن کپی ترید ها  به موجودی قابل برداشت تبدیل کنید',
			fund_connected: 'دارایی متصل در کپی تریدینگ',
			fund_connected_tooltip:
				'این مبلغ شامل سرمایه ای هست که درگیر کپی ترید میباشد. برای آزاد کردن و برداشت از حسابتان میبایست اول کپی ترید های سرمایه مودر نظر خود را متوقف کنید. همچنین در صورت کمتر شدن سرمایه در حساب صرافی شما از سرمایه متصل، تمام کپی ترید های مربوط به کیف پول به صورت خودکار متوقف میشوند.',
			tether_price: 'قیمت تتر',
			rial_tether: 'تبدیل ریال به تتر',
			tether_rial: 'انتقال تتر از کیف پول',
			tether_label: 'مقدار تتر مورد نیاز',
			wallet: 'کیف پول',
			transfer_daraya: 'انتقال به کیف پول کوینزآن',
			payable: 'مبلغ قابل پرداخت',
			wage: 'با احتساب {num} تومان کارمزد',
			transfer: 'انتقال',
			amount_tranfer: 'مبلغ انتقال',
			bank_transfer: 'انتقال به حساب بانکی',
			shaba: 'به شماره شبا',
			name: 'به نام',
			payment_reserve: 'رزرو پرداخت',
		},
		quiz: {
			intial_desc: 'با کامل کردن آزمون این بخش، تریدرهای متناسب با خودت رو پیدا کن و بهترین سرمایه گذاری رو برای خودت رقم بزن',
			result: ' نتیجه آزمون شما',
			start: 'شروع کنید',
			complete_after: 'بعدا تکمیل میکنم',
			next: 'بعدی',
			back: 'بازگشت به قبلی',
			restart: 'شروع مجدد',
			show_traders: 'مشاهده تریدر‌ها',
		},
		trader_report: {
			performance: 'عملکرد',
			market: 'بازار',
			start_date: 'تاریخ شروع',
			loss_limit: 'حد ضرر',
			edit_copy_trade: 'ویرایش کپی ترید',
			stop: 'توقف',
			rate_desc: 'به مدیریت سرمایه من چه امتیازی می‌‌دهید؟',
			rate_desc_2: '(شروع کپی تریدینگ از یک هفته پیش)',
			efficiency: 'بازدهی',
			profit_made: 'سود ساخته شده',
			initial_fund: 'سرمایه اولیه',
			transaction_number: 'تعداد معاملات',
			transactions_done: 'معاملات انجام شده',
		},
		question: ' سوال',
		select_trade_account: 'مقدار',
		mostafa_jadidi: 'مصطفی جدیدی',
		archives: 'آرشیو ها',
		bigger: 'از دارایی های شما بیشتر است',
		paymentGateway: {
			title: 'پرداخت کارمزد کپی تریدینگ',
			desc: 'پرداخت کارمزد کپی تریدینگ از دو روش زیر امکان پذیر است',
			first_method: 'روش اول | پرداخت تتری',
			first_method_desc: 'را به کیف پول زیر از طریق صرافی خود انتقال دهید',
			price: 'مبلغ',
			network: 'شبکه TRC20',
			copy: 'کپی کردن',
			qr_code: 'QR کد',
			pay_tether_desc:
				'پس از واریز، روی دکمه "پرداخت تتری انجام شد" کلیک نمایید تا وضعیت پرداخت شما توسط ادمین دارایا بررسی شود',
			pay_tether_btn: 'پرداخت تتری انجام شد',
			second_method: 'روش دوم | پرداخت از طریق درگاه پرداخت',
			second_method_desc: 'تومان را از طریق درگاه پرداخت زیر پرداخت نمایید',
			second_method_btn: 'انتقال به درگاه پرداخت',
			second_price: 'مبلغ',
		},
		new_wallet: 'کیف پول جدید',
		referal: 'کد معرف',
		help: {
			copytrade: 'می‌توانید APIهای صرافی خود را متصل کنید تا معاملات معامله‌گران از طریق API برای شما انجام شود',
		},
		error: {
			add_wallet: 'لطفا مقادیر صحیح وارد کنید',
		},
	},
	en: {
		referal: 'Referal code',
		new_wallet: 'New wallet',
		paymentGateway: {
			title: 'Payment of copy trading fee',
			desc: 'Payment of the copy trading fee is possible in the following two ways',
			first_method: 'The first method | Tether payment',
			first_method_desc: 'to the wallet below via your exchange',
			price: 'Transfer',
			network: 'TRC20 Network',
			copy: 'Copy',
			qr_code: 'QR code',
			pay_tether_desc:
				'After depositing, click on the "Tetri payment is done" button so that your payment status will be checked by the asset administrator.',
			pay_tether_btn: 'Tether payment is done',
			second_method: 'The second method | Payment via payment gateway',
			second_method_desc: 'tomans through the following payment gateway',
			second_method_btn: 'Move to payment gateway',
			second_price: 'Pay the amount of',
		},
		bigger: 'Bigger than your assets',
		archives: 'Archives',
		mostafa_jadidi: 'Mostafa Jadidi',
		select_trade_account: 'Amount',
		question: 'Question ',
		trader_report: {
			performance: 'Performance',
			market: 'Market',
			start_date: 'Start date',
			loss_limit: 'Loss limit',
			edit_copy_trade: 'Edit copytrade',
			stop: 'Stop',
			rate_desc: 'How do you rate my capital management?',
			rate_desc_2: '(copy trading started a week ago)',
			efficiency: 'Profit',
			profit_made: 'Profit made',
			initial_fund: 'Initial fund',
			transaction_number: 'Number of transactions',
			transactions_done: 'Done transactions',
		},
		quiz: {
			intial_desc:
				'By completing the test in this section, find traders that suit you and make the best investment for yourself.',
			result: 'Your result is: ',
			start: 'Start',
			complete_after: 'Complete after',
			next: 'Next',
			back: 'Back',
			restart: 'Restart',
			show_traders: 'Viewing suitable traders for you',
		},
		pay: {
			daraya_wallet: 'Balance of CoinsON Wallet',
			daraya_wallet_tooltip:
				"The balance of CoinsON wallet includes the amount of Tether that you bought in Daraa exchange and you can copy traders' transactions on it.",
			daraya_cash: 'Balance that can be withdrawn from the Coins ON wallet',
			daraya_cash_tooltip:
				'This amount includes the capital that is in your wallet and is not connected to any trader. If needed, you can turn all your capital into withdrawable balance by stopping copy trades',
			fund_connected: 'Connected assets in copy trading',
			fund_connected_tooltip:
				'This amount includes the capital involved in copy trade. To release and withdraw from your account, you must first stop the copy trades of your desired capital. Also, if the capital in your exchange account is less than the connected capital, all copy trades related to the wallet will be stopped automatically.',
			tether_price: 'Tether price',
			rial_tether: 'Convert Rial to Tether',
			tether_rial: 'Tether transfer from wallet',
			tether_label: 'Required Tether Amount',
			transfer_volt: 'Transfer to Volts',
			transfer_daraya: 'Transfer to Assets Wallet',
			payable: 'The amount payable',
			wage: 'Including {num} tomans fee',
			transfer: 'The transfer',
			amount_tranfer: 'Transfer amount',
			bank_transfer: 'Transfer to bank account',
			shaba: 'To the number of Shaba',
			name: 'Name',
			wallet: 'Wallet',
		},
		back: 'Back',
		update: 'Update',
		people: 'people',
		default_favorite_account: 'Account #1',
		favorite_account: 'Favorite account',
		risk: 'risk',
		home: {
			before: 'Before Anything',
			discover_risk: 'Discover your risk tolerance',
			calculatation: 'Calculate',
			risk_taking: 'risk tolerance',
			risk_taking_tooltip:
				'Your risk tolerance profile helps you to find traders who are similar to you and copy their trades in addition to knowing your investment behavior.',
			me: 'my',
			evalutation_desc:
				'By completing the test in this section, find the baskets that suit you and make the best investment for yourself.',
			start: 'start test',
			see_all_traders: 'See all CoinsON traders',
			best_traders: 'See best Coins ON traders',
			best_papers: 'Important articles to start investing',
			database: 'Login to Coins ON knowledge base',
			papers: {
				0: 'How to participate in Coins On copy trading, step by step​',
				1: 'Deposit to Coins On Via Kucoin',
				2: 'How To Copy a Trader?',
				3: 'How To Edit or Stop Copy Trades',
				4: 'Capital Management In Coins On',
			},
			beginner: 'Beginner',
			first_step: 'The first step of investment',
			first_step_desc: 'Charge one of your wallets',
			darya_wallet: 'Balance of CoinsON Wallet',
			kukoin_wallet: 'KuCoin wallet balance',
			binance: 'Binance wallet balance',
			disconnect: 'no connection',
			increase_balance: 'increase inventory',
			learn_connect_daraya: 'Tutorial',
			restart: 'Retake the test',
			next_step: 'The next step of investment',
			next_step_desc: 'Choose a trader and copy their trades',
			login_exchange: 'Log in to the exchange',
			four_step_desc: 'Pay your copy trade fee so you can continue copying trades',
			pay_commision: 'Commission',
			pay_commision_desc: 'Pay commission before the due date to get copy trading going',
			pay_commision_desc_not_found: 'Enjoy! No commission fees are required at this time',
			sum_pay: 'Total Amount Payable',
			time_pay: 'payment deadline until',
			pay_detail: 'Fee calculation details',
			pay_commision_submit: 'Fee payment',
			pay_footer: 'How much does it cost to use the asset service?',
			pay_automatic: 'Automatic withdrawal of fees from the wallet',
			invitation_title: 'Invite your friends to Coins ON and have a share of the fees paid by them',
			invitation_next_step: 'Next step',
			my_proper_traders: 'Suitable traders for me',
			invite_friends: 'Invite friends',
		},
		my_trades: 'My Trades',
		my_trades_tooltip: 'List of trades copied for you along with the details and the trader associated with it',
		my_portfolio: 'My Portfolio',
		my_copyTrade_portfolio: {
			total_fund: 'Total Asset',
			total_fund_tooltip:
				'This amount includes all your assets in your wallet, including assets connected to copy trading and assets not connected to copy trading.',
			connected_fund: 'Copy Trading Asset',
			connected_fund_tooltip:
				'This amount includes all the amount that is connected to one or more traders and transactions are copied on it',
			total_profit: 'Total Profit',
			total_profit_tooltip:
				'The amount and percentage of your capital growth from the beginning of your activity to this moment',
			profit_at: 'Profit',
			profit_selector: {
				all: 'All activities',
				month: 'Last 30 days',
				week: 'Last 7 days',
			},
			profit_selector_tooltip:
				'The amount and percentage of your capital growth from the specified time period until this moment',
			active_copy_trade: 'Active copytrades',
			table: {
				name: 'Name',
				trader: 'trader',
				market: 'market',
				start_date: 'start date',
				initial_fund: 'Initial fund',
				current_fund: 'current fund',
				loss_limit: 'loss limit',
				profit_loss: 'profit loss',
				operation: 'operation',
				detail: 'see detail',
			},
			total_report: 'See the general report of your performance here',
			total_report_tooltip: 'Performance reports can show your performance and all the traders you have copied at one time',
			total_report_btn: 'My portfolio performance report',
			previous_copy_trade: 'View previous copytrades',
			previous_copy_trade_title: 'Previous copytrades',
		},
		dialog_connect: {
			title: 'Connecting {account} account to Coins ON',
			title_1: 'Connecting ',
			title_2: ' account to Coins ON',
			submit: 'Connect',
			help: 'Learning to connect {account} to Coins ON',
		},
		my_wallet: {
			title: 'Copy trading wallets',
			title_tooltip:
				'If you want to copy the trades of asset traders for yourself, you must first connect your capital to us in reputable exchanges or keep it in the asset wallet so that the trades are copied for your capital.',
			wallet: 'wallet',
			total_balance: 'total balance',
			fund: 'connected fund',
			fund_tooltip:
				'This amount includes the capital involved in copy trade. To release and withdraw from your account, you must first stop the copy trades of your desired capital. Also, if the capital in your exchange account is less than the connected capital, all copy trades related to the wallet will be stopped automatically.',
			status: 'status',
			operation: 'operation',
			charge: 'Charge Account',
			detail: 'Detail Account',
			stop: 'Stop',
			connect_wallet: 'Connect account',
			wallets: {
				daraya: 'Coins On',
				kukoin: 'KuCoin',
				kukoin_spot: 'KuCoin Spot',
				kukoin_future: 'KuCoin Futures',
				binance: 'binance',
				binance_spot: 'Binance Spot',
				binance_future: 'Binance Futures',
			},
			connect_status: 'connected',
			disconnect_status: 'disconnected',
			add_wallet: 'Add new wallet',
			add_wallet_tooltip: 'If you need different wallets in each exchange, create a new wallet for the exchange you want.',
			bills: 'Bills',
			number: 'number',
			for_what: 'for',
			date: 'date',
			price: 'price',
			status: 'status',
			factor_number: 'factor number',
			bills: {
				title: 'Bills',
				number: 'Number',
				for: 'For',
				date: 'Date',
				cost: 'Amount',
				status: 'Status',
				factor_number: 'Factor number',
				currency: 'Tooman',
				successful: 'successful',
				unsuccessful: 'Unsuccessful',
				pending: 'Pending',
			},
			card_desc:
				'Your withdrawable balance includes the amount that is not connected in any copy trading account and the asset fee has been deducted from it',
			card_submit: 'Go to Exchange',
		},
		settings_page: {
			individual_profile: {
				title: 'Personal Information',
				edit: 'Choose photo',
				submit: 'Submit',
			},
			account_security: {
				title: 'Account security',
				phone_num: 'Phone number',
				change_phone_num: 'Change Number',
				email: 'Email',
				change_email: 'Change email',
				password: 'Password',
				change_password: 'Change password',
			},
			change_phone: {
				title: 'Edit phone number',
				current_phone: 'Current phone number',
				new_phone: 'New phone number',
				code: 'Activation code',
				send_code: 'Send activation code',
				desc: 'The activation code will be sent to the current number',
			},
			change_email: {
				title: 'Edit email',
				current_email: 'Current email',
				new_email: 'New email',
				code: 'Activation code',
				send_code: 'Send Activation code',
				desc: 'The activation code will be sent to the current number',
			},
			change_password: {
				title: 'Edit password',
				current_password: 'Current password',
				new_password: 'New password',
				repeat_password: 'Repeat new password',
			},
			submit: 'Submit',
		},
		traders: {
			picked_for_u: 'Picked for you',
			chosen_for_u: 'Top Traders',
			best_traders: 'Top CoinsON Traders',
			astronomical_profit: 'For Astronomical Profits',
			astronomical_profile_tooltip:
				'Note that you can copy all traders for yourself with the assets wallet. Otherwise, you can only copy traders in which market you have a wallet',
			exciting_traders: 'Exciting Traders',
			continuous_investment: 'For continuous investment',
			cautious_traders: 'Cautious Traders',
			all_traders: 'All Traders',
			traders_tooltip_your_choose:
				'Note that you can copy all traders for yourself with the assets wallet. Otherwise, you can only copy traders in which market you have a wallet',
			filter_chips: {
				highest_rank: 'The highest rank',
				most_aum: 'The most assets',
				most_users: 'The most copiers',
				most_monthly_return: 'The highest monthly return',
				most_risk: 'The highest risk',
				least_risk: 'The least risk',
			},
		},
		crypto_exchange: {
			kukoin: 'KuCoin',
			binance: 'binance',
			kukoin_spot: 'KuCoin spot',
			kukoin_future: 'KuCoin futures',
			spot: 'Spot',
			future: 'Futures',
		},
		sidebar: {
			title: 'Coins ON Copy Trading',
			home: 'Home',
			my_portfolio: 'Copy Trading',
			my_wallet: 'My Wallet',
			traders: 'Traders',
			consultations: 'Consultations',
			support: 'Support',
			academy: 'Academy',
			how_to_use: 'Help Center',
			invitation: 'Invite Your Friends',
			settings: 'Settings',
			q_trader: "I'm a Trader",
			logout: 'Log Out',
			my_portfolio_short: 'portfolio',
			finance: 'Payments',
		},
		more: 'more',
		less: 'less',
		read_all: 'read all',
		resend_code_auth: 'Resend activation code',
		auth: {
			disposable: {
				title: 'Enter the one-time code',
				enter_with_pass: 'login with password',
			},
			create_account: 'create account',
			password: 'Set password',
			validation: {
				character_number: 'There must be at least {num} characters',
				captal_character: 'There should be at least one capital letter',
				small_character: 'There should be at least one small letter',
				number: 'There must be at least one number',
			},
			email: {
				title: 'Sign in with email',
				email: 'email',
				password: 'password',
				login_with_phone: 'Sign in with mobile number',
			},
			extra_info: {
				title: 'Further information',
				first_name: 'First name',
				last_name: 'Last name',
				email: 'Email',
				choose: 'choose',
			},
			password_login: {
				title: 'Enter your password',
				password: ' password',
				disposable: 'Log in with a one-time password',
			},
			title: 'Login via email',
			phone_num: 'Phone number',
			login: 'Login',
			enter: 'Enter',
			optional: 'Optional',
			signup: 'Sign up via email',
			login_hint: 'Not on CoinsON yet? Sign up',
			signup_hint: 'Have an account? Login',
			change_phone_num: 'Change phone number',
			change_email: 'Change email',
			enter_code: 'A 6-digit code has been sent to {phone_number}, please type it here',
			term_of_service: 'Term of service',
			accept_term_of_service: 'I have read and agree with it',
			start: 'Register',
		},
		month_num: '{month} month',
		day_num: '{day} day',
		week_num: '{week} week',
		year_num: '{year} year',
		calculator: {
			calculate: 'calculate',
			my_fund: 'My fund ',
			initial_fund: 'Initial fund',
			investment: 'after investing in Coins ON',
			dollar: 'dollar',
			period: 'Investment period',
			choose: 'choose ...',
			my_fund_after_period: 'My capital after {month} month:',
			warning: 'By accepting the risk of financial markets, your capital will probably reach this number.',
			risk_taking: 'Risk taking',
			low: 'low',
			medium: 'medium',
			high: 'high',
		},
		profile_CopyTradingPage: {
			copy_course: 'Copy the best portfolios',
			copy_course_caption: 'Feel free to profit even in a falling market',
			btn_copy_trade_learning: 'Complete copy trade training',
			my_courses: 'My portfolios',
			all_courses: 'View all Coins ON portfolios',
		},
		no_notification: 'There is no notification!',
		notifications: 'Notifications',
		my_account: 'Account',
		best_portfolio_expert: "{username}'s Best Portfolios",
		apostrofo: "'s",
		number: 'portfolios',
		assets_table: {
			percent_portfolio: 'percentage of portfolio',
			aum: 'asset amount',
		},
		position_table: {
			symbol: 'symbol',
			size: 'size',
			equivalent_size: 'equivalent size',
			entry_price: 'entry price',
			current_profit: 'instant profit',
			tpsl: 'TP/SL',
			time_trade: 'trade time',
		},
		position_spot_table: {
			symbol: 'Symbol',
			tether_size: 'Tether size',
			balance: 'Balance',
		},
		history_spot_table: {
			price: 'Price',
			order_time: 'order time',
		},
		order_table: {
			order_price: 'order price',
		},
		history_table: {
			final_profit: 'final profit',
			opening_time: 'opening time',
			closing_time: 'closing time',
		},
		asset_portfolio: 'Assets portfolio',
		latest_update: 'Latest update',
		latest_update_tooltip: 'The information you see on this page is related to this time and date',
		latest_update_refresh_tooltip:
			'The information you see on this page is related to this time and date. To update the information, please use the refresh button in this section',
		current_profit: 'instant profit',
		portfolio_risk: 'portfolio risk',
		positions: 'positions',
		history_trades: 'history trades',
		orders: 'orders',
		number_trades: 'Number of trades',
		return_trader: 'Return to trader page',
		portfolio_risk: 'portfolio risk',
		trade: 'trade(s)',
		today_profit: 'today profit',
		portfolio_profit: 'portfolio profit',
		last_week_profit: 'last week profit',
		unit_time_minute: 'minutes',
		unit_price_toman: 'thousand toman',
		portfolio: 'portfolios',
		experts: {
			title: "Coins ON's Traders",
		},
		traderCard: {
			transaction_risk: 'transaction risk',
			monthly_profit: 'monthly profit',
			copy_btn: 'Copy',
			cart_num: 'portfolios',
		},
		all_portfolios: 'All portfolios',
		person: 'persons',
		best_baskets: 'Best Portfolios',
		daraya_baskets: "Coins ON's Portfolios",
		accept: 'accept',
		i_accept: 'accept',
		free: 'free',
		monetary: 'monetary',
		crypto: 'crypto',
		general: 'general',
		consultation: 'consultation',
		bourse: 'bourse',
		housing: 'housing',
		real_state: 'real state',
		rate: 'rate',
		total: 'total',
		expert: 'expert',
		comment: 'comment',
		comments: 'comments',
		your_comment: 'your comment',
		reply: 'reply',
		replyOwn: "reply'' {name}",
		analyzes: 'analyzes',
		textual: 'textual',
		telephonic: 'telephonic',
		online_only: 'Online only',
		search: 'search',
		search_for_your_needs: 'search for your needs',
		filter: 'filter',
		sort: 'sort',
		by: 'by',
		show_content_by: 'show content by',
		send: 'send',
		message: 'message',
		messages: 'messages',
		send_message_to: 'send message to',
		marked: 'marked',
		mark: 'mark',
		oldest: 'oldest',
		newest: 'newest',
		new: 'new',
		new_messages: 'new messages',
		past_messages: 'past messages',
		both: 'both',
		bought: 'bought',
		dont_bought: 'dont bought',
		see_all: 'see all',
		breadcrumb: {
			home: 'Home',
			experts: 'experts',
			academy: 'academy',
		},
		call_expert: 'call contact with the expert',
		type_message: 'Type your message',
		online: 'online',
		busy: 'busy',
		offline: 'offline',
		waiting: 'waiting',
		reservations: 'reservations',
		about: 'about',
		fax: 'fax',
		close: 'close',
		cancel: 'cancel',
		return: 'return',
		return_home_page: 'return home page',
		submit: 'submit',
		confirm: 'confirm',
		edit: 'edit',
		delete: 'delete',
		continues: 'Continue',
		register: 'register',
		login_to_see: 'login to see',
		see: 'Watch',
		number_courses: 'Number of courses',
		followers: 'Followers',
		step: 'step {step}',
		expertPage: {
			see_all: 'Watch',
			top_expert_copytrading: 'The best copy trading consultants',
		},
		profileExpert: {
			security_call_experts:
				'All your conversations and the consultant and your contact number will remain confidential. All consultants are approved after a specific process.',
			response_hours: 'Response hours',
			metting: 'per {val_time} {unit_time} / {val_price} {unit_price}',
			efficiency_monthly: 'Profit monthly',
			efficiency_activity: 'ROI',
			send_message_to_trader: 'Send Message',
			efficiency: 'Profit',
		},
		footer: {
			slogan: 'قطب نمای مسیر سرمایه گذاری در دنیای بازار ها',
			site_right: 'تمامی حقوق این وبسایت متعلق به {0} می باشد',
		},
		CopyTrading: {
			margin: 'margin',
			cart_size: 'cart',
			capacity: 'capacity',
			expected_profit: 'expected profit',
			expected_losses: 'expected losses',
			risk: 'risk',
			course_status: 'course status ',
			open_orders: 'Open trades',
			orders: 'Orders',
			transaction_history: 'Transaction history',
			participants: 'Participants',
			register_stage: 'Register for the stage {number}',
			date_start: 'date start',
			date_end: 'date end',
			max_damage: 'max damage',
			start_amount: 'start amount',
			end_amount: 'end amount',
			stage_number: 'stage : {stage_number}',
			during_date: 'from{date_start} to {date_end}',
			remainingـpeople: '+{number} others',
			participants_people: '{number} participants',
			pending: 'Pending courses',
			in_progress: 'دوره های در حال برگزاری',
			end: 'دوره های برگزار شده',
			pending_tab: 'Registering',
			in_progress_tab: 'Performing',
			end_tab: 'Ended',

			end: 'Finished courses',
			performance_consultant: 'Annual performance of the consultant',
			entry_price: 'Entry price',
			exit_price: 'Exit price',
			profit: 'profit',
			top_price: 'Top price',
			stop_loss_price: 'Stop loss price',
			price_now: 'price_now',
			amount: 'amount',
			size: 'size',
			value: 'value',
			funds: 'funds',
			date_order_time: 'date',
			order_time: 'order time',
			side: 'side',
			terms_and_condition: 'terms and condition',
			stage_over: 'stage is over',
			assets_value: 'Assets Value',
			tether_size: 'Tether size',
			modified_date: 'Modified date',
			balance: 'balance',
			assets: 'assets',
			price: 'price',
		},
		copy_trading: 'copy trading',
		timeline: 'timeline',
		contents: 'contents',
		courses: 'courses',
		start_courses: 'start courses',
		profile: 'profile',
		profile_fa: 'profile',
		public_profile: 'public_profile',
		perfect: 'perfect',
		intermediate: 'intermediate',
		short: 'short',
		long: 'long',
		bad: 'bad',
		NotFounded: {
			comment: 'comment',
			content: 'content',
			message: 'message',
			courses: 'courses',
			trade: 'trade',
			hours_booking: 'Hours for booking',
			page: 'page',
			content_marked: 'content marked',
			expert_marked: 'expert marked',
			bill: 'bill',
			call: 'call',
			not_found: '{0} not found',
			bill: 'There is no bill to display',
			toTraders: 'Visit Traders',
			copytrade: "You have'nt copied a trader yet",
		},
		time: {
			few_moments_ago: 'a few moments ago',
			few_minutes_ago: 'a few minutes ago',
			hours: 'hours',
			day: 'day(s)',
			yesterday: 'yesterday',
			today: 'today',
			tomorrow: 'tomorrow',
			past: '{val} {unit} ago',
			minute: '{minute} minute',
			the_minute: '{minute} minute',
			hour: '{value}',
			daily: 'daily',
			hourly: 'hourly',
		},
		response: 'response',
		expert_answer: 'expert answer',
		and: 'and',
		my_calls: 'calls',
		my_reserves: 'reserves',
		reserve: 'reserve',
		settings: 'settings',
		mails: 'mails',
		bills: 'bills',
		enter_user: 'Login',
		change_password: 'change password',
		change_phone: 'change phone',
		account: 'Account',
		account_management: 'Account management',
		course_management: 'Course management',
		courseCard: {
			start_course: 'Start from ',
			to_fill_capacity: 'persons to fill portfolio',
			stage: "Portfolio's stage",
			ended: 'Ended at',
			period_days: ' at {number} days',
			assets_under_management: 'Assets under management',
			assets_under_management_brief: 'assets',
			num_copy_users: 'users',
			copy_users: 'Copiers',
			profit_expectation: 'expected profit',
			loss_expectation: 'expected loss',
			validation_course: 'Validation portfolio',
			aum: 'AUM',
			return_of_investment: {
				amonth: 'ROI 30d',
			},
		},
		profileContent: {
			changePhoto: 'change photo',
			credit: 'credit : {0}',
			mail: 'mails',
			settings: 'settings',
			credit_charge: 'credit charge',
			message_site: 'site messages to you',
			marked_content: 'marked content',
			marked_experts: 'marked experts',
			call_expert_one: 'Contact your expert {expert} at {time}',
			call_expert_two: 'It has been booked and will continue until {time}',
			copy_trading: 'Copy trading',
			consulting: 'Consulting',
		},
		enter_counseling_room: 'Enter counseling room',
		password: 'password',
		passwords: 'passwords',
		old_password: 'old password',
		new_password: 'new password',
		confirm_password: 'confirm password',
		current_mobile: 'Current mobile',
		new_mobile_number: 'New mobile',
		send_activation_code: 'Send activation code',
		activation_code: 'code',
		first_name: 'First name',
		last_name: 'Last name',
		email: 'Email',
		email_address: 'email address',
		card_number: 'card number',
		phone_number: 'phone number',
		pending_payment: 'pending payment',
		filed: 'filed',
		on_hold: 'on hold',
		paid: 'paid',
		expired: 'expired',
		invoice_number: 'invoice number : {0}',
		value_func: '{unit}{amount}',
		call_consulting: '{val} {unit} consulting',
		re_call: 'Re-call',
		enter_payment_page: 'Enter the payment page',
		custom_amount: 'custom amount',
		validation: {
			email: 'invalid email',
			required: 'Required!',
			max: 'Must be {max} characters or less',
			min: 'Must be {min} characters or more',
			not_match: '{fields} do not match',
			invalid: 'invalid {field}',
			duplicate: '{field} is duplicate',
			characters_left: '{number} characters left',
			expired_time: 'The time allowed to fill out the form has expired. Please try again',
			string: 'Required!',
		},
		input: {
			account: 'Enter your account information',
		},
		select_item: {
			account: 'Select your account information',
		},
		label_input: {
			api_key: 'Api Key',
			pass_phrase: 'Pass phrase',
			api_secret: 'Api secret',
			account_name: 'account_name',
			receive_daraye: 'Receive notifications via Coins ON platform',
			receive_email: 'Receive notifications via email',
			receive_sms: 'Receive notifications via SMS',
			automatedـsales: 'Automated sales, along with trader transactions',
		},
		ordinal_number: {
			1: 'first',
			2: 'second',
			3: 'third',
			4: 'fourth',
			5: 'fifth',
			6: 'sixth',
			7: 'seventh',
			8: 'eighth',
			9: 'ninth',
			10: 'tenth',
		},
		cardinal_number: {
			1: 'one',
			2: 'two',
			3: 'three',
			4: 'four',
			5: 'five',
			6: 'six',
			7: 'seven',
			8: 'eight',
			9: 'nine',
			10: 'ten',
		},
		timelineComponent: {
			see_more: 'see more',
			damageـrange: 'Damage range',
		},
		permission: {
			see_more: 'To view, you must first register',
		},
		sent: {
			success_code: 'Message sent successfully',
			comment: 'sent your comment',
		},
		registerMessage: {
			success_reserve: 'Your reservation was successful',
			success_signinPerid: 'You have successfully registered',
			already_registered: 'You are already registered',
		},
		menu: {
			home_page: 'Home',
			stock_market: 'Stock market',
			expert: 'expert',
			monitor: 'monitor',
			copy_trading: 'copy trading',
			courses: 'courses',
			content: 'content',
			publications: 'publications',
			cryptocurrency: 'cryptocurrency',
			trader: 'trader',
			traders: 'traders',
			investment_courses: 'Investment courses',
			housing: 'Housing',
			top_items: 'Top items',
			about_us: 'about us',
			start_investing: 'Where to start?',
		},
		trader: {
			number_courses: 'Number of courses',
			followers: 'Followers',
			crypto: 'Crypto',
			exchange: 'Exchange',
			forex: 'Forex',
			efficiency_monthly: 'Profit monthly',
			efficiency_activity: 'Profit',
			see_all_periods: 'see all',
			courses_being_held: 'The latest courses being held',
			meeting: 'Reserve a Consultant',
			copy_trade: 'Copy trades',
			teaching_copy_trade: 'Step by step copy trade training',
			trade_now: 'Copy Coin Futures of Trader Now',
			pages: {
				trader_performance: 'Trader performance',
				trades: 'Trades',
				copiers: 'Copiers',
			},
			copiers_profit: "Copier's profit",
			activation_time: 'Trade Days',
			win_rate: 'Win Rate',
			win_rate_tooltip: 'Net profit after deducting trading fees on the number of orders',
			pnl_rate: 'Pnl Ratio',
			pnl_rate_tooltip: 'The average net profit from profit orders divided by the average net loss from all non-profit orders',
			risk: 'Trader Risk',
			risk_tooltip:
				"1. The risk score of 1 to 5 is rated based on the fluctuation of the trader's profit and loss rate in the last 30 days. The higher the score, the higher the risk.",
			chart: {
				roi: 'ROI',
				assets: 'Property Trader',
				a_month: 'Last 30 days',
				week: 'Last 7 days',
			},
			copy_users: {
				num: 'Number of copiers so far',
				active: 'Number of active copiers',
				profit: 'Profit of copyists so far',
				title: 'Copiers',
			},
			copytradepage: {
				title: 'Copy trades for my capital',
				first_step: 'First stage',
				copytrade_account: 'Copy Trade Account',
				fund_to_connect: 'The capital you want to connect',
				fund: 'NOT connected Assets in your account',
				min_fund: 'The minimum capital required to copy the trades of this trader is ${num}',
				second_step: 'Second stage',
				loss_limit: {
					title: 'Loss limit',
					desc: 'Transactions, up to the specified loss for my capital, are copied',
					check_desc: 'Loss limit for your capital',
					desc_end: 'Minimum loss for copying trades of this trader is {num}%',
				},
				notifs: {
					title: 'Announcements',
					sms: "Receive this trader's copy trading notifications via SMS",
					email: "Receive this trader's copy trading notifications via email",
					platform: 'Receive copy trading notifications of this trader through the Daraa platform',
				},
				third_step: 'Third stage',
				accept_term_of_service_desc: 'Agree to the trading statute',
				accept: 'I agree',
				start_investing: 'Start Investing',
				start_investing_desc: "Important points when copying a trader's trades",
				start_investing_submit: 'Start Investing',
			},
		},
		symbols: {
			colon: '{0} ‌:',
			percent: '{0} %',
		},
		payment: {
			reservation: 'Reservation',
			wallet_charge: 'Wallet charge',
			payment_invoice: 'Payment invoice',
			description: 'Description',
			select_gateway: 'Select payment gateway',
			pay: 'pay',
			billsPage: 'Bills page',
			type_service: 'Type of service',
			date: 'date',
			hour: 'hour',
			amount: 'amount',
			total_amount: 'totoal_amount',
			invoice_number: 'invoice number',
		},
		reservePage: {
			time_reserve: 'From {from} to {to}',
			short_time_reserve: 'from {from} to {to}',
			consultationـtime: 'Consultation time',
			list_reservations_day: 'List of consultant reservations on this day',
			response_hours: 'Response_hours',
			date_consultation: 'Date of consultation',
			resultReserve: 'Your reservation will be held on {0} {1}.',
			select_start_consultation: 'Select the start time of the consultation',
			choose_custom_date: 'Choose a custom date and time',
			return_quick_choice: 'quick choise',
		},
		monitorPage: {
			mostGroupBuy: 'most group buy',
			mostPerCapitaBuy: 'most per capita buy',
			mostBuyPower: 'most buy power',
			mostPerCapitaSales: 'most per capita sales',
			lowestPurchasingBuy: 'lowest purchasing buy',
			buyHighVolume: 'Buy high Volume',
			salesHighVolume: 'Sales high Volume',
			time: 'time',
			symbol: 'symbol',
			totalBuy: 'total buy',
			perCapitaBuy: 'per capita buy',
			BuyerPowerToSeller: 'buyer power to seller',
			totalSale: 'total sale',
			perCapitaSale: 'per capita sale',
			SellerPowerToBuyer: 'saller power to buyer',
		},
		currency: {
			toman: '{amount} toman',
			dollar: '{amount} dollar',
			dollar_sign: '{amount} $',
			rial: '{amount} rial',
			amount: 'amount',
			total_amount: 'totoal_amount',
		},
		countingUnits: {
			peoples: '{count} peoples',
			cousrse: '{count} courses',
		},
		add_new_api_key: 'add new key',
		help: {
			copytrade: "You can add your own exchange APIs to copy traders' trades for you through the API",
		},
		error: {
			add_wallet: 'Please Add valid data',
		},
	},

	/// سلة- ملف-سبد
	ar: {
		referal: 'كود الإحالة',
		new_wallet: 'المحفظة الجديدة',
		accept: 'أوافق',
		i_accept: 'موافق',
		free: 'مجاناً',
		monetary: 'مالي',
		crypto: 'کریبتو',
		general: 'عام',
		consultation: 'استشارة',
		bourse: 'البورصة',
		housing: 'الإسكان',
		real_state: 'المستشار العقاري',
		rate: 'امتیاز',
		total: 'تعداد',
		expert: 'مستشار',
		comment: 'رأي',
		comments: 'آراء',
		your_comment: 'رأيك',
		reply: 'إجابة',
		replyOwn: 'إجابة  {name}',
		analyzes: 'تحلیل‌',
		textual: 'نصي',
		telephonic: 'عن طريق الهاتف',
		online_only: 'فقط آونلاین',
		search: 'البحث',
		search_for_your_needs: 'ابحث عمّا يجول في خاطرك',
		filter: 'الفلترة',
		sort: 'ترتيب',
		by: 'على أساس',
		show_content_by: 'عرض المحتوى على أساس',
		send: 'ارسال',
		message: 'رسالة',
		messages: 'الرسائل',
		send_message_to: 'ارسال رسالة الى',
		marked: 'تم عرضه',
		mark: 'عرض',
		oldest: 'الأقدم',
		newest: 'الأحدث',
		new: 'جدید',
		new_messages: 'الرسائل الجديدة',
		past_messages: 'الرسائل السابقة',
		both: 'كلاهما',
		bought: 'تم شراؤها',
		dont_bought: 'لم يتم شراؤها',
		see_all: 'عرض الجميع',
		pwa_welcome: 'مرحباً, أهلاً بكم في تطبيق كوينز اون',
		asset_portfolio: 'محفظةالأصول',
		profile_CopyTradingPage: {
			copy_course: 'انسخ أفضل الملفات في كوينز اون',
			copy_course_caption: 'لا تتردد في الربح حتى في السوق الهابطة',
			btn_copy_trade_learning: 'تدريب كامل على نسخ التداول',
			my_courses: 'سلتي',
			all_courses: 'مشاهدة جميع حقائب كوينز اون',
		},
		breadcrumb: {
			home: 'الرئيسية',
			experts: 'قائمة المستشارين',
			academy: 'التعلم',
		},
		call_expert: 'التواصل مع العملاء عبر الهاتف',
		type_message: 'اكتب رسالتك',
		online: 'آون لاین',
		busy: 'مشغول',
		offline: 'آوف لاین',
		waiting: 'ينتظر',
		reservations: 'تقديم المشورة',
		about: 'حول',
		fax: 'فاكس',
		close: 'إغلاق',
		cancel: 'انسحاب',
		return: 'العودة',
		return_trader: 'العودة إلى صفحة المتداول',
		return_home_page: 'العودة إلى الرئيسية',
		submit: 'سِجِلّ',
		confirm: 'تأكيد',
		edit: 'تحرير',
		delete: 'حذف',
		continues: 'استمرار',
		register: 'تسجيل',
		login_to_see: 'تسجيل الدخول للمتابعة',
		see: 'انسخ',
		number_courses: 'عدد الدورات',
		followers: 'عدد المتابعين',
		current_profit: 'الربح الحالي',
		step: 'المستوى {step}',
		number_trades: 'عدد المعاملات',
		calculator: {
			calculate: 'المحاسبة',
			my_fund: 'أموالي',
			initial_fund: 'الاستثمار الأولي',
			investment: 'بعد الاستثمار في كوينز اون',
			dollar: 'دولار',
			period: 'مدة الاستثمار',
			choose: 'اختيار ...',
			my_fund_after_period: 'أموالي بعد {month} شهر:',
			warning: 'بقبول مخاطر الأسواق المالية ، من المحتمل أن يصل رأس المال الخاص بك إلى هذا الرقم',
			risk_taking: 'تحمل المخاطر',
			low: 'قليل',
			medium: 'متوسط',
			high: 'كثير',
		},
		expertPage: {
			see_all: 'اظهار الكل',
			top_expert_copytrading: 'أفضل مستشارين نسخ التداول',
		},
		profileExpert: {
			security_call_experts:
				'ستبقى جميع المحادثات بينك وبين مستشارك ورقم الاتصال الخاص بك سرية. تتم الموافقة على جميع الاستشاريين بعد المرور بعملية محددة',
			response_hours: 'ساعات الاستجابة',
			metting: 'مقابلة  {val_time} {unit_time}  {val_price} {unit_price}',
			efficiency_monthly: 'العائد الشهري',
			efficiency_activity: 'العائد من وقت بدء النشاط',
			send_message_to_trader: 'أرسل رسالة',
			efficiency: 'العائد',
		},
		footer: {
			slogan: 'بوصلة مسار الاستثمار في عالم الأسواق',
			site_right: 'جميع حقوق موقع الويب هذا محفوظة لـ {0}',
		},
		traderCard: {
			transaction_risk: 'مخاطر التداول',
			monthly_profit: 'العائد الشهري',
			copy_btn: 'انسخ',
			cart_num: 'عدد الملفات', ///////////////
		},
		courseCard: {
			start_course: 'بداية الملف من',
			to_fill_capacity: 'أشخاص و يكتمل عدد أفراد الدورة',
			stage: 'حالة الملف',
			ended: 'انتهى في',
			period_days: 'في {number} يوم',
			assets_under_management: 'رأس المال تحت الإدارة',
			assets_under_management_brief: 'أصول تحت الإدارة',
			num_copy_users: ' عددالناسخین ( اي الأشخاص الذين يتابعون هذا المتداول )',
			copy_users: 'الناسخین',
			profit_expectation: 'الربح المتوقع',
			loss_expectation: 'الخسارة المتوقعة',
			validation_course: 'ملف التحقق من الصحة',
			aum: 'تحت الإدارة',
			return_of_investment: {
				amonth: 'الكفاءة الشهرية',
			},
		},
		experts: {
			title: 'متداولون  کوینز آون',
		},
		CopyTrading: {
			margin: 'margin',
			cart_size: 'حجم الملف الحالي',
			capacity: 'السعة',
			expected_profit: 'الربح المتوقع',
			expected_losses: 'الخسارة المتوقعة',
			risk: 'میزان الخطر',
			course_status: 'حالة الدورة التدريبية',
			open_orders: 'المعاملات المفتوحة',
			orders: 'الطلبات',
			transaction_history: 'تاريخ التداول',
			participants: 'الأشخاص المشاركون',
			register_stage: 'سجل للمرحلة {number}',
			date_start: 'تاريخ البدء',
			date_end: 'تاريخ الإكمال',
			max_damage: 'أقصى ضرر',
			start_amount: 'مبلغ المرحلة الأولية',
			end_amount: 'حجم الملف في نهاية المرحلة',
			stage_number: 'stage : {stage_number}',
			during_date: 'من تاریخ {date_start} الى {date_end}',
			remainingـpeople: '+{number} شخص اخر متبقي',
			participants_people: '{number} مشترك',
			pending: 'الدورات المعلقة',
			in_progress: 'دورات  قيد لإجراء',
			end: 'الدورات المنعقدة',
			pending_tab: 'في مرحلة التسجيل',
			in_progress_tab: 'في مرحلة الأداء',
			end_tab: 'تم تنظيمها',
			performance_consultant: 'الأداء السنوي للاستشاري',
			entry_price: 'سعر الدخول',
			exit_price: 'سعر الخروج',
			profit: 'الربح',
			top_price: 'حد الربح',
			stop_loss_price: 'حد الخسارة',
			price_now: 'السعر الفوري',
			value: 'قيمة',
			amount: 'كمية', ////// to check later
			size: 'مقاس', ////// to check later
			funds: 'الحجم المكافئ', ////// to check later
			date_order_time: 'التاريخ',
			order_time: 'تاريخ التدوال',
			side: 'جانب',
			balance: 'توازن', ////// to check later
			modified_date: 'اخر تغير',
			terms_and_condition: 'ميثاق نسخ التداول',
			stage_over: 'هذه المرحلة انتهت',
			assets_value: 'مبلغ حقيبة التسوق',
			tether_size: 'الحجم (Tether-تتر)',
			assets: 'الاصول',
			price: 'السعر',
		},
		timeline: 'مخطط التقدم',
		copy_trading: 'نسخ التداول',
		contents: 'المحتوى',
		courses: 'الدورات',
		latest_update: 'اخر تاريخ تحديث',
		latest_update_tooltip: 'المعلومات التي تراها في هذه الصفحة مرتبطة بهذا الوقت والتاريخ',
		latest_update_refresh_tooltip:
			'المعلومات التي تراها في هذه الصفحة مرتبطة بهذا الوقت والتاريخ. لتحديث المعلومات ، يرجى استخدام زر التحديث في هذا القسم',
		start_courses: 'بدء الدورة',
		profile: 'حساب تعريفي',
		profile_fa: 'المشخصات',
		public_profile: 'المشخصات العمومية',
		perfect: 'ممتاز',
		intermediate: 'متوسط',
		short: 'قصيرة',
		long: 'طويلة',
		bad: 'سيء',
		NotFounded: {
			comment: 'تعليق',
			content: 'المحتوى',
			message: 'رسالة',
			courses: 'حقيبة',
			page: 'الصفحة',
			trade: 'التداول', ////// to check later
			hours_booking: 'ساعة الحجز',
			content_marked: 'المحتوى المعروض',
			expert_marked: 'خبير ملحوظ', ////// to check later
			bill: 'الفاتورة',
			call: 'اتصال',
			not_found: '{0} غير متاح للعرض', ////// to check later
			bill: 'لا يوجد فاتورة لعرضها',
			toTraders: 'قم بزيارة التجار',
			copytrade: 'لم تقم بنسخ أي تاجر حتى الآن',
		},
		time: {
			few_moments_ago: 'منذ لحظات',
			few_minutes_ago: 'قبل عدة دقائق',
			hours: 'ساعة',
			day: 'يوم',
			yesterday: 'أمس',
			today: 'اليوم',
			tomorrow: 'غداً',
			past: '{val} {unit} قبل',
			minute: '{minute} دقيقة',
			the_minute: '{minute} دقيقة ',
			hour: 'ساعة {value}',
			daily: 'يوميًا',
			hourly: 'ساعة', ////// to check later
		},
		response: 'إجابة',
		expert_answer: 'إجابة المستشار',
		and: 'و',
		my_calls: 'مكالماتي',
		my_reserves: 'حجوزاتي',
		reserve: 'حجز',
		settings: 'إعدادات',
		mails: 'الرسائل',
		bills: 'الفواتير',
		enter_user: 'تسجیل الدخول',
		change_password: 'تغيير كلمة السر',
		change_phone: 'تغيير رقم الهاتف المحمول',
		account: 'الحساب',
		my_account: 'حسابي ',
		account_management: 'ادارة الحساب',
		course_management: 'إدارة الدورة',
		profileContent: {
			changePhoto: 'تغییر الصوره',
			credit: ' الرصيد المتبقي : {0}', ////// to check later
			credit_charge: 'زيادة رصيدي',
			message_site: 'رسائل الموقع لك',
			marked_content: 'المحتويات المعروضة',
			marked_experts: 'المستشارون المميزون', ////// to check later
			call_expert_one: 'اتصالك بالخبير{expert} في الساعة {time}',
			call_expert_two: ' منعقدة و الى الساعة {time}  ستستمر',
			copy_trading: 'محفظة الاستثمار',
			consulting: 'تقديم المشورة', /// استشارة
		},
		enter_counseling_room: 'دخول غرفة الاستشارة',
		password: 'كلمة السر',
		passwords: 'كلمات المرور',
		old_password: 'كلمة المرور السابقة',
		new_password: 'كلمة المرور الجديدة',
		confirm_password: 'كرر كلمة المرور',
		current_mobile: 'رقم الهاتف الحالي',
		new_mobile_number: 'رقم الهاتف الجديد',
		send_activation_code: 'إرسال رمز التفعيل',
		activation_code: 'رمز التفعيل',
		first_name: 'الاسم',
		last_name: 'اللقب',
		email: 'تغيير البريد الإلكتروني',
		email_address: 'البريد إلكتروني',
		card_number: 'رقم البطاقة',
		phone_number: 'رقم الهاتف المحمول',
		pending_payment: 'في انتظار الدفع',
		filed: 'خطأ ما قد حدث',
		on_hold: ' في الانتظار',
		paid: 'مدفوع',
		expired: 'منتهي الصلاحية',
		invoice_number: 'رقم الفاتورة : {0}',
		value_func: '{amount} {unit}',
		call_consulting: '{val} {unit} استشارة',
		re_call: 'أتصل مرة أخرى',
		enter_payment_page: ' الدخول إلى صفحة الدفع',
		custom_amount: 'كمية عشوائية', ////// to check later
		password_placeholder: 'تعيين كلمة مرور حسابك',
		send_code: 'تم إرسال رمز التفعيل المكون من 6 أرقام إلى {phone_number}',
		validation: {
			email: 'البريد الإلكتروني غير صالح',
			required: 'ملء هذه الخانة الزامي', ////// to check later
			max: 'يجب أن يكون عدد الأحرف المستخدمة أقل من {max}',
			min: 'يجب أن تكون الأحرف المستخدمة أكثر من {min}',
			not_match: '{fields} لا تتطابق مع بعضها البعض',
			invalid: '{fields} الذي تم إدخاله غير صالح',
			duplicate: '{fields} الذي تم إدخاله مكرر',
			characters_left: '{number} من الأحرف المتبقية',
			expired_time: 'انتهت المهلة المحددة لملء النموذج. حاول مرة اخرى',
			string: 'ملء هذه الخانة الزامي', ////// to check later
		},
		input: {
			account: 'أدخل معلومات حسابك',
		},
		select_item: {
			account: 'اختر حسابك',
		},
		label_input: {
			api_key: 'Api Key',
			pass_phrase: 'Pass phrase',
			api_secret: 'Api secret',
			account_name: 'account_name',
			receive_daraye: 'تلقي الإشعارات من خلال منصة Coins On',
			receive_email: 'تلقي الإشعارات عبر البريد الإلكتروني',
			receive_sms: 'تلقي الإشعارات عبر الرسائل القصيرة',
			automatedـsales: 'البيع والشراء التلقائي مع صفقات المتداولين',
		},
		ordinal_number: {
			1: 'الأول',
			2: 'الثاني',
			3: 'الثالث',
			4: 'الرابع',
			5: 'الخامس',
			6: 'السادس',
			7: 'السابع',
			8: 'الثامن',
			9: 'التاسع',
			10: 'العاشر',
		},
		cardinal_number: {
			1: 'واحد',
			2: 'اثنان',
			3: 'ثلاثة',
			4: 'اربعة',
			5: 'خمسة',
			6: 'ستة',
			7: 'سبعة',
			8: 'ثمانية',
			9: 'تسعة',
			10: 'عشرة',
		},
		timelineComponent: {
			see_more: 'مشاهدة المزيد',
			damageـrange: 'نطاق الخسارة', ////// to check later
		},
		permission: {
			see_more: 'يجب عليك التسجيل للمتابعة', ////// to check later
		},
		sent: {
			success_code: 'تم إرسال الرسالة بنجاح',
			comment: 'لقد تم ارسال تعليقك',
		},
		registerMessage: {
			success_reserve: 'تم تسجيل حجزك بنجاح!',
			success_signinPerid: 'تم تسجيلك في الدورة بنجاح',
			already_registered: 'أنت مسجل بالفعل في الدورة',
			error_number: 'كلمة مرورك يجب أن تحتوي على أرقام',
			error_string: 'يجب أن تحتوي كلمة مرورك على أحرف كبيرة وصغيرة',
			error_special_chars: 'كلمة المرور يجب أن تحتوي على أحرف خاصة (@ ،! ،٪ ، $ ، ...)',
		},
		menu: {
			home_page: 'الصفحة الرئيسية',
			stock_market: 'سوق الأوراق المالية', ////// to check
			expert: 'استشارة',
			monitor: 'شاشة', ////// to check
			copy_trading: 'نسخ التداول',
			courses: 'الدورات',
			content: 'مقالات الاستشاريين',
			publications: 'أكاديمية کوینز آون',
			cryptocurrency: 'العملة الرقمية', ////// to check
			trader: 'تاجر',
			traders: 'تجار',
			investment_courses: 'دورات الاستثمار',
			courses: 'ملفات',
			housing: 'الإسكان',
			top_items: 'أهم العناصر',
			about_us: 'من نحن؟', ////// to check 'معلومات عنا'
			start_investing: 'من أين أبدا؟',
		},

		auth: {
			disposable: {
				title: 'أدخل الرمز المستخدم لمرة واحدة',
				enter_with_pass: 'تسجيل الدخول بكلمة المرور',
			},
			create_account: 'إنشاء حساب',
			password: 'كلمة السر',
			validation: {
				character_number: 'يجب ألا يقل عدد الأحرف عن {num} حرف',
				captal_character: 'يجب أن يكون هناك حرف كبير واحد على الأقل',
				small_character: 'يجب أن يكون هناك حرف صغير واحد على الأقل',
				number: 'يجب أن يكون هناك رقم واحد على الأقل',
			},
			email: {
				title: 'تسجيل الدخول عن طريق البريد الإلكتروني',
				email: 'تغيير البريد الإلكتروني',
				password: 'كلمة السر',
				login_with_phone: 'الدخول عن طريق رقم الهاتف المحمول',
			},
			extra_info: {
				title: 'مزيد من المعلومات',
				first_name: 'الاسم',
				last_name: 'اللقب',
				email: 'تغيير البريد الإلكتروني',
				choose: 'اختر',
			},
			password_login: {
				title: 'أدخل كلمة السر الخاصة بك',
				password: 'كلمة السر',
				disposable: 'تسجيل الدخول عن طريق الرمز لمرة واحدة',
			},
			title: 'تسجيل الدخول',
			phone_num: 'رقم الهاتف المحمول',
			login: 'تسجیل الدخول',
			enter: 'تسجیل الدخول',
			signup: 'سجل عبر البريد الإلكتروني',
			login_hint: 'هل لم تقم بالتسجيل؟ اشتراك',
			signup_hint: 'هل لديك حساب؟ تسجيل الدخول',
			optional: 'خياري',
			change_phone_num: 'تغيير رقم الهاتف المحمول',
			change_email: 'تغيير الايميل',
			enter_code: 'تم إرسال رمز مكون من 6 أرقام إلى رقم الهاتف ${phone_number} يرجى ادخاله',
			term_of_service: 'لقد قرأت القواعد واللوائح الخاصة بکوینز اون وأتفق معها', /// 'لقد قرأت القواعد واللوائح الخاصة بدارايا وأتفق معها'
			accept_term_of_service: '', //// nothin' was here
			start: 'إبدأ',
		},
		reservePage: {
			time_reserve: 'من الساعة {from} الی {to}',
			short_time_reserve: '{from} الی {to}',
			consultationـtime: 'مدة الاستشارة',
			list_reservations_day: 'قائمة حجز الاستشارات في هذا اليوم',
			response_hours: 'ساعات استجابة المتداول',
			date_consultation: 'تاریخ الإستشارة',
			resultReserve: 'سيتم تسجيل حجزك في {0} {1}',
			select_start_consultation: 'اختر وقت بدء الاستشارة',
			choose_custom_date: 'تحديد التاريخ والساعة المطلوبة',
			return_quick_choice: 'الرجوع إلى التحديد السريع',
		},
		monitorPage: {
			mostGroupBuy: 'معظم الشراء الجماعي', ////// to check 'معلومات عنا'
			mostPerCapitaBuy: 'معظم المشتريات لكل فرد', ////// to check 'معلومات عنا'
			mostBuyPower: 'معظم القوة الشرائية', ////// to check
			mostPerCapitaSales: 'أعلى نسبة مبيعات للفرد',
			lowestPurchasingBuy: 'أدنى قوة شرائية',
			buyHighVolume: 'مشتريات مجمعة',
			salesHighVolume: 'حسومات كبرى', ////// to check
			time: 'وقت',
			symbol: 'الرمز',
			totalBuy: 'إجمالي الشراء',
			perCapitaBuy: 'شراء لكل فرد', ////// to check
			BuyerPowerToSeller: 'قوة المشتري للبائع', ////// to check
			totalSale: 'إجمالي المبيعات',
			perCapitaSale: 'مبيعات الفرد',
			SellerPowerToBuyer: 'قوة البائع للمشتري',
		},
		trader: {
			number_courses: 'عدد الدورات',
			followers: 'عدد المتابعين',
			crypto: 'کریبتو',
			exchange: 'تبادل', ////// to check
			forex: 'فورکس',
			efficiency_monthly: 'العائد الشهري',
			efficiency_activity: 'الكفاءة منذ النشاط',
			see_all_periods: 'عرض كل الملفات',
			courses_being_held: 'أحدث الدورات التي تعقد',
			meeting: 'استشارة إفتراضية مع المتداول',
			copy_trade: 'نسخ التداولات',
			teaching_copy_trade: 'التدريب على نسخ التداول خطوة بخطوة',
			trade_now: 'انسخ تداولات كوكوين فيوتشرز الخاصة بالمتداول الآن',
			pages: {
				trader_performance: 'أداء المتداول',
				trades: 'التداولات',
				copiers: 'الناسخين',
			},
			copiers_profit: 'أرباح الناسخين',
			activation_time: 'كفاءة 30 يوم الماضية',
			win_rate: 'Win Rate',
			win_rate_tooltip: 'صافي الربح بعد خصم رسوم التداول على عدد الصفقات',
			pnl_rate: 'Pnl Ratio',
			pnl_rate_tooltip: 'متوسط ​​صافي الربح من الصفقات  مقسومًا على متوسط ​​صافي الخسارة من جميع الصفقات الغير هادفة للربح',
			risk: 'مخاطر المتداول',
			risk_tooltip:
				'1. يتم تصنيف درجة المخاطرة من 1 إلى 5 بناءً على تارجح معدل ربح وخسارة التاجر في آخر 30 يومًا. كلما زادت النتيجة، زادت المخاطر.',
			chart: {
				roi: 'ROI',
				assets: 'اصول المتداول',
				a_month: '30 يوم الماضية',
				week: '7 ايام الماضية',
			},
			copy_users: {
				num: 'عدد الناسخين حتى الآن',
				active: 'عدد الناسخين النشطين',
				profit: 'ربح الناسخين حتى الآن',
				title: 'الناسخين',
			},
			copytradepage: {
				title: 'نسخ المعاملات لرأسمالي',
				first_step: 'المرحلة الأولى',
				copytrade_account: 'حساب نسخ  التداول', ////// to check
				fund_to_connect: 'التمويل الذي تريد توصيله',
				fund: 'الأصول غير المتصلة في حسابك',
				min_fund: 'الحد الأدنى لرأس المال المطلوب لنسخ تداولات هذا التاجر هو {num} دولار',
				second_step: 'المرحلة الثانية',
				loss_limit: {
					title: 'حد الخسارة',
					desc: 'يتم نسخ المعاملات، حتى الخسارة المحددة لرأسمالي',
					check_desc: 'حد الخسارة لرأس المال الخاص بك',
					desc_end: 'الحد الأدنى للخسارة لنسخ صفقات هذا التاجر هو {num}٪',
				},
				notifs: {
					title: 'الإعلانات',
					sms: 'تلقي إشعارات نسخ تداول  هذا التاجر عبر الرسائل القصيرة',
					email: 'تلقي إشعارات نسخ تداول  هذا التاجر عبر البريد الإلكتروني',
					platform: 'تلقي إشعارات نسخ تداول  هذا التاجر عبر منصة كوينز اون',
				},
				third_step: 'المرحلة الثالثة',
				accept_term_of_service_desc: 'أوافق على النظام الأساسي للتجارة',
				accept: 'أنا موافق',
				start_investing: 'ابدأ الاستثمار',
				start_investing_desc: 'نقاط مهمة عند نسخ صفقات المتداول',
				start_investing_submit: 'ابدأ الاستثمار',
			},
		},
		symbols: {
			colon: '{0}‌ :',
			percent: '% {0}',
		},
		percent_without_space: '{0}%',
		payment: {
			reservation: 'حجز استشارة',
			wallet_charge: 'شحن المحفظة', ////// to check
			payment_invoice: 'فاتورة الدفع',
			description: 'الوصف',
			select_gateway: ' تحديد بوابة الدفع',
			pay: 'الدفع',
			billsPage: 'صفحة الفواتير', ////// to check
			type_service: 'نوع الخدمة',
			date: 'التاريخ',
			hour: 'الساعة',
			invoice_number: 'رقم الفاتورة',
			amount: 'المبلغ',
			total_amount: 'المبلغ النهائي',
		},
		currency: {
			toman: '{amount} تومان',
			dollar: '{amount} دولار',
			dollar_sign: '{amount} $',
			rial: '{amount} ﷼',
		},
		countingUnits: {
			peoples: '{count} شخص',
			cousrse: '{count} دورة', ////// to check cousrse - course
		},
		add_new_api_key: 'إضافة مفتاح جديد',
		best_baskets: 'أفضل المحافظ',
		daraya_baskets: 'محافظ كوينز اون',
		person: 'شخص',
		all_portfolios: 'كل المحافظ',
		portfolio: 'محفظة', ////// to check
		unit_time_minute: 'دقيقة',
		unit_price_toman: 'هزار تومان', ///
		last_week_profit: 'ربح آخر سبعة أيام',
		portfolio_profit: 'ربح المحفظة', ////// to check ربح المحفظة
		today_profit: 'ربح اليوم',
		trade: 'تداول',
		portfolio_risk: 'مخاطر المحفظة',
		positions: 'المواقف',
		history_trades: 'تاريخ التداول',
		orders: 'الطلبات',
		position_table: {
			symbol: 'الرمز',
			size: 'الحجم',
			equivalent_size: 'الحجم المكافئ',
			entry_price: 'سعر الدخول',
			current_profit: 'العائد الحالي',
			tpsl: 'TP/SL',
			time_trade: 'تاريخ التدوال',
		},
		order_table: {
			order_price: 'سعر الطلب',
		},

		position_spot_table: {
			symbol: 'نماد',
			tether_size: 'اندازه تتر',
			balance: 'موجودی',
		},
		history_spot_table: {
			price: 'قیمت',
			order_time: 'زمان سفارش',
		},

		history_table: {
			final_profit: 'الربح  النهائي',
			opening_time: 'وقت الافتتاح', ////// to check
			closing_time: 'وقت الإغلاق',
		},
		assets_table: {
			percent_portfolio: 'النسبة من الملف', ////// to check سلال
			aum: 'حجم الأصول',
		},
		number: 'عدد',
		apostrofo: '',
		best_portfolio_expert: 'أفضل المحافظ{username}',
		notifications: 'إشعارات',
		read_all: 'قراءة كل شيء',
		no_notification: '! لا توجد إشعارات',
		month_num: '{month} شهر',
		year_num: '{year} سنة',
		resend_code_auth: 'إعادة إرسال رمز التفعيل',
		more: 'المزيد',
		less: 'أقل',
		sidebar: {
			title: ' كوينز اون لنسخ التداول',
			home: 'الرئيسية',
			// my_portfolio: 'سلة نسخ التداول الخاصة بي',
			my_portfolio: 'ملف نسخ التداول',
			my_wallet: 'محفظتي',
			traders: 'المتداولون',
			consultations: 'المستشارون',
			support: 'الدعم',
			academy: 'التعليم',
			how_to_use: 'كيفية الاستخدام',
			invitation: 'ادعو الأصدقاء',
			settings: 'الإعدادات',
			q_trader: 'هل أنت متداول؟',
			logout: 'تسجيل الخروج',
			my_portfolio_short: 'حقيبتي',
			finance: 'المدفوعات',
		},
		crypto_exchange: {
			kukoin: 'کوکوین',
			binance: 'باینانس',
			kukoin_spot: 'كوكوين سبوت',
			kukoin_future: 'كوكوين فيوتشرز',
			spot: 'سبوت',
			future: 'فيوتشرز',
		},
		traders: {
			picked_for_u: 'المفضلة',
			chosen_for_u: 'مختار لك',
			best_traders: 'أفضل المتداولون لدى كوينز اون',
			astronomical_profit: 'للأرباح النجومية',
			astronomical_profile_tooltip:
				'لاحظ أنه يمكنك تقلید جميع المتداولين باستخدام محفظة الأصول. خلاف ذلك، يمكنك فقط تقلید المتداولين في السوق الذي لديك فيه محفظة',
			exciting_traders: 'المتداولون المتحمسون',
			continuous_investment: 'للاستثمار المستمر',
			cautious_traders: 'المتداولون الحذرون',
			all_traders: 'جميع المتداولون',
			traders_tooltip_your_choose:
				'لاحظ أنه يمكنك نسخ صفقات جميع المتداولين باستخدام محفظة الأصول. خلاف ذلك، يمكنك فقط نسخ صفقات المتداولين في السوق الذي لديك فيه محفظة',
			filter_chips: {
				highest_rank: 'أعلى مرتبة',
				most_aum: 'أكبر رأس مال تحت الإدارة',
				most_users: 'أكبر عدد للناسخين',
				most_monthly_return: 'أعلى عائد شهري',
				most_risk: 'أكبر مخاطرة',
				least_risk: 'أدنى مخاطرة',
			},
		},
		my_wallet: {
			title: 'محفظات نسخ التداول',
			title_tooltip:
				'إذا كنت ترغب في نسخ صفقات متداولي الأصول، فيجب عليك أولاً توصيل رأس المال الخاص بك بنا في البورصات ذات الاعتبار أو الاحتفاظ بها في محفظة الأصول حتى يتم نسخ التداولات لرأس المال الخاص بك.',
			wallet: 'المحفظة',
			total_balance: 'إجمالي الرصيد',
			fund: 'رأس المال المتصل',
			fund_tooltip:
				'يشمل هذا المبلغ رأس المال المتضمن في نسخ الصفقات. للإفراج عن حسابك والسحب منه ، يجب عليك أولاً إيقاف نسخ الصفقات لرأس المال المطلوب. أيضًا ، إذا كان رأس المال في حساب التبادل الخاص بك أقل من رأس المال المتصل ، فسيتم إيقاف جميع عمليات النسخ المتعلقة بالمحفظة تلقائيًا.',
			status: 'الحالة',
			operation: 'العمليات',
			charge: 'شحن الحساب',
			detail: 'بيانات الحساب',
			stop: 'إيقاف',
			connect_wallet: 'وصل الحساب',
			wallets: {
				daraya: 'محفظة كوينز اون',
				kukoin: 'کوکوین',
				kukoin_spot: 'كوكوين سبوت',
				kukoin_futures: 'کوکوین فیوچرز',
				binance_spot: 'باينانس سبوت',
				binance_futures: 'باينانس فيوتشرز',
				binance: 'باینانس',
			},
			connect_status: 'متصل',
			disconnect_status: 'غير متصل',
			add_wallet: 'أضف محفظة جديدة',
			add_wallet_tooltip: 'إذا كنت بحاجة إلى محافظ مختلفة في كل بورصة، فأنشئ محفظة جديدة للتبادل الذي تريده.',
			bills: 'الفواتير',
			number: 'الرقم',
			for_what: 'من اجل',
			date: 'التاريخ',
			price: 'المبلغ',
			status: 'الحالة',
			factor_number: 'رقم الفاتورة',
			bills: {
				title: 'الفواتير',
				number: 'الرقم',
				for: 'من اجل',
				date: 'التاريخ',
				cost: 'المبلغ',
				status: 'الحالة',
				factor_number: 'رقم الفاتورة',
				currency: 'تومان',
				successful: 'ناجح',
				unsuccessful: 'غير ناجح',
				pending: 'انتظار الدفع',
			},
			card_desc: 'يتضمن رصيدك القابل للسحب المبلغ غير المتصل بأي حساب تداول نسخ وتم خصم رسوم الأصول منه.', ////// to check
			card_submit: 'تسجيل دخول إلى مكتب كوينز اون للصرف', ////// to check
		},
		settings_page: {
			individual_profile: {
				title: 'المعلومات الشخصية',
				edit: 'اختر الصورة',
				submit: 'تسجيل التغييرات',
			},
			account_security: {
				title: 'أمن الحساب',
				phone_num: 'رقم الهاتف ',
				change_phone_num: 'تغيير رقم الهاتف ',
				email: 'البريد الإلكتروني',
				change_email: 'تغيير البريد الإلكتروني',
				password: 'كلمة السر',
				change_password: 'تغيير كلمة السر',
			},
			change_phone: {
				title: 'تغيير رقم الهاتف',
				current_phone: 'رقم الهاتف الحالي',
				new_phone: 'رقم الهاتف الجديد',
				code: 'رمز التفعيل',
				send_code: 'إرسال رمز التفعيل',
				desc: 'سيتم إرسال رمز التفعيل إلى الرقم الحالي',
			},
			change_email: {
				title: 'تغيير البريد الإلكتروني',
				current_email: 'الايميل الحالي',
				new_email: 'الایمیل الجدید',
				code: 'رمز التفعيل',
				send_code: 'إرسال رمز التفعيل',
				desc: 'سيتم إرسال رمز التفعيل إلى الايميل الحالي',
			},
			change_password: {
				title: 'تغيير كلمة المرور',
				current_password: 'كلمة المرور الحالية',
				new_password: 'كلمة المرور الجديدة',
				repeat_password: 'كرر كلمة المرور الجديدة',
			},
			submit: 'تاكید',
		},
		dialog_connect: {
			title: 'ربط حساب {account} بکوینز اون',
			title_1: ' ربط حساب',
			title_2: ' بکوینز اون',
			submit: 'اتصال',
			help: 'تعلم ربط {account} بکوینز اون',
		},
		my_copyTrade_portfolio: {
			total_fund: 'إجمالي الرصيد',
			total_fund_tooltip:
				'يشمل هذا المبلغ جميع الأصول في محفظتك، بما في ذلك الأصول المرتبطة بنسخ التداول والأصول غير المرتبطة بنسخ التداول.',
			connected_fund: 'الرصيد المتصل في نسخ التداول',
			connected_fund_tooltip: 'يشمل هذا المبلغ كل المبلغ المرتبط بمتداول واحد أو أكثر ويتم نسخ المعاملات عليه',
			total_profit: 'العائد الإجمالي',
			total_profit_tooltip: 'مقدار ونسبة نمو رأس المال الخاص بك من بداية نشاطك حتى هذه اللحظة',
			profit_at: 'الربح في',
			profit_selector: {
				all: 'كل الأنشطة',
				month: '30 يوم الماضية',
				week: '7 ايام الماضية',
			},
			profit_selector_tooltip: 'مقدار ونسبة نمو رأس المال الخاص بك من الفترة الزمنية المحددة حتى هذه اللحظة',
			active_copy_trade: 'صفقات نسخ نشطة',
			table: {
				name: 'الاسم',
				trader: 'المتداول',
				market: 'السوق',
				start_date: 'تاريخ البدء',
				initial_fund: 'الاستثمار الأولي',
				current_fund: 'رصيدي الحالي',
				loss_limit: 'حد الخسارة',
				profit_loss: 'الربح والخسارة',
				operation: 'العمليات',
				detail: 'مشاهدة البيانات',
			},
			total_report: 'شاهد التقرير الإجمالي لأدائك هنا',
			total_report_tooltip: 'يمكن أن تظهر تقارير الأداء أدائك و اداء جميع المتداولين الذين نسخت عنهم في وقت واحد',
			total_report_btn: 'تقرير إجراء الملف الخاص بك',
			previous_copy_trade: 'مشاهدة نسخ التدوالات السابقة',
			previous_copy_trade_title: 'نسخ التداولات السابقة',
		},
		my_trades: 'تداولاتي',
		my_trades_tooltip: 'قائمة الصفقات المنسوخة لك مع التفاصيل والمتداول المرتبط بها',
		my_portfolio: 'حقيبة أصولي الخاصة بي',
		home: {
			before: 'قبل كل شيء',
			discover_risk: 'اكتشف مدى قدرتك على تحمل المخاطر',
			calculatation: 'حساب',
			risk_taking: 'احتمالي للمخاطرة',
			risk_taking_tooltip:
				'يساعدك ملف تعريف تحمل المخاطر الخاص بك في العثور على المتداولين المشابهين لك ونسخ تداولاتهم بالإضافة إلى معرفة سلوكك الاستثماري',
			me: '',
			evalutation_desc: 'ابحث عن المتداولين المناسبين لك وحقق أفضل استثمار لنفسك بمجرد إكمال هذا الاختبار',
			start: 'البدء بالاختبار',
			see_all_traders: 'مشاهدة جميع متداولين کوینز اون',
			best_traders: 'أفضل متداولين کوینز اون',
			best_papers: 'مقالات مهمة للبدء بالاستثمار',
			database: 'ادخل إلى أكاديمية کوینز اون',
			papers: {
				// 0: 'ما هي تكلفة استخدام خدمة Coins on ؟', after merge comment it
				0: 'كيفية المشاركة في معاملات Coins on، خطوة بخطوة',
				1: 'الإيداع في Coins on عبر Kucoin',
				2: 'كيف تنسخ معاملات متداول ؟',
				3: 'كيفية تعديل أو إيقاف نسخ المعاملات',
				4: ' إدارة رأس المال في Coins on',
			},
			beginner: 'مبتدی',
			first_step: 'الخطوة الأولى للاستثمار',
			first_step_desc: 'اكتشف مدى قدرتك على تحمل المخاطر	',
			darya_wallet: 'محفظة كوينز اون',
			kukoin_wallet: 'رصيد محفظة كوكوين',
			binance: 'رصيد محفظة باينانس',
			disconnect: 'غير متصل',
			increase_balance: 'زيادة الرصيد',
			learn_connect_daraya: 'تعلم کیفیة التوصیل',
			restart: 'إعادة إجراء الاختبار',
			next_step: 'الخطوة التالية من الاستثمار',
			next_step_desc: 'اختر متداولا وانسخ تداولاته',
			login_exchange: 'ادخل إلى مكتب الصرف',
			four_step_desc: 'ادفع رسوم  نسخك للتداولات لتتمكن من الاستمرار بعملية نسخ التداول',
			pay_commision: 'دفع أجر إدارة الملف',
			pay_commision_desc: 'ادفع العمولة قبل تاريخ الاستحقاق لبدء تداول النسخ',
			pay_commision_desc_not_found: 'لا توجد رسوم عمولة مطلوبة في هذا الوقت',
			sum_pay: 'إجمالي المبلغ القابل للدفع',
			time_pay: 'موعد الدفع حتى',
			pay_detail: 'بيانات حساب الأجر',
			pay_commision_submit: 'دفع الأجر',
			pay_footer: 'ما هي تكلفة استخدام خدمة كوينز اون ؟',
			pay_automatic: 'سحب الرسوم التلقائي من المحفظة',
			invitation_title: 'ادعو أصدقاؤك إلى كوينز اون واحصل على حصة من الرسوم المدفوعة من قبلهم',
			invitation_next_step: 'الخطوة التالية',
			my_proper_traders: 'المتداولون الذين يناسبونني',
			invite_friends: 'ادعو الأصدقاء',
		},
		risk: 'مخاطرة',
		favorite_account: 'اسم الحساب (المفضل)',
		default_favorite_account: 'الحساب رقم 1',
		people: 'شخص',
		update: 'تحدیث',
		back: 'العودة',
		pay: {
			daraya_wallet: 'رصيد محفظة كوينز اون',
			daraya_wallet_tooltip:
				'يتضمن رصيد محفظة کوینز اون مبلغ التيثر الذي اشتريته في بورصة کوینز اون ويمكنك نسخ صفقات المتداولین من خلالها.',
			daraya_cash: 'الرصيد القابل للسحب من محفظة كوينز اون',
			daraya_cash_tooltip:
				'يشمل هذا المبلغ رأس المال الموجود في محفظتك وغير متصل بأي متداول. إذا لزم الأمر، يمكنك تحويل كل رأس مالك إلى رصيد قابل للسحب عن طريق إيقاف نسخ الصفقات',
			fund_connected: 'الأصول المتصلة في نسخ التداول',
			fund_connected_tooltip:
				'يشمل هذا المبلغ رأس المال المتضمن في نسخ الصفقات. للإفراج عن حسابك والسحب منه، يجب عليك أولاً إيقاف نسخ الصفقات لرأس المال المطلوب. أيضًا، إذا كان رأس المال في حساب التبادل الخاص بك أقل من رأس المال المتصل، فسيتم إيقاف جميع عمليات النسخ المتعلقة بالمحفظة تلقائيًا.',
			tether_price: 'سعر تيثر',
			rial_tether: 'تحويل الريال إلى تيثر',
			tether_rial: 'نقل تيثر من المحفظة',
			tether_label: 'كمية التيثر المطلوبة',
			wallet: 'محفظة',
			transfer_daraya: 'النقل إلى محفظة كوينز اون',
			payable: 'المبلغ القابل للدفع',
			wage: 'مع حساب الأجر بمبلغ {num} تومان ',
			transfer: 'نقل',
			amount_tranfer: 'مبلغ النقل',
			bank_transfer: 'النقل إلى الحساب البنكي',
			shaba: 'رقم شبا',
			name: 'باسم:', ///
		},
		quiz: {
			intial_desc: 'من خلال إكمال الاختبار في هذا القسم، ابحث عن المتداولين الذين يناسبونك والذين سيحققون أفضل استثمار لك.',
			start: 'ابدأ',
			complete_after: 'سوف أكملها لاحقاً',
			next: 'التالي',
			result: 'نتيجة الاختبار الخاصة بك',
			complete_after: 'سأكملها لاحقًا',
			back: 'العودة إلى السابق',
			restart: 'إبدأ من جديد',
			show_traders: 'عرض المتداولون',
		},
		trader_report: {
			performance: 'أداء',
			market: 'السوق',
			start_date: 'تاريخ البدء',
			loss_limit: 'حد الخسارة',
			edit_copy_trade: 'تعديل نسخ التداول',
			stop: 'إيقاف',
			rate_desc: 'كيف تقيّم إدارتي لرأس المال؟',
			rate_desc_2: '(بدء نسخ التداول قبل أسبوع)',
			efficiency: 'الكفاءة',
			profit_made: 'الربح المحقق',
			initial_fund: 'الاستثمار الأولي',
			transaction_number: 'عدد التداولات',
			transactions_done: 'المعاملات المنجزة',
		},
		question: ' سؤال',
		select_trade_account: 'عاصمة',
		mostafa_jadidi: 'مصطفی جدیدی',
		archives: 'الأرشيف',
		bigger: 'أكثر من أصولك',
		help: {
			copytrade: 'يمكنك إضافة واجهات برمجة تطبيقات التبادل الخاصة بك لنسخ تداولات المتداولين لك من خلال API',
		},
		error: {
			add_wallet: 'الرجاء إضافة بيانات صالحة',
		},
	},
});

export default strings;
