import React , {useRef , useState} from 'react';
import { Tooltip } from '@material-ui/core';

export default function AnchorElTooltips() {
  const positionRef = useRef({
    x: 0,
    y: 0,
  });

  const popperRef = useRef(null);
  const areaRef = useRef(null);
  const [positionX, setPositionX] = useState()

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };
	setPositionX(event.clientX)
    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <div className='p-[30px]'>
    <Tooltip
      title={positionX}
      placement="top"
      arrow
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              positionRef.current.x,
              areaRef.current.getBoundingClientRect().y,
              0,
              0,
            );
          },
        },
      }}
    >
      <div
        ref={areaRef}
        onMouseMove={handleMouseMove}
        className='bg-blue-500 p-8 text-white'
      >
        Hover
      </div>
    </Tooltip>
    </div>
  );
}
