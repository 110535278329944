import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment-jalaali';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { useFetch } from 'utils/reactQuery';
import { setCount } from '../../redux/actions/config';
import strings from 'translations/translations';
import { showSidebarNotifications, closeSidebarNotifications } from 'redux/actions/notifications';
import { getNotifications, postReadNotification, postReadAllNotifications } from 'api/notification/readNotificaion';

const MINUTE_MS = 60000;

const SwipeableTemporaryDrawer = ({
	isLoggedInCustomer,
	isLoggedInExpert,
	count,
	showSideBar,
	showSidebarNotifications,
	closeSidebarNotifications,
	setCount,
}) => {
	const [items, setItems] = useState([]);
	const [data, setData] = useState([]);

	const fetchNotifications = async () => {
		try {
			const result = await getNotifications();
			setItems(result['data']);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (!!isLoggedInCustomer || !!isLoggedInExpert) fetchNotifications();
		}, MINUTE_MS);

		// return () => clearInterval(interval);
	}, []);
	const handleRead = async (id) => {
		try {
			const result = await postReadNotification({
				data: { id },
			});
			setItems(result['data']);
			setCount({ count: count - 1 });
		} catch (e) {
			console.log(e);
		}
	};
	const handleReadAll = async () => {
		try {
			await postReadAllNotifications();
			setItems([]);
			setCount({ count: 0 });
		} catch (e) {
			console.log(e);
		}
	};
	const toggleDrawer = (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		if (showSideBar) {
			closeSidebarNotifications();
		} else {
			showSidebarNotifications();
		}
	};

	return (
		<SwipeableDrawer
			style={{ width: '90%' }}
			anchor={'right'}
			open={showSideBar}
			onClose={() => toggleDrawer()}
			onOpen={() => toggleDrawer()}>
			<ListNotifications data={items} handleRead={handleRead} handleReadAll={handleReadAll} toggleDrawer={toggleDrawer} />
		</SwipeableDrawer>
	);
};

const ListNotifications = ({ data, handleRead, handleReadAll, toggleDrawer }) => {
	return (
		<div className="min-w-full p-4" role="presentation">
			<div className="flex justify-between items-center">
				<div className="flex flex-row items-center">
					<button type="button" aria-label="menu" className="icon-btn items-center" onClick={() => toggleDrawer()}>
						<CloseIcon style={{ width: '2rem', height: '2rem' }} />
					</button>
					{data?.length > 0 && (
						<button
							className="font-normal text-xl mr-2 text-blue-600"
							onClick={function (event) {
								event.preventDefault();
								handleReadAll();
							}}>
							{strings.read_all}
						</button>
					)}
				</div>
				<div className="my-3 ml-3 font-normal text-3xl">{strings.notifications}</div>
			</div>
			<div className="my-4">
				{data?.length > 0 ? (
					data?.map((item, key) => (
						<ListNotificationsItem item={item} key={key} variant={'new'} handleRead={handleRead} />
					))
				) : (
					<div className="flex justify-center bg-yellow-50 rounded-t-2xl text-profile-button-payment p-8 m-4 md:m-8">
						<span>{strings.no_notification}</span>
					</div>
				)}
			</div>
		</div>
	);
};

const ListNotificationsItem = ({ item, notification, variant = 'primary', handleRead }) => {
	const [readMore, setReadMore] = useState(false);
	const variantNotification = {
		primary: {
			border: 'border-blue-500',
			bg: 'bg-blue-50',
		},
		new: {
			border: 'border-yellow-400',
			bg: 'bg-yellow-50',
		},
	};
	const notificationStyle = Object.values(variantNotification[variant]).join(' ');
	const time = moment(item['created_at'].split('.')[0].split('T').join(' ')).format('HH:mm:ss - jYYYY/jM/jD');
	const hour = time.split(' ')[1].split(':').slice(0, 2).join(':');
	const day = time.split(' ')[0];

	const linkName = readMore ? strings.less : strings.more;
	const message = item['notification']['site']['message'];
	const shortMessage = message.slice(0, 15);
	return (
		<div className={classnames('mb-3 px-4 border-r-2', notificationStyle)}>
			<div className="text-right py-2 text-lg font-normal">{item['notification']['site']['title']}</div>
			<div className="text-gray-500 text-right mb-4 text-lg font-normal break-all">
				{readMore ? message : `${shortMessage}...`}
				<button
					className="read-more-link"
					onClick={(event) => {
						setReadMore(!readMore);
					}}
					onKeyDown={(event) => {
						setReadMore(!readMore);
					}}>
					{linkName}
				</button>
			</div>
			<div className="flex justify-between text-400 text-base font-normal">
				<div className="text-gray-400">{day}</div>
				<div className="flex flex-row">
					<button
						className="font-normal text-md ml-2 text-blue-600"
						onClick={function (event) {
							event.preventDefault();
							handleRead(item.id);
						}}>
						{strings.read_all}
					</button>
					<div className="text-gray-600 font-medium">{hour}</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	showSideBar: state.notifications.showSideBar,
	count: state.config.configs.notification.count,
	isLoggedInCustomer:
		state.account.token &&
		state.account.user.role === 'CUSTOMER' &&
		(typeof state.account.auth_data.registered === 'undefined' || state.account.auth_data.registered),
	isLoggedInExpert:
		state.account.token &&
		state.account.user.role === 'EXPERT' &&
		(typeof state.account.auth_data.registered === 'undefined' || state.account.auth_data.registered),
});

export default connect(mapStateToProps, { setCount, showSidebarNotifications, closeSidebarNotifications })(SwipeableTemporaryDrawer);
