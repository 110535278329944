import { Container } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as Close } from '../../../assets/icon/close.svg';
import { checkRegistered, phoneAuth, resetAuth, closeAuthDialog, resetPhone } from '../../../redux/actions/account';
import * as pages from './pages';
import CustomButton from '../../CustomButton';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiDialog-paperWidthMd': {
			maxWidth: 850,
		},
	},
	backDrop: {
		background: '#000000b3',
	},
	authLeftImage: {
		height: '100%',
		display: 'grid',
		background: `url('${process.env.PUBLIC_URL + 'login.png'}')`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
	},
}));

function AuthDialog({ open, openGlobal, resetPhone, handleClose, auth_data, phoneAuth, isLoggedIn, resetAuth, closeAuthDialog, account }) {
	const classes = useStyles();
	const history = useHistory();
	const [currentPage, setCurrentPage] = useState(pages.PHONE);

	const onClose = () => {
		setCurrentPage(pages.PHONE);
		handleClose();
		if (!isLoggedIn) {
			history.push('/courses');
			closeAuthDialog();
		} else {
			closeAuthDialog();
		}
	};

	useEffect(() => {
		switch (currentPage.type) {
			case pages.PHONE.type:
				if (!!auth_data.phone_number) {
					if (!auth_data.registered) {
						// phoneAuth({ phone_number: auth_data.phone_number });
						// setCurrentPage(pages.CODE_AND_PASSWORD);
						resetPhone();
						// setCurrentPage(pages.PHONE_REFERAL);
					} else {
						setCurrentPage(pages.PASSWORD);
					}
				}
				break;
			case pages.PHONE_REFERAL.type:
				if (!!auth_data.phone_number) {
					if (!auth_data.registered) {
						phoneAuth({ phone_number: auth_data.phone_number });
						// setCurrentPage(pages.CODE_AND_PASSWORD);
					} else {
						setCurrentPage(pages.PASSWORD);
					}
				}
				break;
			case pages.CODE_AND_PASSWORD.type:
				if (isLoggedIn) {
					setCurrentPage(pages.NAME_AND_EMAIL);
				}
				break;
			// case pages.EMAIL.type:
			// 	if (!!auth_data.email) {
			// 		if (!auth_data.registered) {
			// 			// phoneAuth({ phone_number: auth_data.phone_number });
			// 			// send email code
			// 			setCurrentPage(pages.CODE_AND_PASSWORD_EMAIL);
			// 		} else {
			// 			setCurrentPage(pages.PASSWORD_EMAIL);
			// 		}
			// 	}
			// 	break;
			default:
				break;
		}
	}, [auth_data, currentPage, phoneAuth, setCurrentPage, isLoggedIn]);

	useEffect(() => {
		return () => {
			setCurrentPage(pages.PHONE);
		};
	}, []);

	const DialogContentComponent = currentPage.Component;

	return (
		<Dialog
			maxWidth="md"
			fullWidth={true}
			open={false}
			onClose={onClose}
			scroll="body"
			disableBackdropClick={true}
			BackdropProps={{
				classes: {
					root: classes.backDrop,
				},
			}}
			className={classes.dialog}>
			<div className="flex flex-wrap">
				<div className="w-full sm:w-3/5">
					<CustomButton aria-label="close" onClick={onClose} className="p-4">
						<Close />
					</CustomButton>
					<div className="h-[500px]">
						<DialogContentComponent setCurrentPage={setCurrentPage} />
					</div>
				</div>
				<div className="w-2/5">
					<div className={classes.authLeftImage}></div>
				</div>
			</div>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	auth_data: state.account.auth_data,
	isLoggedIn: !!state.account.token,
	openGlobal: state.account.authModal,
	account: state.account,
});

export default connect(mapStateToProps, {
	checkRegistered,
	phoneAuth,
	resetAuth,
	resetPhone,
	closeAuthDialog,
})(AuthDialog);
