import React from 'react';
import { Link } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';

import strings from 'translations/translations';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

// import { menu } from './Menu';
import { hasChildren } from 'utils/hasChildren';
import { CustomLink } from './CustomLink';

// strings.setLanguage('fa');

export const MenuAppBar = () => {
	const menu = [
		// {
		// 	id: 1,
		// 	title: strings.menu.courses,
		// 	path: '/courses',
		// },
		// {
		// 	id: 2,
		// 	title: strings.menu.traders,
		// 	path: '/traders',
		// },
		// {
		// 	id: 4,
		// 	title: strings.menu.publications,
		// 	path: `//${process.env.REACT_APP_HOME_URL}/academy`,
		// 	absolute: true,
		// },
		// {
		// 	id: 5,
		// 	title: strings.menu.start_investing,
		// 	path: `//${process.env.REACT_APP_HOME_URL}/getstarted`,
		// 	absolute: true,
		// },
	];
	return menu.map((item, key) => <MenuItemCustom key={key} item={item} />);
};

const MenuItemCustom = ({ item }) => {
	const Component = hasChildren(item) ? MultiLevel : SingleLevel;
	return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
	const Component = item?.absolute ? CustomLink : Link;
	return (
		<Component
			to={item.path}
			key={item.title}
			className="flex justify-center no-underline items-center ml-14 transition-all duration-500 hover:text-menu-darkLight">
			<p className="mb-0 font-medium" style={{ fontSize: '14px', color: 'rgba(0,0,0,0.6)', fontWeight: '700' }}>
				{item.title}
			</p>
		</Component>
	);
};

const MultiLevel = ({ item }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { items: children, title, id } = item;

	return (
		<React.Fragment>
			<button
				id={'fade-button' + id}
				aria-controls={'fade-menu' + id}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				className="flex no-underline items-center ml-14 transition-all duration-500 hover:text-menu-darkLight">
				<p>{title}</p> {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowLeftIcon />}
			</button>
			<Menu
				id={'fade-menu' + id}
				MenuListProps={{
					'aria-labelledby': 'fade-button' + id,
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				keepMounted
				PaperProps={{
					style: {
						maxWidth: 'max-content',
						minWidth: '20ch',
						marginTop: '50px',
						border: '1px solid #E3E3E3',
					},
				}}>
				{children.map((child, key) => (
					<div className="p-4 pr-5 border-b border-solid boreder-menu-subItem">
						<Link
							key={key}
							onClick={handleClose}
							to={child.path}
							className="flex flex-start no-underline items-center ">
							<p className="mb-0 font-normal text-xl text-menu-subItem">{child.title}</p>
						</Link>
					</div>
				))}
			</Menu>
		</React.Fragment>
	);
};
