import { Link } from 'react-router-dom';

import strings from '../../../../translations/translations';

import { ReactComponent as LogoSvg } from '../../../../assets/icon/LogoFooterGray.svg';
import { ReactComponent as InstagramLogo } from '../../../../assets/icon/Instagram.svg';
import { ReactComponent as TelegramLogo } from '../../../../assets/icon/telegram_app.svg';
import { ReactComponent as TwitterLogo } from '../../../../assets/icon/twitter.svg';
import { ReactComponent as WhatsappLogo } from '../../../../assets/icon/whatsapp.svg';
import { ReactComponent as LinkedinLogo } from '../../../../assets/icon/linkedin.svg';

const Footer = () => {
	return (
		// <div className="md:flex md:flex-rows md:w-full md:h-24 md:mt-6">
		// bg-[#EDF2F8]
		<div className="flex flex-row w-full mt-10 bg-[#f7fafe]">
			<div className="xs:basis-2/12 mt-16 basis-4/12">
				<p className="text-[#201C68]">Coins ON Inc.</p>
				<h4>Support@coinson.co</h4>
			</div>
			<div className="xs:basis-8/12 basis-2/12"></div>
			<div className="xs:basis-2/12 basis-6/12 mt-16 flex flex-row w-full gap-x-4 md:gap-x-9">
				<a href="https://www.instagram.com/coinson_co">
					<InstagramLogo className="w-7" />
				</a>
				<a href="https://t.me/CoinsOn_co">
					<TelegramLogo className="w-7" />
				</a>
				<Link to="#">
					<TwitterLogo className="w-7" />
				</Link>
				<Link to="#">
					<WhatsappLogo className="w-7" />
				</Link>
				<a href="https://www.linkedin.com/company/91486802/">
					<LinkedinLogo className="w-7" />
				</a>
			</div>
		</div>
	);
};
export default Footer;
