export const back_map = [
	{
		regex: /^\/experts\/(\d+)/,
		length: 2,
		url: 'experts/',
		exact: false,
	},
	{
		regex: '/my_copytrade_portfolio?previousCopytrading',
		length: 2,
		url: 'experts/',
		exact: true,
	},
];
