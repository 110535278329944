import { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Routes } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import { useLocation, useHistory, Link, BrowserRouter } from 'react-router-dom';

import strings from 'translations/translations';
import Loading from 'components/Loading';
import HomeAppBar from 'components/AppBar/HomeAppBar';
import HomepageWrapper from 'containers/Homepage/HomePageWrapper';
import TestPage from 'components/Test/TestPage';
import ReservePageNew from 'containers/ReservePageNew';
import SideBar from 'components/SideBar';
import Footer from 'components/AppBar/HomeAppBar/Footer';
import BottomNavigation from 'components/BottomNavigation';
import { back_map } from 'utils/regex';
import classnames from 'classnames';
import { initializeStore } from 'redux/store/configureStore.dev';
import { ReactComponent as ConsultationSVG } from 'assets/icon/ask_question2.svg';
import { ReactComponent as HomeSVG } from 'assets/icon/home2.svg';
import { ReactComponent as TradersSVG } from 'assets/icon/user_account.svg';
import { ReactComponent as HomeSelectedSVG } from 'assets/icon/home3.svg';
import { ReactComponent as InvestmentSVG } from 'assets/icon/investment_portfolio.svg';
import { ReactComponent as WalletSVG } from 'assets/icon/wallet.svg';
import { ReactComponent as TradersSelectedSVG } from 'assets/icon/user_accoun3t.svg';
import { ReactComponent as WalletSelectedSVG } from 'assets/icon/wall2et.svg';
import { ReactComponent as InvestmentSelectedSVG } from 'assets/icon/investment_portfolio2.svg';
import { IntlActions } from 'react-redux-multilingual';
import { Router } from 'react-router-dom/cjs/react-router-dom.min';

const NotFoundPage = lazy(() => import('components/NotFound/NotFoundPage'));
const Expert = lazy(() => import('containers/Expert'));
const Experts = lazy(() => import('containers/Experts'));
const Monitor = lazy(() => import('containers/Monitor'));
const PublicationsPage = lazy(() => import('containers/PublicationsPage/index'));
const PublicationPage = lazy(() => import('containers/PublicationsPage/PublicationPage'));
const Profile = lazy(() => import('containers/Profile'));
const Settings = lazy(() => import('containers/Settings'));
const Finance = lazy(() => import('containers/Finance'));
const MyWallet = lazy(() => import('containers/MyWallet'));
const Home = lazy(() => import('containers/Home'));
const MyCopyTradePortfolio = lazy(() => import('containers/MyCopyTradePortfolio'));
const Dashboard = lazy(() => import('containers/Dashboard'));
const CopyTrade = lazy(() => import('containers/CopyTrade'));
const ReduxTest = lazy(() => import('containers/ReduxTest'));
const ReservePage = lazy(() => import('containers/ReservePage'));
const Payment = lazy(() => import('containers/Payment'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const Courses = lazy(() => import('containers/Courses'));
const Traders = lazy(() => import('containers/Trader/index'));
const Auth = lazy(() => import('containers/Auth'));
const Exchange = lazy(() => import('containers/Exchange'));
const Quiz = lazy(() => import('containers/Quiz'));
const TraderReport = lazy(() => import('containers/TraderReport'));
const PaymentGateway = lazy(() => import('containers/PaymentGateway'));

const languages_Enum = ['en', 'ar', 'fa'];
const Root = ({ roleOfUser, locale, isLoggedInCustomer, isLoggedInExpert }) => {
	strings.setLanguage(locale);
	const location = useLocation();
	let history = useHistory();

	const activeRoute = (routeName) => {
		return location.pathname.includes(routeName);
	};

	// if (location.pathname.split('/')[1] !== locale) {
	// 	if (languages_Enum.includes(location.pathname.split('/')[1])) {
	// 		strings.setLanguage(location.pathname.split('/')[1]);
	// 		const store = initializeStore();

	// 		console.log(strings.getLanguage(), 'getLanguage');
	// 		store.dispatch(IntlActions.setLocale(location.pathname.split('/')[1]));
	// 		strings.setLanguage(location.pathname.split('/')[1]);
	// 	} else {
	// 		console.log('locationlocation');
	// 		history.push({ pathname: '/en' + location.pathname });
	// 	}
	// }

	return (
		<>
			<CssBaseline />
			<HomeAppBar backMap={back_map} fullWidth={activeRoute('auth') || (!isLoggedInCustomer && !isLoggedInExpert)} />
			<div
				className={
					activeRoute('auth') || (!isLoggedInCustomer && !isLoggedInExpert)
						? 'hidden'
						: 'hidden md:block border-l border-[#EAEAEA] fixed min-h-full'
				}>
				<SideBar />
			</div>

			<Route exact path="/" component={HomepageWrapper} />
			<div
				className={
					activeRoute('auth') || (!isLoggedInCustomer && !isLoggedInExpert)
						? ''
						: classnames('container-sidebar pb-16 md:pb-0', locale === 'en' ? 'float-right' : 'float-left')
				}>
				<div className="shadow-md min-h-[calc(100vh-5rem)] md:min-h-screen bg-[#F7FAFE] md:bg-[#F7FAFE] rtl:border-r-1 ltr:border-l-1 border-[#EAEAEA]">
					<Suspense fallback={<Loading />}>
						{/* <Route basename={`/${locale}`}> */}
						{/* <Routes base> */}
						<Switch>
							<Route exact path="/redux_test" component={ReduxTest} />
							{/* {roleOfUser && roleOfUser === 'CUSTOMER' && ( */}
							<PrivateRoute exact path="/settings" component={Settings} />
							<PrivateRoute exact path="/finance" component={Finance} />
							{/* )} */}
							{/* {roleOfUser && roleOfUser === 'CUSTOMER' && <PrivateRoute exact path="/quiz" component={Quiz} />} */}
							<Route exact path="/quiz" component={Quiz} />
							{/* {roleOfUser && roleOfUser === 'CUSTOMER' && ( */}
							<PrivateRoute exact path="/exchange" component={Exchange} />
							{/* )} */}
							{/* {roleOfUser && roleOfUser === 'CUSTOMER' && ( */}
							<PrivateRoute exact path="/my_wallet" component={MyWallet} />
							{/* )} */}
							{/* {roleOfUser && roleOfUser === 'CUSTOMER' && ( */}
							<PrivateRoute exact path="/my_copytrade_portfolio" component={MyCopyTradePortfolio} />
							{/* )} */}
							{/* {roleOfUser && roleOfUser === 'EXPERT' && ( */}
							<PrivateRoute exact path="/dashboard" component={Dashboard} />
							{/* )} */}
							{/* {roleOfUser && roleOfUser === 'CUSTOMER' && ( */}
							<PrivateRoute exact path="/action_copytrade/:id" role="CUSTOMER" component={CopyTrade} />
							{/* )} */}
							<PrivateRoute exact path="/experts/:id/reserve" component={ReservePageNew} />
							<Route exact path="/home" component={Home} />
							<Route exact path="/publications" component={PublicationsPage} />
							<Route exact path="/auth" component={Auth} />
							<Route exact path="/payment_gateway" component={PaymentGateway} />
							<PrivateRoute exact path="/monitor" component={Monitor} />
							<Route exact path="/traders" render={(props) => <Traders />} />
							<Route exact path="/trader_report" render={(props) => <TraderReport />} />
							<PrivateRoute exact path="/publications/:id/:type" component={PublicationPage} />
							<Route
								key={20}
								exact
								path="/experts/:id"
								component={Expert}
								// onEnter={() => console.log('Entered pathname', location.pathname)}
							/>
							<PrivateRoute exact path="/payment/:id" component={Payment} />
							<Route exact path="/test" component={TestPage} />
							<Route exact path="/experts/:id/test-reserve" component={ReservePageNew} />
							<Route path={/[^\/]+/} component={NotFoundPage} />
						</Switch>
						{/* </Routes> */}
						{/* </Route> */}
					</Suspense>
				</div>
				{/* bg-[#EDF2F8] */}
				<div className="bg-[#F7FAFE]">
					<div className="grid px-12 pb-12 md:flex md:flex-row md:items-start">
						<Footer />
					</div>
				</div>
				{/* <div
					className={classnames(
						'shadow-lg border-t-1 border-[#EAEAEA] fixed h-20 w-full bottom-0 bg-white pt-2 pb-1 items-center md:hidden',
						isLoggedInCustomer || isLoggedInExpert ? 'grid grid-cols-5 gap-x-3' : 'grid grid-cols-3',
					)}>
					<Link className="flex flex-col items-center">
						<ConsultationSVG width={22} />
						<span className="text-[#727D8F] text-lg pt-2 font-normal">{strings.sidebar.consultations}</span>
					</Link>
					<Link className="flex flex-col items-center" to="/traders">
						{activeRoute('traders') ? <TradersSelectedSVG width={22} /> : <TradersSVG width={22} />}
						<span
							className={`${
								activeRoute('traders') ? 'text-[#201C68] font-medium' : 'text-[#727D8F] font-normal'
							}  text-lg pt-2`}>
							{strings.sidebar.traders}
						</span>
					</Link>
					{(isLoggedInCustomer || isLoggedInExpert) && (
						<Link className="flex items-center flex-col" to="/my_wallet">
							{activeRoute('my_wallet') ? <WalletSelectedSVG width={22} /> : <WalletSVG width={22} />}
							<span
								className={`${
									activeRoute('my_wallet') ? 'text-[#201C68] font-medium' : 'text-[#727D8F] font-normal'
								}  text-lg pt-2`}>
								{strings.sidebar.my_wallet}
							</span>
						</Link>
					)}
					{(isLoggedInCustomer || isLoggedInExpert) && (
						<Link className="flex items-center flex-col" to="/my_copytrade_portfolio">
							{activeRoute('my_copytrade_portfolio') ? (
								<InvestmentSelectedSVG width={22} />
							) : (
								<InvestmentSVG width={22} />
							)}
							<span
								className={`${
									activeRoute('my_copytrade_portfolio')
										? 'text-[#201C68] font-medium'
										: 'text-[#727D8F] font-normal'
								}  text-lg pt-2`}>
								{strings.sidebar.my_portfolio_short}
							</span>
						</Link>
					)}
					<Link className="flex flex-col items-center" to="/home">
						{activeRoute('home') ? <HomeSelectedSVG width={22} /> : <HomeSVG width={22} />}
						<span
							className={`${
								activeRoute('home') ? 'text-[#201C68] font-medium' : 'text-[#727D8F] font-normal'
							}  text-lg pt-2`}>
							{strings.sidebar.home}
						</span>
					</Link>
				</div> */}
				<BottomNavigation activeRoute={activeRoute} />
			</div>
		</>
	);
};

const mapStateToProps = ({ account, Intl }) => ({
	roleOfUser: account.user.role,
	locale: Intl.locale,
	isLoggedInCustomer:
		account.token &&
		account.user.role === 'CUSTOMER' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
	isLoggedInExpert:
		account.token &&
		account.user.role === 'EXPERT' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
});

export default connect(mapStateToProps)(Root);
