import { Grid, makeStyles, Typography, Box } from '@material-ui/core';

import groupBy from '../../utils/groupBy';
import { tLocalNum } from '../../utils/translateNumber';
import NotFounded from '../NotFound';

const useStyles = makeStyles((theme) => ({
	mobileMode: {
		[theme.breakpoints.down('xs')]: {
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
		},
		[theme.breakpoints.between('sm', 'md')]: {
			display: 'grid',
			gridTemplateColumns: 'repeat(4, 1fr)',
		},
	},
	timeItem: {
		width: '13%',
		margin: theme.spacing(3, 'auto', 0),
		textAlign: 'center',
		backgroundColor: 'rgb(231, 244, 255)',
		border: '1px solid rgb(180, 220, 255)',
		borderRadius: 8,
		padding: theme.spacing(1),
		maxWidth: 120,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.between('sm', 'md')]: {
			width: '100%',
		},
	},
}));

const dateTranslator = {
	SATURDAY: 'السبت',
	SUNDAY: 'الأحد ',
	MONDAY: 'الاثنين',
	TUESDAY: 'الثلاثاء',
	WEDNESDAY: 'الأربعاء',
	THURSDAY: 'الخميس',
	FRIDAY: 'الجمعة',
};

export default function ReserveTimes({ schedule }) {
	const classes = useStyles();
	const groupedSchedule = groupBy(schedule, 'day'); // Todo: fix back to pass grouped schedule time
	return (
		<>
			{schedule && schedule.length > 0 ? (
				// <Grid container className={classes.mobileMode}>
				<div className="flex flex-col xs:flex-row">
					{Object.keys(groupedSchedule)?.map((day, index) => (
						// <Grid key={index} item className={classes.timeItem}>
						<div className="bg-lightBlue bg-opacity-90 m-2 p-4 rounded-xl w-full justify-center items-center text-center">
							<Typography component="h5" variant="h6" style={{ color: '#2A5077' }}>
								{dateTranslator[day]}
							</Typography>
							{groupedSchedule[day].map((item, index) => (
								<Box key={index} mt={2}>
									<Typography style={{ color: '#201C68', fontWeight: 500 }}>{`${tLocalNum(
										item.from_hour.split(':')[0],
									)}:${tLocalNum(item.from_hour.split(':')[1])} الی ${tLocalNum(
										item.to_hour.split(':')[0],
									)}:${tLocalNum(item.to_hour.split(':')[1])}`}</Typography>
								</Box>
							))}
							{/* </Grid> */}
						</div>
					))}
					{/* </Grid> */}
				</div>
			) : (
				<NotFounded text="ساعتی" />
			)}
		</>
	);
}
