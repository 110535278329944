import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';

export const config = {
	apiKey: 'AIzaSyAXJnLhyb4rqxR80IVx10paIVXkGCb_-ps',
	authDomain: 'daraya-f9d90.firebaseapp.com',
	projectId: 'daraya-f9d90',
	storageBucket: 'daraya-f9d90.appspot.com',
	messagingSenderId: '288364550359',
	appId: '1:288364550359:web:9bbf374a20542820417892',
	measurementId: 'G-QCNZWGG4XC',
};
firebase.initializeApp(config);
let messaging;
if (firebase.messaging.isSupported()) {
	messaging = firebase.messaging();
}

export const requestFirebaseNotificationPermission = () =>
	new Promise((resolve, reject) => {
		if (firebase.messaging.isSupported()) {
			messaging
				.getToken()
				.then((firebaseToken) => {
					resolve(firebaseToken);
				})
				.catch((err) => {
					Notification.requestPermission(function (permission) {
						// If the user accepts, let's create a notification
						if (permission === 'granted') {
						}
					});

					// reject(err);
				});
		} else reject('firebase not supported');
	});

export const onMessageListener = () =>
	new Promise((resolve) => {
		if (firebase.messaging.isSupported()) {
			messaging.onMessage((payload) => {
				resolve(payload);
			});
		}
	});
