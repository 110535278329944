import getFormData from '../../utils/getFromData';
import { CALL_API } from '../middleware/api/api';
import * as actionTypes from './actionTypes';
import * as urls from './urls';
// code,
// password,
// email: auth_data.phone_number,
// phone_number: translateNumber({ lang: 'en', num: email }),
// referal: auth_data.referal,
export const register = ({ phone_number, password, code, email, referal }) => ({
	[CALL_API]: {
		types: [actionTypes.REGISTER_REQUEST, actionTypes.REGISTER_SUCCESS, actionTypes.REGISTER_FAILURE],
		url: urls.REGISTER,
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify({ phone_number, password, code, email, referral_code: referal }),
		},
	},
	payload: { phone_number, password, code },
});

export const setPersonalInfo = ({ first_name, last_name }) => ({
	[CALL_API]: {
		types: [actionTypes.SET_ACCOUNT_DATA_REQUEST, actionTypes.SET_ACCOUNT_DATA_SUCCESS, actionTypes.SET_ACCOUNT_DATA_FAILURE],
		url: urls.SET_ACCOUNT_DATA,
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify({
				first_name,
				last_name,
			}),
		},
	},
	payload: {
		first_name,
		last_name,
	},
});

export const setAccountData = ({ first_name, last_name, phone_number, email, answer = '', cc_number = '' }) =>
	answer === ''
		? {
				[CALL_API]: {
					types: [
						actionTypes.SET_ACCOUNT_DATA_REQUEST,
						actionTypes.SET_ACCOUNT_DATA_SUCCESS,
						actionTypes.SET_ACCOUNT_DATA_FAILURE,
					],
					url: urls.SET_ACCOUNT_DATA,
					fetchOptions: {
						method: 'POST',
						body: JSON.stringify({
							first_name,
							last_name,
							email,
							// cc_number,
						}),
					},
				},
				payload: {
					first_name,
					last_name,
					email,
					// cc_number,
				},
		  }
		: {
				[CALL_API]: {
					types: [
						actionTypes.SET_ACCOUNT_DATA_REQUEST,
						actionTypes.SET_ACCOUNT_DATA_SUCCESS,
						actionTypes.SET_ACCOUNT_DATA_FAILURE,
					],
					url: urls.SET_ACCOUNT_DATA,
					fetchOptions: {
						method: 'POST',
						body: JSON.stringify({
							first_name,
							last_name,
							answer,
							email,
							// cc_number,
						}),
					},
				},
				payload: {
					first_name,
					last_name,
					answer,
					email,
					// cc_number,
				},
		  };

export const checkRegistered = ({ phone_number, referal = '.' }) => ({
	[CALL_API]: {
		types: [actionTypes.CHECK_REGISTERED_REQUEST, actionTypes.CHECK_REGISTERED_SUCCESS, actionTypes.CHECK_REGISTERED_FAILURE],
		url: urls.CHECK_REGISTERED,
		// url: 'https://api.daraya.ir/fa/auth/register/check',
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify({ email: phone_number }),
		},
	},
	payload: { phone_number, referal },
});

export const checkRegisteredEmail = ({ email }) => ({
	[CALL_API]: {
		types: [
			actionTypes.CHECK_REGISTERED_REQUEST_EMAIL,
			actionTypes.CHECK_REGISTERED_SUCCESS_EMAIL,
			actionTypes.CHECK_REGISTERED_FAILURE_EMAIL,
		],
		url: urls.CHECK_REGISTERED,
		// url: 'https://api.daraya.ir/fa/auth/register/check',
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify({ email }),
		},
	},
	payload: { email },
});

export const phoneAuth = ({ phone_number }) => ({
	[CALL_API]: {
		types: [actionTypes.PHONE_AUTH_REQUEST, actionTypes.PHONE_AUTH_SUCCESS, actionTypes.PHONE_AUTH_FAILURE],
		url: urls.PHONE_AUTH,
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify({ email: phone_number }),
		},
	},
	payload: { phone_number },
});

const login = (props) => ({
	[CALL_API]: {
		types: [actionTypes.LOGIN_REQUEST, actionTypes.LOGIN_SUCCESS, actionTypes.LOGIN_FAILURE],
		url: urls.LOGIN,
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify(props),
		},
	},
	payload: props,
});

export const loginByPhoneCode = ({ phone_number, code }) => login({ email: phone_number, code });

export const loginByPhonePassword = ({ phone_number, password }) => login({ password, email: phone_number });

export const loginByEmailPassword = ({ email, password }) => login({ email, password });

export const logout = () => ({
	type: actionTypes.LOGOUT_REQUEST,
});

export const resetAuth = () => ({
	type: actionTypes.RESET_AUTH,
});

export const resetPhone = () => ({
	type: actionTypes.RESET_PHONE,
});

export const openAuthDialog = () => ({
	type: actionTypes.OPEN_AUTH_DIALOG,
});

export const closeAuthDialog = () => ({
	type: actionTypes.CLOSE_AUTH_DIALOG,
});

export const getProfile = () => ({
	[CALL_API]: {
		types: [actionTypes.GET_PROFILE_REQUEST, actionTypes.GET_PROFILE_SUCCESS, actionTypes.GET_PROFILE_FAILURE],
		url: urls.GET_PROFILE,
		fetchOptions: {
			method: 'GET',
		},
	},
});

export const uploadProfilePicture = ({ image }) => ({
	[CALL_API]: {
		types: [
			actionTypes.UPLOAD_PROFILE_PICTURE_REQUEST,
			actionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS,
			actionTypes.UPLOAD_PROFILE_PICTURE_FAILURE,
		],
		url: urls.UPLOAD_PROFILE_PICTURE,
		fetchOptions: {
			method: 'POST',
			dontContentType: true,
			body: getFormData({ image }),
		},
	},
});

export const getExpertDashboard = () => ({
	[CALL_API]: {
		types: [actionTypes.EXPERT_DASHBOARD_REQUEST, actionTypes.EXPERT_DASHBOARD_SUCCESS, actionTypes.EXPERT_DASHBOARD_FAILURE],
		url: urls.EXPERT_DASHBOARD,
		fetchOptions: {
			method: 'GET',
		},
	},
});

export const expertSettings = ({ first_name, last_name, about, email, cc_number, phone_number }) => ({
	[CALL_API]: {
		types: [actionTypes.EXPERT_SETTINGS_REQUEST, actionTypes.EXPERT_SETTINGS_SUCCESS, actionTypes.EXPERT_SETTINGS_FAILURE],
		url: urls.EXPERT_SETTINGS,
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify({
				first_name,
				last_name,
				about,
				email,
				cc_number,
				phone_number,
			}),
		},
	},
	payload: {
		first_name,
		last_name,
		about,
		email,
		cc_number,
		phone_number,
	},
});

export const expertNewSettings = () => ({
	[CALL_API]: {
		types: [
			actionTypes.EXPERT_NEW_SETTINGS_REQUEST,
			actionTypes.EXPERT_NEW_SETTINGS_SUCCESS,
			actionTypes.EXPERT_NEW_SETTINGS_FAILURE,
		],
		url: urls.EXPERT_SETTINGS,
		fetchOptions: {
			method: 'GET',
		},
	},
});

export const changePassword = ({ old_password, new_password, confirm_new_password }) => ({
	[CALL_API]: {
		types: [actionTypes.CHANGE_PASSWORD_REQUEST, actionTypes.CHANGE_PASSWORD_SUCCESS, actionTypes.CHANGE_PASSWORD_FAILURE],
		url: urls.CHANGE_PASSWORD,
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify({
				old_password,
				new_password,
				confirm_new_password,
			}),
		},
	},
	payload: {
		old_password,
		new_password,
		confirm_new_password,
	},
});

export const changePhoneNumber = ({ phone_number, code }) => ({
	[CALL_API]: {
		types: [
			actionTypes.CHANGE_PHONE_NUMBER_REQUEST,
			actionTypes.CHANGE_PHONE_NUMBER_SUCCESS,
			actionTypes.CHANGE_PHONE_NUMBER_FAILURE,
		],
		url: urls.CHANGE_PHONE_NUMBER,
		fetchOptions: {
			method: 'POST',
			body: JSON.stringify({
				phone_number,
				code,
			}),
		},
	},
	payload: {
		phone_number,
		code,
	},
});
