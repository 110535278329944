export const Logger = (msg, title = 'debug') => {
	if (process.env.DEBUG === false) return;
};

export const inArray = (arr, value) => {
	return arr.filter((item) => item === value).length > 0;
};

export const redirectPaymentPage = (id_payment, history) => {
	if (id_payment) history.push(`/payment/${id_payment}`);
};
