import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: '1rem',
    padding: '0',
    height : '17px',
    minWidth : '17px'
  },
}))(Badge);

export const CustomizedBadges = ({ count, children, ...rest }) => {
  return (
    <StyledBadge badgeContent={4} color="secondary" {...rest}>
      {children}
    </StyledBadge>
  );
}