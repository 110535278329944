import { useLocation, Link } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import strings from 'translations/translations';

import { ReactComponent as Settings } from 'assets/icon/settings3.svg';
import { ReactComponent as SettingsSelected } from 'assets/icon/setting2s.svg';
import { ReactComponent as TradersSVG } from 'assets/icon/user_account.svg';
import { ReactComponent as TradersSelectedSVG } from 'assets/icon/user_accoun3t.svg';
import { ReactComponent as ConsultationSVG } from 'assets/icon/ask_question2.svg';
import { ReactComponent as ConsultationSelectedSVG } from 'assets/icon/ask_question.svg';
import { ReactComponent as WalletSVG } from 'assets/icon/wallet.svg';
import { ReactComponent as WalletSelectedSVG } from 'assets/icon/wall2et.svg';
import { ReactComponent as InvestmentSVG } from 'assets/icon/investment_portfolio.svg';
import { ReactComponent as InvestmentSelectedSVG } from 'assets/icon/investment_portfolio2.svg';
import { ReactComponent as HomeSVG } from 'assets/icon/home2.svg';
import { ReactComponent as HomeSelectedSVG } from 'assets/icon/home3.svg';
import { ReactComponent as Payments } from 'assets/icon/payments.svg';
import { ReactComponent as PaymentsSelected } from 'assets/icon/paymentsSelected.svg';

const BottomNavigation = ({ activeRoute, isLoggedInCustomer, isLoggedInExpert, ...props }) => {
	if (isLoggedInCustomer || isLoggedInExpert) {
		return (
			<div className="shadow-lg border-t-1 border-[#EAEAEA] fixed h-20 w-full bottom-0 bg-white px-8 pt-2 pb-1 flex justify-between items-center md:hidden">
				{/* {(isLoggedInCustomer || isLoggedInExpert) && (
					<Link className="flex flex-col items-center">
						<ConsultationSVG width={22} />
						<span className="text-[#727D8F] text-lg pt-2 font-normal">{strings.sidebar.consultations}</span>
					</Link>
				)} */}

				{/* {(isLoggedInCustomer || isLoggedInExpert) && (
					<Link className="flex flex-col items-center" to="/settings">
						{activeRoute('settings') ? <SettingsSelected width={22} /> : <Settings width={22} />}
						<span
							className={`${
								activeRoute('settings') ? 'text-primary font-semibold' : 'text-[#727D8F] font-normal'
							} text-lg pt-2`}>
							{strings.sidebar.settings}
						</span>
					</Link>
				)} */}
				{(isLoggedInCustomer || isLoggedInExpert) && (
					<Link className="flex flex-col items-center" to="/finance">
						{activeRoute('finance') ? (
							<PaymentsSelected width={22} height={22} />
						) : (
							<Payments width={22} height={22} />
						)}
						<span
							className={`${
								activeRoute('finance') ? 'text-primary font-semibold' : 'text-[#727D8F] font-normal'
							} text-lg pt-2`}>
							{strings.sidebar.finance}
						</span>
					</Link>
				)}
				<Link className="flex flex-col items-center" to="/traders">
					{activeRoute('traders') ? <TradersSelectedSVG width={22} /> : <TradersSVG width={22} />}
					<span
						className={`${
							activeRoute('traders') ? 'text-primary font-medium' : 'text-[#727D8F] font-normal'
						}  text-lg pt-2`}>
						{strings.sidebar.traders}
					</span>
				</Link>
				{(isLoggedInCustomer || isLoggedInExpert) && (
					<Link className="flex items-center flex-col" to="/my_wallet">
						{activeRoute('my_wallet') ? <WalletSelectedSVG width={22} /> : <WalletSVG width={22} />}
						<span
							className={`${
								activeRoute('my_wallet') ? 'text-primary font-medium' : 'text-[#727D8F] font-normal'
							}  text-lg pt-2`}>
							{strings.sidebar.my_wallet}
						</span>
					</Link>
				)}
				{(isLoggedInCustomer || isLoggedInExpert) && (
					<Link className="flex items-center flex-col" to="/my_copytrade_portfolio">
						{activeRoute('my_copytrade_portfolio') ? (
							<InvestmentSelectedSVG width={22} />
						) : (
							<InvestmentSVG width={22} />
						)}
						<span
							className={`${
								activeRoute('my_copytrade_portfolio')
									? 'text-primary font-medium'
									: 'text-[#727D8F] font-normal'
							}  text-lg pt-2`}>
							{strings.sidebar.my_portfolio_short}
						</span>
					</Link>
				)}
				{(isLoggedInCustomer || isLoggedInExpert) && (
					<Link className="flex flex-col items-center" to="/home">
						{activeRoute('home') ? <HomeSelectedSVG width={22} /> : <HomeSVG width={22} />}
						<span
							className={`${
								activeRoute('home') ? 'text-primary font-medium' : 'text-[#727D8F] font-normal'
							}  text-lg pt-2`}>
							{strings.sidebar.home}
						</span>
					</Link>
				)}
			</div>
		);
	} else {
		return <></>;
	}
};

const mapStateToProps = ({ account, Intl }) => ({
	roleOfUser: account.user.role,
	locale: Intl.locale,
	isLoggedInCustomer:
		account.token &&
		account.user.role === 'CUSTOMER' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
	isLoggedInExpert:
		account.token &&
		account.user.role === 'EXPERT' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
});

export default connect(mapStateToProps)(BottomNavigation);
