import classnames from 'classnames';
import React from 'react'
import { useState, useEffect } from 'react';

const Timer = ({
	minute,
	second,
	endAction = () => { },
	className
}) => {
	const [minutes, setMinutes] = useState(minute);
	const [seconds, setSeconds] = useState(second);
	const [finalMoments, setFinalMoments] = useState(false)
	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds <= 59 && minutes === 0) {
				setFinalMoments(true)
			}
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					endAction('finish');
					clearInterval(myInterval)
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000)
		return () => {
			clearInterval(myInterval);
		};
	});

	return (
		<div>
			<div className={classnames(
				finalMoments ? 'text-red-500 font-bold text-2xl' : '',
				className ? className : ''
			)}> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>
		</div>
	)
}

export default Timer;