import * as actionTypes from '../actions/actionTypes';

const initState = {
  messages: {},
};

function message(state = initState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.response,
      };
    case actionTypes.SEND_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.response,
      };
    case actionTypes.RESERVATION_SEND_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.response,
      };
    case actionTypes.GET_THREADS_SUCCESS:
      return {
        ...state,
        messages: action.response,
      };
    case actionTypes.MESSAGE_REPLY_SUCCESS:
      const indexRead = Object.keys(
        state.messages['not_read_messages']
      ).findIndex((e) => +e === +action.response.thread[0].thread);
      const indexNotRead = Object.keys(
        state.messages['older_messages']
      ).findIndex((e) => +e === +action.response.thread[0].thread);

      if (indexRead === -1 && indexNotRead === -1) {
        return {
          ...state,
        };
      }
      state.messages.not_read_messages[indexNotRead] = action.response.thread;
      state.messages.older_messages[indexRead] = action.response.thread;
      return {
        ...state,
        messages: state.messages,
      };
    default:
      return state;
  }
}

export default message;
