import * as actionTypes from '../actions/actionTypes';

const initState = {
  invoices: [],
  wallet_payment_id: null,
};

function payment(state = initState, action) {
  switch (action.type) {
    case actionTypes.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.response,
      };
    case actionTypes.CHECK_COUPON_SUCCESS:
      return {
        ...state,
        checkedCoupon: action.response,
      };
    case actionTypes.P_SUCCESS:
      return {
        ...state,
        checkedCoupon: action.response,
      };
    case actionTypes.T_SUCCESS:
      return {
        ...state,
        checkedCoupon: action.response,
      };
    case actionTypes.GET_INVOICES_DETAILS_SUCCESS:
      return {
        ...state,
        invoices: action.response,
      };
    case actionTypes.ADD_WALLET_SUCCESS:
      return {
        ...state,
        wallet_payment_id: action.response.id,
      };
    case actionTypes.RESET_PAYMENT:
      return {
        ...state,
        invoices: [],
        wallet_payment_id: null,
      };
    default:
      return state;
  }
}

export default payment;
