import { useState } from 'react';
import { connect } from 'react-redux';

import { Row, Input } from './Styles';

const LastNameInput = ({ errors, onChange = () => {}, ...props }) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Row>
      <Input
        errors={
          errors &&
          errors.length > 0 &&
          errors[0].message.includes('last_name') &&
          true
        }
        required
        type="text"
        value={value}
        onChange={handleChange}
        {...props}
      />
      {errors &&
        errors.length > 0 &&
        errors[0].message.includes('last_name') && (
          <span>{errors[0].message.split(' ').slice(1).join(' ')}</span>
        )}
    </Row>
  );
};

const mapStateToProps = ({ notifications }) => ({
  errors: notifications.notifications.errors,
});

export default connect(mapStateToProps)(LastNameInput);
