import styled from 'styled-components';

export const CustomButtonContainer = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: max-content;
  height: max-content;
  text-align: ${({ align }) => align};
`;
