import { CALL_API } from '../middleware/api/api';
import * as actionTypes from './actionTypes';
import * as urls from './urls';

export const getExpert = ({ expertId }) => ({
    [CALL_API]: {
        types: [actionTypes.GET_EXPERT_REQUEST, actionTypes.GET_EXPERT_SUCCESS, actionTypes.GET_EXPERT_FAILURE],
        url: urls.GET_EXPERT(expertId),
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const getExpertList = (search) => ({
    [CALL_API]: {
        types: [actionTypes.GET_EXPERT_LIST_REQUEST, actionTypes.GET_EXPERT_LIST_SUCCESS, actionTypes.GET_EXPERT_LIST_FAILURE],
        url: urls.GET_EXPERT_LIST + (search ? '?' + new URLSearchParams(search) : ''),
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const getAvailableTime = ({ expert, from_date, to_date }) => ({
    [CALL_API]: {
        types: [actionTypes.GET_AVAILABLE_TIME_REQUEST, actionTypes.GET_AVAILABLE_TIME_SUCCESS, actionTypes.GET_AVAILABLE_TIME_FAILURE],
        url: urls.GET_AVAILABLE_TIME,
        fetchOptions: {
            method: 'POST',
            body: JSON.stringify({ expert, from_date, to_date }),
        },
    },
    payload: { expert, from_date, to_date },
});

export const expertReserve = ({ expertId, date, start_hour, duration }) => ({
    [CALL_API]: {
        types: [actionTypes.EXPERT_RESERVE_REQUEST, actionTypes.EXPERT_RESERVE_SUCCESS, actionTypes.EXPERT_RESERVE_FAILURE],
        url: urls.EXPERT_RESERVE(expertId),
        fetchOptions: {
            method: 'POST',
            body: JSON.stringify({ date, start_hour, duration }),
        },
    },
    payload: { date, start_hour, duration },
});

export const sendMessage = ({ expertId, message }) => ({
    [CALL_API]: {
        types: [actionTypes.SEND_MESSAGE_REQUEST, actionTypes.SEND_MESSAGE_SUCCESS, actionTypes.SEND_MESSAGE_FAILURE],
        url: urls.SEND_MESSAGE(expertId),
        fetchOptions: {
            method: 'POST',
            body: JSON.stringify({ message }),
        },
    },
    payload: { message },
});

export const getComments = ({ expertId }) => ({
    [CALL_API]: {
        types: [actionTypes.GET_COMMENTS_REQUEST, actionTypes.GET_COMMENTS_SUCCESS, actionTypes.GET_COMMENTS_FAILURE],
        url: urls.GET_COMMENTS(expertId),
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const getCommentsDashboard = () => ({
    [CALL_API]: {
        types: [
            actionTypes.GET_COMMENTS_DASHBOARD_REQUEST,
            actionTypes.GET_COMMENTS_DASHBOARD_SUCCESS,
            actionTypes.GET_COMMENTS_DASHBOARD_FAILURE,
        ],
        url: urls.GET_COMMENTS_DASHBOARD,
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const responseToComments = ({ commentId, updated_response }) => ({
    [CALL_API]: {
        types: [
            actionTypes.RESPONSE_TO_COMMENTS_REQUEST,
            actionTypes.RESPONSE_TO_COMMENTS_SUCCESS,
            actionTypes.RESPONSE_TO_COMMENTS_FAILURE,
        ],
        url: urls.RESPONSE_TO_COMMENTS(commentId),
        fetchOptions: {
            method: 'POST',
            body: JSON.stringify({ updated_response }),
        },
    },
    payload: { updated_response },
});

export const getNotificationInbox = () => ({
    [CALL_API]: {
        types: [
            actionTypes.GET_NOTIFICATION_INBOX_REQUEST,
            actionTypes.GET_NOTIFICATION_INBOX_SUCCESS,
            actionTypes.GET_NOTIFICATION_INBOX_FAILURE,
        ],
        url: urls.GET_NOTIFICATION_INBOX,
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const addWorkSchedule = ({ day, shift, from_hour, to_hour }) => ({
    [CALL_API]: {
        types: [actionTypes.ADD_WORK_SCHEDULE_REQUEST, actionTypes.ADD_WORK_SCHEDULE_SUCCESS, actionTypes.ADD_WORK_SCHEDULE_FAILURE],
        url: urls.ADD_WORK_SCHEDULE,
        fetchOptions: {
            method: 'POST',
            body: JSON.stringify({ day, shift, from_hour, to_hour }),
        },
    },
    payload: { day, shift, from_hour, to_hour },
});

export const resetWorkSchedule = ({ schedule }) => ({
    [CALL_API]: {
        types: [actionTypes.RESET_WORK_SCHEDULE_REQUEST, actionTypes.RESET_WORK_SCHEDULE_SUCCESS, actionTypes.RESET_WORK_SCHEDULE_FAILURE],
        url: urls.RESET_WORK_SCHEDULE,
        fetchOptions: {
            method: 'POST',
            body: JSON.stringify({ schedule }),
        },
    },
    payload: { schedule },
});

export const removeWorkSchedule = ({ day, shift, from_hour, to_hour }) => ({
    [CALL_API]: {
        types: [
            actionTypes.REMOVE_WORK_SCHEDULE_REQUEST,
            actionTypes.REMOVE_WORK_SCHEDULE_SUCCESS,
            actionTypes.REMOVE_WORK_SCHEDULE_FAILURE,
        ],
        url: urls.REMOVE_WORK_SCHEDULE,
        fetchOptions: {
            method: 'POST',
            body: JSON.stringify({ day, shift, from_hour, to_hour }),
        },
    },
    payload: { day, shift, from_hour, to_hour },
});

export const getWorkScheduleDisplay = () => ({
    [CALL_API]: {
        types: [
            actionTypes.GET_WORK_SCHEDULE_DISPLAY_REQUEST,
            actionTypes.GET_WORK_SCHEDULE_DISPLAY_SUCCESS,
            actionTypes.GET_WORK_SCHEDULE_DISPLAY_FAILURE,
        ],
        url: urls.GET_WORK_SCHEDULE_DISPLAY,
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const GetPublicationsContents = ({ expertId, search }) => ({
    [CALL_API]: {
        types: [
            actionTypes.GET_PUBLICATIONS_CONTENTS_REQUEST,
            actionTypes.GET_PUBLICATIONS_CONTENTS_SUCCESS,
            actionTypes.GET_PUBLICATIONS_CONTENTS_FAILURE,
        ],
        url: urls.GET_PUBLICATIONS_CONTENTS(expertId) + (search ? '?' + new URLSearchParams(search) : ''),
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const publicationGetList = () => ({
    [CALL_API]: {
        types: [
            actionTypes.PUBLICATION_GET_LIST_REQUEST,
            actionTypes.PUBLICATION_GET_LIST_SUCCESS,
            actionTypes.PUBLICATION_GET_LIST_FAILURE,
        ],
        url: urls.PUBLICATION_GET_LIST,
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const displayPublication = ({ publication_type, publication_id }) => ({
    [CALL_API]: {
        types: [actionTypes.DISPLAY_PUBLICATION_REQUEST, actionTypes.DISPLAY_PUBLICATION_SUCCESS, actionTypes.DISPLAY_PUBLICATION_FAILURE],
        url: urls.DISPLAY_PUBLICATION(publication_type, publication_id),
        fetchOptions: {
            method: 'GET',
        },
    },
});

export const submitTweet = ({ text }) => ({
    [CALL_API]: {
        types: [actionTypes.SUBMIT_TWEET_REQUEST, actionTypes.SUBMIT_TWEET_SUCCESS, actionTypes.SUBMIT_TWEET_FAILURE],
        url: urls.SUBMIT_TWEET,
        fetchOptions: {
            method: 'POST',
            body: JSON.stringify({ text }),
        },
    },
    payload: { text },
});

export const deleteTweet = ({ tweetId }) => ({
    [CALL_API]: {
        types: [actionTypes.DELETE_TWEET_REQUEST, actionTypes.DELETE_TWEET_SUCCESS, actionTypes.DELETE_TWEET_FAILURE],
        url: urls.DELETE_TWEET(tweetId),
        fetchOptions: {
            method: 'POST',
        },
    },
});

export const likeTweet = ({ tweetId }) => ({
    [CALL_API]: {
        types: [actionTypes.LIKE_TWEET_REQUEST, actionTypes.LIKE_TWEET_SUCCESS, actionTypes.LIKE_TWEET_FAILURE],
        url: urls.LIKE_TWEET(tweetId),
        fetchOptions: {
            method: 'POST',
        },
    },
});

export const copyTrade = ({ id }) => ({
    [CALL_API]: {
        types: [actionTypes.COPY_TRADING_REQUEST, actionTypes.COPY_TRADING_SUCCESS, actionTypes.COPY_TRADING_FAILURE],
        url: urls.COURSE_URL(id),
        fetchOptions: {
            method: 'GET',
        },
    },
});
