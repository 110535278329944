import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { Button } from '@material-ui/core';
import { Box } from '@material-ui/core';
import strings from 'translations/translations';
import { loginByPhoneCode, resetAuth, phoneAuth } from '../../../redux/actions/account';
import CustomVerificationCode from '../../Input/CustomVerificationCode';
import SendCodeBtn from './components/SendCodeBtn';
import FromTemplate from './pageTemplate/FormTemplate';
import CustomButton from '../../CustomButton';

import * as pages from './pages';

function Code({ loginByPhoneCode, auth_data, setCurrentPage, resetAuth, errors, token }) {
	const history = useHistory();
	const [code, setCode] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = async () => {
		await loginByPhoneCode({ code, phone_number: auth_data.phone_number });
	};

	useEffect(() => {
		if (code.length === 6) {
			onSubmit();
		}
	}, [code]);

	const back = (e) => {
		setCurrentPage(pages.PHONE);
	};
	return (
		<div
			className={
				'shadow-white sm:shadow-md bg-white flex justify-center h-[40.5rem] flex-col rounded-2xl sm:border-1 border-slate-300 px-10 pt-5 pb-2 w-full'
			}>
			<div className="self-start text-center text-3xl font-bold pt-8 sm:pb-6">
				<span>{strings.auth.disposable.title}</span>
			</div>
			<div
				className="text-right pb-6 font-normal ltr:text-left rtl:text-right"
				style={{
					fontSize: '17px',
					lineHeight: '3rem',
				}}>
				{' '}
				{strings.formatString(strings.auth.enter_code, {
					phone_number: auth_data.phone_number,
				})}
			</div>

			<div className="my-4">
				<div className="">
					<Box mb={3} mt={1}>
						<CustomVerificationCode
							length={6}
							placeholder=""
							isInvalid={false}
							onChange={(newCode) => {
								setCode(newCode);
							}}
						/>
					</Box>
				</div>
			</div>
			<SendCodeBtn />
			<div className="my-4 pb-0">
				<button onClick={back} for="Name" className="block text-2xl font-medium text-blue-600">
					{strings.auth.disposable.enter_with_pass}
				</button>
			</div>
			<div className="mb-4 mt-8 pb-5">
				<Button
					disableElevation
					disabled={isSubmitting || code.length < 6}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					onClick={onSubmit}
					className="btn-disabled"
					color="primary">
					<span className="text-3xl font-bold py-4">{strings.auth.login}</span>
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	auth_data: state.account.auth_data,
	errors: state.notifications.notifications.errors,
	token: !!state.account.token,
});

export default connect(mapStateToProps, {
	loginByPhoneCode,
	phoneAuth,
	resetAuth,
})(Code);
