import { ReactComponent as Bell } from 'assets/icon/notification2.svg';

const NotificationIcon = ({ count, showSidebarNotifications }) => (
	<button
		class="relative border-2 border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out"
		onClick={(event) => showSidebarNotifications()}
		aria-label="Cart">
		<Bell width={15} height={15} />
		{count !== 0 && (
			<span class="absolute inset-0 object-right-top -mr-6">
				<div
					style={{
						borderRadius: '6px',
						lineHeight: '10px',
						paddingTop: '5px',
						width: '18px',
						height: '18px',
						textAlign: 'center',
						placeContent: 'center',
					}}
					class="inline-flex items-center px-2 py-1 border-2 border-white rounded-full text-lg font-semibold leading-5 bg-red-500 text-white">
					{count}
				</div>
			</span>
		)}
	</button>
);

export default NotificationIcon;
