import { connect } from 'react-redux';
import { IntlActions } from 'react-redux-multilingual';
import { initializeStore } from 'redux/store/configureStore.dev';
import LoggedInAccountButton from './LoggedInAccountButton';
import AccountManagement from './AccountManagement';
import NotLoggedInAccountButton from './NotLoggedInAccountButton';
import { useHistory, useLocation } from 'react-router-dom';
import strings from 'translations/translations';
import classnames from 'classnames';
import Daraya from 'assets/icon/coinson.png';
import BrandCoinson from 'assets/icon/brand-coinson.png';

import { ReactComponent as GroupIconCoinson } from 'assets/icon/group-logo.svg';

const AccountButton = ({ isLoggedInCustomer, isLoggedInExpert, locale, backmap }) => {
	const store = initializeStore();
	let history = useHistory();
	const location = useLocation();
	String.prototype.replaceAt = function (index, replacement) {
		return this.substring(0, index) + replacement + this.substring(index + replacement.length);
	};

	const handleChangeLocale = (e) => {
		window.location.replace(window.location.pathname.replaceAt(1, e.target.value));
		store.dispatch(IntlActions.setLocale(e.target.value));
		strings.setLanguage('en');
	};

	return (
		<>
			{isLoggedInCustomer && <LoggedInAccountButton />}
			{isLoggedInExpert && <AccountManagement />}
			{!isLoggedInCustomer && !isLoggedInExpert && <NotLoggedInAccountButton />}
			{!isLoggedInCustomer && (
				<select
					name="risk"
					value={locale}
					onChange={handleChangeLocale}
					className={classnames(
						locale === 'en' ? 'select_arrow_right_lang pr-12 pl-6 dir-ltr' : 'select_arrow pl-16 pr-6 dir-rtl',
						'rtl:direction-rtl border-0 bg-white text-blue-600 0 text-xl font-medium border-slate-300 rounded-full ring-0 focus:ring-blue-500 focus:border-blue-500 block w-full hidden md:block',
					)}>
					<option value="en">English</option>
					<option value="ar">العربیة</option>
				</select>
			)}
		</>
	);
};

const mapStateToProps = ({ account, Intl }) => ({
	locale: Intl.locale,
	isLoggedInCustomer:
		account.token &&
		account.user.role === 'CUSTOMER' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
	isLoggedInExpert:
		account.token &&
		account.user.role === 'EXPERT' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
});

export default connect(mapStateToProps)(AccountButton);
