import styled from 'styled-components';

import colors from '../../theme/theme.js';

export const Row = styled.div`
  span {
    color: #d80000;
    font-weight: 400;
    display: inline-block;
    margin-top: 0.5rem;
  }
`;

export const Input = styled.input`
  direction: rlt;
  width: 100%;
  height: 4.5rem;
  padding: 1rem;
  border-right: ${({ errors }) =>
    errors
      ? '0.3rem solid #d80000'
      : `0.3rem solid
    ${colors.palette.primary.main}`};
  border-left: 0.1rem solid #f6f6f6;
  border-top: 0.1rem solid #f6f6f6;
  border-bottom: 0.1rem solid #f6f6f6;
  background: ${({ errors }) => (errors ? '#ffd6d6' : '#f6f6f6')};
  display: block;
  outline: none;
  margin: auto;

  &:hover {
    background: #ecf3f9;
  }

  &:focus {
    border-left: 0.1rem solid ${colors.palette.primary.main};
    border-top: 0.1rem solid ${colors.palette.primary.main};
    border-bottom: 0.1rem solid ${colors.palette.primary.main};
    background: ${colors.palette.white.main};
  }
`;
