import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
//assets
import Daraya from 'assets/icon/coinson.png';

import { logout } from 'redux/actions/account';

import { ReactComponent as Home } from 'assets/icon/home2.svg';
import { ReactComponent as HomeSelected } from 'assets/icon/home3.svg';

import { ReactComponent as Investment } from 'assets/icon/investment_portfolio.svg';
import { ReactComponent as InvestmentSelected } from 'assets/icon/investment_portfolio2.svg';

import { ReactComponent as Wallet } from 'assets/icon/wallet.svg';
import { ReactComponent as WalletSelected } from 'assets/icon/wall2et.svg';

import { ReactComponent as Traders } from 'assets/icon/user_account.svg';
import { ReactComponent as TradersSelected } from 'assets/icon/user_accoun3t.svg';

import { ReactComponent as Consultation } from 'assets/icon/ask_question2.svg';
import { ReactComponent as ConsultationSelected } from 'assets/icon/ask_question.svg';

import { ReactComponent as Support } from 'assets/icon/online_support.svg';
import { ReactComponent as SupportSelected } from 'assets/icon/online_support2.svg';

import { ReactComponent as Academy } from 'assets/icon/student_male2.svg';
import { ReactComponent as AcademySelected } from 'assets/icon/student_male.svg';

import { ReactComponent as How } from 'assets/icon/safety_float.svg';
import { ReactComponent as HowSelected } from 'assets/icon/safety_floaqt.svg';

import { ReactComponent as Invitation } from 'assets/icon/trus2.svg';
import { ReactComponent as InvitationSelected } from 'assets/icon/trust.svg';

import { ReactComponent as Settings } from 'assets/icon/settings3.svg';
import { ReactComponent as SettingsSelected } from 'assets/icon/setting2s.svg';

import { ReactComponent as Payments } from 'assets/icon/payments.svg';
import { ReactComponent as PaymentsSelected } from 'assets/icon/paymentsSelected.svg';

import { ReactComponent as AreYouTrader } from 'assets/icon/user_account.svg';
import { ReactComponent as AreYouTraderSelected } from 'assets/icon/user_accoun3t.svg';

import { ReactComponent as Logout } from 'assets/icon/logout.svg';
import { ReactComponent as LogoutSelected } from 'assets/icon/logout-1.svg';

import { ReactComponent as QTrader } from 'assets/icon/q_trader.svg';

// import { ReactComponent as GlobeLang } from 'assets/icon/globe-alt.svg';
import { ReactComponent as GlobeLang } from 'assets/icon/icons8_language_skill.svg';

import strings from 'translations/translations';

//sj
import { IntlActions } from 'react-redux-multilingual';
import { initializeStore } from 'redux/store/configureStore.dev';

const baseOfAnotherHost = 'https://coinson.co';

const SideBar = ({ isLoggedInCustomer, isLoggedInExpert, locale, logout }) => {
	//sj
	const store = initializeStore();
	const handleChangeLocale = (e) => {
		window.location.replace(window.location.pathname.replaceAt(1, e.target.value));
		store.dispatch(IntlActions.setLocale(e.target.value));
		strings.setLanguage('en');
	};

	let location = useLocation();
	const history = useHistory();
	const activeRoute = (routeName) => {
		// console.log(location.pathname, 'location');
		return location.pathname.includes(routeName);
	};
	const logOutHandler = (e) => {
		logout();
		history.push({ pathname: '/traders' });
	};
	if (activeRoute('auth')) {
		return null;
	} else {
		return (
			<div className="bg-white ease-linear duration-200 w-[300px] h-screen m-0 min-h-full overflow-x-hidden shadow-md">
				<div className="relative overflow-hidden w-full h-full">
					<div className="overflow-hidden absolute inset-0 overflow-auto ltr:-mr-[17px] rtl:-ml-[17px] rtl:mr-[25px] ltr:ml-[25px] mt-8">
						<a target="_self" href={'//' + process.env.REACT_APP_HOME_URL} className="hidden md:flex items-center">
							<img
								className="align-middle h-10"
								style={{ width: '15rem', height: '3.9rem' }}
								src={Daraya}
								alt="coinson"
							/>
							{/* <span className="rtl:mr-2 ltr:ml-2 font-medium">{strings.sidebar.title}</span> */}
						</a>
						<div className="flex flex-col h-full pt-[4rem] mr-4 overflow-scroll">
							<div>
								{(isLoggedInCustomer || isLoggedInExpert) && (
									<Link className="flex items-center mt-8" to="/home">
										{activeRoute('home') ? <HomeSelected width={15} /> : <Home width={15} />}
										<span
											className={`${
												activeRoute('home')
													? 'text-primary font-semibold'
													: 'text-[#727D8F] font-normal'
											}  rtl:mr-6 ltr:ml-6 text-[1.45rem]`}>
											{strings.sidebar.home}
										</span>
									</Link>
								)}
								{(isLoggedInCustomer || isLoggedInExpert) && (
									<Link className="flex items-center mt-8" to="/my_copytrade_portfolio">
										{activeRoute('my_copytrade_portfolio') ? (
											<InvestmentSelected width={15} />
										) : (
											<Investment width={15} />
										)}
										<span
											className={`${
												activeRoute('my_copytrade_portfolio')
													? 'text-primary font-semibold'
													: 'text-[#727D8F] font-normal'
											}  rtl:mr-6 ltr:ml-6 text-[1.45rem]`}>
											{strings.sidebar.my_portfolio}
										</span>
									</Link>
								)}
								{(isLoggedInCustomer || isLoggedInExpert) && (
									<Link className="flex items-center mt-8" to="/my_wallet">
										{activeRoute('my_wallet') ? (
											<WalletSelected width={15} />
										) : (
											<Wallet width={15} />
										)}
										<span
											className={`${
												activeRoute('my_wallet')
													? 'text-primary font-semibold'
													: 'text-[#727D8F] font-normal'
											}  rtl:mr-6 ltr:ml-6 text-[1.45rem]`}>
											{strings.sidebar.my_wallet}
										</span>
									</Link>
								)}
								<Link className="flex items-center mt-8" to="/traders">
									{activeRoute('traders') ? <TradersSelected width={15} /> : <Traders width={15} />}
									<span
										className={`${
											activeRoute('traders')
												? 'text-primary font-semibold'
												: 'text-[#727D8F] font-normal'
										}  rtl:mr-6 ltr:ml-6 text-[1.45rem]`}>
										{strings.sidebar.traders}
									</span>
								</Link>
								{/* <Link className="flex items-center mt-8">
									<Consultation width={15} />
									<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
										{strings.sidebar.consultations}
									</span>
								</Link> */}
								{/* {(isLoggedInCustomer || isLoggedInExpert) && (
									<Link className="flex items-center mt-8" to="/settings">
										{activeRoute('settings') ? (
											<SettingsSelected width={15} />
										) : (
											<Settings width={15} />
										)}
										<span
											className={`${
												activeRoute('settings')
													? 'text-primary font-semibold'
													: 'text-[#727D8F] font-normal'
											}  rtl:mr-6 ltr:ml-6 text-[1.45rem]`}>
											{strings.sidebar.settings}
										</span>
									</Link>
								)} */}
								{(isLoggedInCustomer || isLoggedInExpert) && (
									<Link className="flex items-center mt-8" to="/finance">
										{activeRoute('finance') ? (
											<PaymentsSelected width={18} height={18} />
										) : (
											<Payments width={18} height={18} />
										)}
										<span
											className={`${
												activeRoute('finance')
													? 'text-primary font-semibold'
													: 'text-[#727D8F] font-normal'
											}  rtl:mr-6 ltr:ml-6 text-[1.45rem]`}>
											{strings.sidebar.finance}
										</span>
									</Link>
								)}
							</div>
							<hr className=" mt-8 bg-slate-200 rtl:ml-16 ltr:mr-16 rtl:-mr-3 ltr:-ml-3" />
							<div>
								<a
									className="flex items-center mt-8"
									href={
										locale === 'ar'
											? new URL(
													'https://coinson.co/ar/need-help/%d8%a7%d8%ad%d8%b5%d9%84-%d8%b9%d9%84%d9%89-%d8%a7%d9%84%d8%af%d8%b9%d9%85/',
													baseOfAnotherHost,
											  ).href
											: new URL('https://coinson.co/help/support/', baseOfAnotherHost).href
									}
									target="_blank">
									<Support width={15} />
									<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
										{strings.sidebar.support}
									</span>
								</a>
								<a
									className="flex items-center mt-8"
									href={
										locale === 'ar'
											? new URL(
													'https://coinson.co/ar/need-help/%d8%a7%d9%84%d8%a3%d9%83%d8%a7%d8%af%d9%8a%d9%85%d9%8a%d8%a9/',
													baseOfAnotherHost,
											  ).href
											: new URL('https://coinson.co/help/academy/', baseOfAnotherHost).href
									}
									target="_blank">
									<Academy width={15} />
									<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
										{strings.sidebar.academy}
									</span>
								</a>
								<a
									className="flex items-center mt-8"
									href={
										locale === 'ar'
											? new URL(
													'https://coinson.co/ar/need-help/%d8%aa%d8%ad%d8%aa%d8%a7%d8%ac-%d9%85%d8%b3%d8%a7%d8%b9%d8%af%d8%a9%d8%9f/',
													baseOfAnotherHost,
											  ).href
											: new URL('https://coinson.co/help/helpcenter/', baseOfAnotherHost).href
									}
									target="_blank">
									<How width={15} />
									<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
										{strings.sidebar.how_to_use}
									</span>
								</a>
								{/* {(isLoggedInCustomer || isLoggedInExpert) && (
									<Link className="flex items-center mt-8">
										<Invitation width={15} />
										<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
											{strings.sidebar.invitation}
										</span>
									</Link>
								)} */}
							</div>
							<hr className=" mt-8 bg-slate-200 rtl:ml-16 ltr:mr-16 rtl:-mr-3 ltr:-ml-3" />
							<div>
								<a
									className="flex items-center mt-8"
									href={new URL('https://coinson.co/traders/', baseOfAnotherHost).href}
									target="_blank">
									<QTrader width={15} />
									<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
										{strings.sidebar.q_trader}
									</span>
								</a>

								<div
									className="flex items-center mt-8"
									href={new URL('https://coinson.co/traders/', baseOfAnotherHost).href}
									target="_blank">
									<GlobeLang width={20} height={20} />
									{/* <button
										className="text-[#727D8F] rtl:mr-2 ltr:ml-2 text-[1.45rem] font-normal"
										value="fa"
										onClick={handleChangeLocale}>
										{locale === 'fa' ? null : 'فارسی | '}
									</button> */}

									<button
										className="text-[#727D8F] rtl:mr-2 ltr:ml-2 text-[1.45rem] font-normal"
										value="en"
										onClick={handleChangeLocale}>
										{locale === 'en' ? null : `English ${locale === 'ar' ? '' : ' |'}`}
									</button>
									<button
										className="text-[#727D8F] rtl:mr-2 ltr:ml-2 text-[1.45rem] font-normal"
										value="ar"
										onClick={handleChangeLocale}>
										{locale === 'ar' ? null : 'العربیة'}
									</button>
								</div>

								{(isLoggedInCustomer || isLoggedInExpert) && (
									<Link className="flex items-center mt-8" to="/traders" onClick={logOutHandler}>
										{locale === 'en' ? (
											<Logout width={15} style={{ transform: 'scale(-1,1)' }} />
										) : (
											<Logout width={15} />
										)}
										{/* <Logout width={15} /> */}
										<span className="text-[#C60F21] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
											{strings.sidebar.logout}
										</span>
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = ({ account, Intl }) => ({
	locale: Intl.locale,
	isLoggedInCustomer:
		account.token &&
		account.user.role === 'CUSTOMER' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
	isLoggedInExpert:
		account.token &&
		account.user.role === 'EXPERT' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
});

export default connect(mapStateToProps, { logout })(SideBar);
