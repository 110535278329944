import classnames from 'classnames';
import MedalWinner from 'assets/icon/medal-winner.png';
import MeddalSecond from 'assets/icon/medal-second.png';
import Avatar from 'assets/icon/profile-mock.svg';
import { tLocalNum } from 'utils/translateNumber';
import { Link } from 'react-router-dom';
import strings from 'translations/translations';

const medal = {
	1: MedalWinner,
	2: MeddalSecond,
};

export const ExpertShortCardHeader = ({ expert, expertId, course = false, job }) => {
	return (
		<>
			<div className="">
				<div className="flex justify-start flex-row">
					<img className="w-18 h-18 rounded-xl rtl:ml-4 ltr:mr-4" src={expert?.image ? expert.image : Avatar} alt="" />
					<div className="flex flex-col justify-around">
						<div className={'flex flex-wrap items-center mb-1'}>
							{expert?.rate && <img src={medal[expert.rate]} alt="medal" className="ml-1 h-8 w-8" />}
							<Link
								to={{
									pathname: `/experts/${expertId ? expertId : expert?.id}`,
									expertProfile: expert,
								}}>
								<h5 className="text-profileHeader font-medium leading-5 inline-block my-0 mr-1 ml-2 text-primary">
									{expert?.full_name}
								</h5>
							</Link>
						</div>
						<div>
							<h4 className={'text-black h-12 mlg:h-full text-lg font-medium'}>{job}</h4>
						</div>
					</div>
				</div>
			</div>
			{!course && (
				<div className="mt-3 lsm:mt-0">
					<div className={'text-xl mt-2 mb-6 mx-0  font-normal text-contentExpert'}>
						{expert && (
							<span className={'text-xl'} title={expert?.about}>
								{expert && expert?.about?.substr(0, 30)}
							</span>
						)}
						{expert && expert?.about?.length > 30 && <span>...</span>}
					</div>
				</div>
			)}
			{!course && (
				<div className="">
					<div className="flex justify-start flex-row items-center">
						<div className="p-6 ml-4 text-3xl rounded-xl font-medium flex justify-center items-center h-16 w-16 bg-successLight text-harmony-successLight">
							{expert?.score && tLocalNum(expert?.score)}
						</div>
						<div className="text-xl mx-0 flex flex-col items-start justify-start font-normal">
							<div className="flex justify-between ml-4">
								<div className="ml-4">
									{strings.formatString(
										strings.symbols.colon,
										strings.profileExpert.efficiency_monthly,
									)}
								</div>
								<div className="text-primary">
									{expert?.monthly_profit &&
										strings.formatString(strings.symbols.percent, tLocalNum(expert.monthly_profit))}
								</div>
							</div>
							<div className="flex justify-between ml-4">
								<div className="ml-4">
									{strings.formatString(
										strings.symbols.colon,
										strings.profileExpert.efficiency_activity,
									)}
								</div>
								<div className="text-primary">
									{expert?.total_profit &&
										strings.formatString(strings.symbols.percent, tLocalNum(expert.total_profit))}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export const ExpertShortCardBody = ({ expert }) => {
	return (
		<div className="mt-4 font-light mb-8">
			<div className="flex justify-start text-blue-900 text-lg font-medium">
				<div className="ml-2">{strings.formatString(strings.symbols.colon, strings.followers)}</div>
				<div className="">
					{expert?.followers && strings.formatString(strings.countingUnits.peoples, { count: expert?.followers })}
				</div>
			</div>
			<div className="flex justify-start text-blue-900 text-lg font-medium mt-1">
				<div className="ml-2">{strings.formatString(strings.symbols.colon, strings.number_courses)}</div>
				<div className="mx-3">
					{expert?.period_count && strings.formatString(strings.countingUnits.cousrse, { count: expert?.period_count })}
				</div>
			</div>
		</div>
	);
};

export const ExpertShortCardFooter = ({ expert }) => {
	return <div>Footer</div>;
};

export const ExpertShortCard = ({ expert, className = '' }) => {
	return (
		<div
			className={classnames(
				'flex flex-col  p-5 pl-0 m-2 border-blue-200 border-1 rounded-lg  ',
				className.length ? className : '',
			)}>
			<ExpertShortCardHeader expert={expert} />
			<ExpertShortCardBody expert={expert} />
		</div>
	);
};
