import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import { checkRegistered } from '../../../redux/actions/account';
import PhoneInput from '../../Input/PhoneInput';
import * as pages from './pages';
import FromTemplate from './pageTemplate/FormTemplate';
import CustomButton from '../../CustomButton';
import strings from 'translations/translations';
import translateNumber from '../../../utils/translateNumber.js';
import { useHistory, useLocation } from 'react-router-dom';
import GoogleAuth from './GoogleAuth';

function Phone({ setCurrentPage, checkRegistered, setStep }) {
	const [phone_number, setPhoneNumber] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		// history.push({ search: '?email=' + location.search });
	}, []);

	const onSubmit = async (e) => {
		setStep(1);
		setIsSubmitting(true);
		// await checkRegistered({
		// 	phone_number: translateNumber({ lang: 'en', num: phone_number }),
		// });
		await checkRegistered({
			phone_number: phone_number,
		});
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		if (params.email) {
			let currentUrlParams = new URLSearchParams(location.search);
			currentUrlParams.set('email', phone_number);
			// window.location.search = params;
			history.push(location.pathname + '?' + currentUrlParams.toString());
			// const varSign = location.search.length > 0 ? '&' : '';
			// history.push({ search: location.search + varSign + 'email=' + phone_number });
		} else {
			const varSign = location.search.length > 0 ? '&' : '';
			history.push({ search: location.search + varSign + 'email=' + phone_number });
		}
		setIsSubmitting(false);
	};

	return (
		<div
			style={{ height: '35rem' }}
			className={
				'shadow-white sm:shadow-md bg-white flex justify-center flex-col rounded-2xl sm:border-1 border-slate-300 px-10 pt-5 pb-2 w-full'
			}>
			{/* <GoogleAuth /> */}
			<div className="self-start text-center text-3xl font-bold py-5 sm:pb-10">
				<span>{strings.auth.title}</span>
			</div>
			<div className="my-4">
				<div className="">
					<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
						{strings.settings_page.account_security.email}
					</label>
					<label className="relative block">
						<input
							autoFocus
							style={{ height: '60px' }}
							className=" font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 ltr:pl-3 ltr:pr-9 rtl:pl-9 rtl:pr-3 focus:outline-none"
							onChange={(event) => setPhoneNumber(event.target.value)}
							value={phone_number}
							type="text"
							name="username"
							id="username"
							aria-label="username"
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									onSubmit();
								}
							}}
						/>
					</label>
				</div>
			</div>
			{/* <div className="my-4 pb-5">
				<button onClick={() => setCurrentPage(pages.EMAIL)} className="block mb-1 text-2xl font-medium text-blue-600">
					{strings.auth.email.title}
				</button>
			</div> */}
			<div className="my-4 pb-2">
				<Button
					disableElevation
					disabled={isSubmitting}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					color="primary"
					onKeyPress={(event) => {
						if (event.key === 'Enter') {
							onSubmit();
						}
					}}
					onClick={onSubmit}>
					<span className="text-3xl py-4 font-bold">{strings.continues}</span>
				</Button>
			</div>
			<div className="flex justify-center underline pb-2">
				<Button onClick={(e) => setCurrentPage(pages.PHONE_REFERAL)}>{strings.auth.login_hint}</Button>
			</div>
		</div>
	);
}

export default connect(null, { checkRegistered })(Phone);
