import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

import strings from 'translations/translations';
import * as pages from './pages';
import { loginByPhonePassword, phoneAuth } from '../../../redux/actions/account';
import { OutlinedInput, IconButton, makeStyles, InputAdornment } from '@material-ui/core';
import classNames from 'classnames';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PasswordInput from '../../Input/PasswordInput';
import FromTemplate from './pageTemplate/FormTemplate';
import { resetAuth } from '../../../redux/actions/account';
import CustomButton from '../../CustomButton';
import translateNumber from '../../../utils/translateNumber.js';

const useStyles = makeStyles((theme) => ({
	input: {
		borderWidth: '1.6px',
		'&.Mui-focused': {
			borderColor: 'rgb(59 130 246)',
			borderWidth: '1.6px',
		},
	},
}));
function EmailAndPassword({ setCurrentPage, auth_data, setStep, loginByPhonePassword, resetAuth, locale, phoneAuth, token }) {
	const history = useHistory();
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const classes = useStyles();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const onSubmit = async () => {
		await loginByPhonePassword({
			password,
			phone_number: translateNumber({
				lang: 'en',
				num: auth_data.phone_number,
			}),
		});
	};

	const back = (e) => {
		setStep(0);
		setCurrentPage(pages.PHONE);
	};

	return (
		<div
			style={{ height: '35rem' }}
			className={
				'shadow-white sm:shadow-md bg-white flex justify-center h-39.5 flex-col rounded-2xl sm:border-1 border-slate-300 px-10 pt-5 pb-2 w-full'
			}>
			This is email
			<div className="self-start text-center text-3xl font-bold py-5 sm:pb-10">
				<span>{strings.auth.password_login.title}</span>
			</div>
			<div className="my-4">
				<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
					{strings.auth.password_login.password}
				</label>
				<OutlinedInput
					id="outlined-adornment-password-login"
					type={showPassword ? 'text' : 'password'}
					value={password}
					className={classNames(
						classes.input,
						'pr-0 font-4xl font-bold text-4xl px-4 text-blue-600 rounded-xl py-0 focus:ring-blue-500 focus:border-blue-500 focus:ring-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-gray-200 rounded-md py-4 ltr:pl-3 ltr:pr-9 rtl:pl-9 rtl:pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1',
					)}
					onChange={(event) => setPassword(event.target.value)}
					dir={locale === 'en' ? 'ltr' : 'rtl'}
					onKeyPress={(event) => {
						if (event.key === 'Enter') {
							onSubmit();
						}
					}}
					endAdornment={
						<InputAdornment position="end" className={`${locale === 'en' ? 'hidden' : ''}`}>
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end">
								{showPassword ? (
									<Visibility style={{ fontSize: '2rem' }} />
								) : (
									<VisibilityOff style={{ fontSize: '2rem' }} />
								)}
							</IconButton>
						</InputAdornment>
					}
					style={{ fontSize: '1.5rem', fontWeight: '500' }}
					inputProps={{
						paddingRight: '0',
						classes: {
							paddingRight: '0',
							fontSize: '2rem',
						},
					}}
				/>
			</div>
			<div className="my-4 pb-2">
				<button
					onClick={() => {
						phoneAuth({ phone_number: auth_data.phone_number });
						setCurrentPage(pages.CODE);
					}}
					className="block mb-1 text-2xl font-medium text-blue-600">
					{strings.auth.password_login.disposable}
				</button>
			</div>
			<div className="mb-4 pb-0">
				<button onClick={back} for="Name" className="block mb-1 text-2xl font-medium text-blue-600">
					{strings.auth.change_phone_num}
				</button>
			</div>
			<div className="my-4 pb-5">
				<Button
					disableElevation
					disabled={isSubmitting}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					color="primary"
					className="btn-disabled"
					onClick={onSubmit}>
					<span className="text-3xl py-4 font-bold">{strings.auth.login}</span>
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = ({ account, Intl }) => ({
	locale: Intl.locale,
	auth_data: account.auth_data,
	token: !!account.token,
});

export default connect(mapStateToProps, {
	loginByPhonePassword,
	phoneAuth,
	resetAuth,
})(EmailAndPassword);
