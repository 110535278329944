import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  notifications: {
    messages: [],
    errors: [],
    showSideBar : false,
  },
};

function notification(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        notifications: {
          messages: action.message,
        },
      };
    case actionTypes.REMOVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          messages: [],
        },
      };

    case actionTypes.ENQUEUE_SNACKBAR:
      return enquequeSnackbar({ state, ...action });

    case actionTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.errors.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };

    case actionTypes.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: {
          errors:
            state.notifications.errors &&
            state.notifications.errors.length > 0 &&
            state.notifications.errors.filter(
              (notification) => notification.key !== action.key
            ),
        },
      };

    case actionTypes.SHOW_ERROR_MESSAGE:
      return enquequeSnackbar({
        state,
        myerror: action.response,
        notification: {
          message: action.error,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        },
      });
      case actionTypes.SHOW_SIDEBAR_NOTIFICATIONS:
      return {
        ...state,
        showSideBar: true,
      };
      case actionTypes.CLOSE_SIDEBAR_NOTIFICATIONS:
      return {
        ...state,
        showSideBar: false,
      };

    default:
      return state;
  }
}

const enquequeSnackbar = ({ state, notification }) => ({
  ...state,
  notifications: {
    ...state.notifications,
    errors: [
      {
        key: notification.options.key,
        ...notification,
      },
    ],
  },
});

export default notification;
