import * as actionTypes from '../actions/actionTypes';

const defaultState = {
	name: '',
	id: null,
	image: null,
	configs: {
		notification: {
			count: 0,
		},
	},
};

function config(state = defaultState, action) {
	switch (action.type) {
		case actionTypes.GET_COUNT:
			return {
				...state,
				configs: {
					notification: {
						count: action.payload.count,
					},
				},
			};

		default:
			return state;
	}
}

export default config;
