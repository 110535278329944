import { IconButton, makeStyles } from '@material-ui/core';
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ReactComponent as MessageIcon } from 'assets/icon/messageIcon.svg';

import { removeSnackbar } from '../../../../redux/actions/notifications';
import * as actionTypes from '../../../../redux/actions/actionTypes';
import { initializeStore } from 'redux/store/configureStore.dev';

import { ReactComponent as User } from 'assets/icon/user-black.svg';
import { ReactComponent as Exit } from 'assets/icon/exit.svg';
import { logout } from 'redux/actions/account';
import FakePNG from 'assets/icon/streamHeader.svg';
import strings from 'translations/translations';
import { showSidebarNotifications, closeSidebarNotifications } from 'redux/actions/notifications';
import NotificationIcon from './NotificationIcon';
import ProfilePicture from '../../../User/ProfilePicture';
import { ReactComponent as Settings } from 'assets/icon/settings.svg';
import { ReactComponent as Logout } from 'assets/icon/logout.svg';

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: 'none',
	},
	exitButton: {
		marginLeft: theme.spacing(1),
	},
}));

const LoggedInAccountButton = ({ user, locale, state, count, history, logout, removeSnackbar, showSidebarNotifications }) => {
	const classes = useStyles();
	const logOutHandler = () => {
		logout();
		history.push('/traders');
	};

	return (
		<>
			<div class="group inline-block relative">
				<button className="mx-4 w-auto min-w-[14rem] max-w-[20rem] h-16 flex items-center justify-between p-4 hover:border-blue-300 bg-[#FAFBFB] rounded-xl">
					<span className="text-primary font-medium ltr:mr-2 rtl:ml-2">{user?.full_name}</span>
					{/* <User className="rtl:mr-4 ltr:ml-4" /> */}
					{/* <ProfilePicture img={FakePNG} name="" className="hover:opacity-100 w-12 h-12" /> */}
					<img className="rounded-md w-11 h-11" src={FakePNG} alt="user" />
				</button>
				<ul class="absolute hidden w-[15rem] text-gray-700 pt-1 bg-white group-hover:block shadow-2xl">
					<li>
						<Link
							className="h-20 flex items-center rounded-t bg-white hover:bg-gray-300 py-2 px-4 whitespace-no-wrap"
							to="/settings">
							<Settings width={15} className="mx-3" />
							<span className="px-4 font-normal">{strings.sidebar.settings}</span>
						</Link>
					</li>
					<li>
						<Link
							className="h-20 flex items-center rounded-b bg-white hover:bg-gray-300 py-2 px-4 whitespace-no-wrap"
							to="/traders"
							onClick={logOutHandler}>
							{locale === 'en' ? (
								<Logout width={15} className="mx-3" style={{ transform: 'scale(-1,1)' }} />
							) : (
								<Logout width={15} className="mx-3" />
							)}
							<span className="px-4 font-normal">{strings.sidebar.logout}</span>
						</Link>
					</li>
				</ul>
			</div>

			<div className="h-16 w-16 hidden md:flex justify-center item-center rounded-md hover:border-blue-300 bg-[#FAFBFB] rounded-xl">
				<NotificationIcon count={count} showSidebarNotifications={showSidebarNotifications} />
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	locale: state.Intl.locale,
	errors: state.notifications.notifications.errors,
	state: state,
	// user: state.history,
	user: state.account.user,
	count: state.config.configs.notification.count,
});

export default connect(mapStateToProps, { removeSnackbar, showSidebarNotifications, logout })(withRouter(LoggedInAccountButton));
