import React, { useState } from 'react';
import { tLocalNum } from 'utils/translateNumber';
import classnames from 'classnames';
import strings from 'translations/translations';
import NotFound from 'components/NotFound';
import { CircularProgress } from '@material-ui/core';

const ReserveHours = ({ data, handlerId, className, loading = true }) => {
	const [valueSelected, setValueSelected] = useState();

	const selectIdHandler = (id, value) => {
		setValueSelected(value);
		handlerId(id, value);
	};

	return (
		<div className={classnames('p-4 bg-reservePage-TimeSchedule', className ? className : '')}>
			<h3 className="mt-4 mb-8 text-lg">{strings.reservePage.select_start_consultation}</h3>
			{loading && (
				<div className="flex justify-center my-12">
					<CircularProgress />
				</div>
			)}
			{(!data || !data.length) && !loading && <NotFound text={strings.NotFounded.hours_booking} />}
			{data && !loading && (
				<div
					className={classnames(
						'grid grid-cols-4 gap-y-2 gap-x-2 py-8 mt-4 mb-8 sm:grid-cols-7 sm:gap-y-2 sm:gap-x-2 md:grid-cols-7 md:gap-y-3 md:gap-x-2',
						!data.length ? 'hidden' : '',
					)}>
					{data?.map((item, index) => (
						<button
							id={item.id}
							key={index}
							disabled={!item.free}
							className={classnames(
								'p-4 rounded-lg text-xl font-medium lg:p-6',
								valueSelected === item.time
									? 'bg-primary text-center text-white'
									: 'text-primary  bg-white',
							)}
							onClick={() => selectIdHandler(item.id, item.time)}>
							{tLocalNum(item.time)}
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default ReserveHours;
