import { Box } from '@material-ui/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

import classnames from 'classnames';
import strings from 'translations/translations';
import { setAccountData } from '../../../redux/actions/account';
import EmailInput from '../../Input/EmailInput';
import NameInput from '../../Input/NameInput';
import LastNameInput from '../../Input/LastNameInput';
import FromTemplate from './pageTemplate/FormTemplate';

function NameAndEmail({ setAccountData, question, locale }) {
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('');
	const [QA, setQA] = useState('');
	const [answer, setAnswer] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = async () => {
		await setAccountData({ email, first_name, last_name, answer });
	};

	return (
		<div
			className={
				'shadow-white sm:shadow-lg bg-white flex justify-center h-58 flex-col rounded-2xl sm:border-1 border-slate-300 px-10 pt-5 pb-2 w-full'
			}>
			<div className="self-start text-center text-3xl font-bold py-5 sm:pb-10">
				<span>{strings.auth.extra_info.title}</span>
			</div>

			<div className="my-4">
				<div className="">
					<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
						{strings.auth.extra_info.first_name}
					</label>
					<label className="relative block">
						<input
							style={{ height: '50px' }}
							className=" font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none"
							onChange={(event) => setFirstName(event.target.value)}
							value={first_name}
							type="text"
							name="first name"
						/>
					</label>
				</div>
			</div>

			<div className="my-4">
				<div className="">
					<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
						{strings.auth.extra_info.last_name}
					</label>
					<label className="relative block">
						<input
							style={{ height: '50px' }}
							className=" font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none"
							onChange={(event) => setLastName(event.target.value)}
							value={last_name}
							type="text"
							name="last name"
						/>
					</label>
				</div>
			</div>

			<div className="my-4">
				<div className="">
					<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
						{strings.auth.extra_info.email}
					</label>
					<label className="relative block">
						<input
							style={{ height: '50px' }}
							className=" font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none"
							onChange={(event) => setEmail(event.target.value)}
							value={email}
							type="email"
							name="email"
						/>
					</label>
				</div>
			</div>
			<div className="my-4">
				<label for="Name" className="block mb-4 text-2xl font-normal">
					{question?.question}
				</label>

				{question?.multiple_choice && (
					<select
						style={{ height: '50px' }}
						value={answer}
						onChange={(event) => setAnswer(event.target.value)}
						name="period"
						className={classnames(
							locale === 'en' ? 'select_arrow_right' : 'select_arrow',
							'rtl:dir-rtl ltr:dir-ltr border border-slate-30 bg-white text-blue-600 text-2xl font-medium border-slate-300 rounded-lg ring-1 ring-gray-200 ring-inset focus:ring-blue-500 focus:border-blue-500 block w-full py-5 p-6',
						)}>
						<option selected>{strings.auth.extra_info.choose}</option>
						{question?.answers.map((element) => (
							<option value={element.answer}>{element.answer}</option>
						))}
					</select>
				)}
				{!question?.multiple_choice && (
					<label className="relative block">
						<input
							style={{ height: '50px' }}
							className=" font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none"
							onChange={(event) => setAnswer(event.target.value)}
							value={answer}
							type="text"
							name="last name"
						/>
					</label>
				)}
			</div>
			<div className="my-4 pb-5">
				<Button
					disableElevation
					disabled={
						answer === strings.auth.extra_info.choose ||
						answer.trim().length === 0 ||
						first_name.trim().length === 0 ||
						last_name.trim().length === 0 ||
						email.trim().length === 0
					}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					color="primary"
					onClick={onSubmit}>
					<span className="text-3xl py-4">{strings.auth.start}</span>
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	locale: state.Intl.locale,
	question: state.account.question,
});

export default connect(mapStateToProps, { setAccountData })(NameAndEmail);
