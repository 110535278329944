import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import strings from 'translations/translations';
import { Integrations } from '@sentry/tracing';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import ScrollToTop from './root/ScrollToTop.jsx';
import App from './App';
import './assets/styles/App.scss';
import './assets/styles/tailwind.scss';
import { initializeStore } from './redux/store/configureStore.dev.js';
import * as serviceWorker from './serviceWorker';
import PwaInstallPopupIOS from 'react-pwa-install-ios';
import PWAPrompt from 'react-ios-pwa-prompt';
import GA4React from 'ga-4-react';
import TagManager from 'react-gtm-module';
const ga4react = new GA4React('G-KHK5M8M3PP');
ga4react.initialize().then().catch();

const TRACKING_ID = 'G-KHK5M8M3PP'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

hotjar.initialize(2282974, 6);

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://9f153a0924064177a231b779294cac36@sentry.hamravesh.com/178',
		autoSessionTracking: true,
		integrations: [new Integrations.BrowserTracing()],

		tracesSampleRate: 1.0,
	});
}

const persistedState = localStorage.getItem('financeState') ? JSON.parse(localStorage.getItem('financeState')) : {};
const store = initializeStore(persistedState);
store.subscribe(() => {
	const state = store.getState();
	localStorage.setItem(
		'financeState',
		JSON.stringify({
			account: { ...state.account, auth_data: {} },
			Intl: state.Intl,
		}),
	);
});
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const tagManagerArgs = {
	gtmId: 'GTM-PZ8HZQK',
	dataLayer: {
		userProject: 'project',
	},
};

TagManager.initialize(tagManagerArgs);
ReactDOM.hydrate(
	<Router>
		<ScrollToTop>
			<Provider store={store}>
				<PWAPrompt />
				<QueryClientProvider client={queryClient}>
					<App />
				</QueryClientProvider>
			</Provider>
		</ScrollToTop>
	</Router>,
	document.getElementById('root'),
);

serviceWorker.register();
