import jMoment from 'jalali-moment';
import moment from 'moment';

import getLocale from './getLocale';
import translateNumber from './translateNumber';

export const dateTypes = {
	JALALI: 'JALALI',
	GREGORIAN: 'GREGORIAN',
};

const defaultType = {
	fa: dateTypes.JALALI,
	en: dateTypes.GREGORIAN,
};

export const dateFormatter = ({ date = Date.now(), lang = getLocale(), type = defaultType[lang], format = 'YYYY/MM/DD' }) => {
	let enResult;
	switch (type) {
		case dateTypes.JALALI:
			enResult = jMoment(date).locale(lang).format(format);
			break;
		case dateTypes.GREGORIAN:
		default:
			enResult = moment(date).format(format);
	}
	return translateNumber({ lang, num: enResult });
};

export const dateFormatterDay = (item) => {
	let date = new Date(item),
		lang = getLocale();

	let enResult;
	switch (defaultType[lang]) {
		case dateTypes.JALALI:
			enResult = jMoment(date).locale('fa').format('ddd DD MMMM');
			break;
		case dateTypes.GREGORIAN:
		default:
			enResult = moment(date).format('ddd DD MMMM');
	}
	return translateNumber({ lang, num: enResult });
};

export const getDateNow = () => {
	var d = new Date(),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
};

export const getDateCourse = (date, locale = 'fa') => {
	const arr = date.split('-');
	const d = new Date(arr[0], arr[1], arr[2]);
	let options = { year: 'numeric', month: 'long', day: 'numeric' };
	if (locale === 'fa') return new Intl.DateTimeFormat('fa-IR', options).format(d);
	return new Intl.DateTimeFormat('en-US', options).format(d);
};

export const subtractTwoDates = (date1, date2 = moment()) => {
	var date1 = moment(date1);
	var date2 = moment(date2);
	return date2.diff(date1, 'days');
};
