import Code from './Code';
import CodeEmail from './CodeEmail';
import CodeAndPassword from './CodeAndPassword';
import CodeAndPasswordEmail from './CodeAndPasswordEmail';
import Email from './Email';
import NameAndEmail from './NameAndEmail';
import NameAndPhone from './NameAndPhone';
import Password from './Password';
import PasswordEmail from './PasswordEmail';
import Phone from './Phone';
import Success from './Success';
import PhoneReferal from './PhoneReferal';

export const NAME_AND_EMAIL = {
	Component: NameAndEmail,
	type: 'NAME_AND_EMAIL',
};
export const NAME_AND_PHONE = {
	Component: NameAndEmail,
	type: 'NAME_AND_PHONE',
};
export const PHONE = { Component: Phone, type: 'PHONE' };
export const PHONE_REFERAL = { Component: PhoneReferal, type: 'PHONE_REFERAL' };
export const EMAIL = {
	Component: Email,
	type: 'EMAIL',
};
export const CODE_AND_PASSWORD = {
	Component: CodeAndPassword,
	type: 'CODE_AND_PASSWORD',
};
export const CODE_AND_PASSWORD_EMAIL = {
	Component: CodeAndPasswordEmail,
	type: 'CODE_AND_PASSWORD_EMAIL',
};
export const CODE = { Component: Code, type: 'CODE' };
export const CODE_EMAIL = { Component: CodeEmail, type: 'CODE_EMAIL' };
export const PASSWORD = { Component: Password };
export const PASSWORD_EMAIL = { Component: PasswordEmail };
export const SUCCESS = { Component: Success };
