import { Drawer, List } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Daraya from 'assets/icon/coinson.png';
import BrandCoinson from 'assets/icon/brand-coinson.png';
import classnames from 'classnames';
import strings from 'translations/translations';
import { ReactComponent as MessageIcon } from 'assets/icon/messageIcon.svg';
import NotificationIcon from '../NotificationIcon';

import { hasChildren } from 'utils/hasChildren';
import { ReactComponent as CloseLightIcon } from 'assets/icon/closelight.svg';

import Collapse from '@material-ui/core/Collapse';
import { CustomLink } from './CustomLink';

import { showSidebarNotifications } from 'redux/actions/notifications';

import { ReactComponent as Home } from 'assets/icon/home2.svg';
import { ReactComponent as HomeSelected } from 'assets/icon/home3.svg';

import { ReactComponent as Investment } from 'assets/icon/investment_portfolio.svg';
import { ReactComponent as InvestmentSelected } from 'assets/icon/investment_portfolio2.svg';

import { ReactComponent as Wallet } from 'assets/icon/wallet.svg';
import { ReactComponent as WalletSelected } from 'assets/icon/wall2et.svg';

import { ReactComponent as Traders } from 'assets/icon/user_account.svg';
import { ReactComponent as TradersSelected } from 'assets/icon/user_accoun3t.svg';

import { ReactComponent as Consultation } from 'assets/icon/ask_question2.svg';
import { ReactComponent as ConsultationSelected } from 'assets/icon/ask_question.svg';

import { ReactComponent as Support } from 'assets/icon/online_support.svg';
import { ReactComponent as SupportSelected } from 'assets/icon/online_support2.svg';

import { ReactComponent as Academy } from 'assets/icon/student_male2.svg';
import { ReactComponent as AcademySelected } from 'assets/icon/student_male.svg';

import { ReactComponent as How } from 'assets/icon/safety_float.svg';
import { ReactComponent as HowSelected } from 'assets/icon/safety_floaqt.svg';
import { ReactComponent as Invitation } from 'assets/icon/trus2.svg';
import { ReactComponent as InvitationSelected } from 'assets/icon/trust.svg';

import { ReactComponent as Settings } from 'assets/icon/settings3.svg';
import { ReactComponent as SettingsSelected } from 'assets/icon/setting2s.svg';

import { ReactComponent as AreYouTrader } from 'assets/icon/user_account.svg';
import { ReactComponent as AreYouTraderSelected } from 'assets/icon/user_accoun3t.svg';

import { ReactComponent as Logout } from 'assets/icon/logout.svg';
import { ReactComponent as LogoutSelected } from 'assets/icon/logout-1.svg';

//sj
import { IntlActions } from 'react-redux-multilingual';
import { initializeStore } from 'redux/store/configureStore.dev';
import { ReactComponent as GlobeLang } from 'assets/icon/icons8_language_skill.svg';

const baseOfAnotherHost = 'https://coinson.co';

const SideDrawer = ({ locale, logOutHandler, isLoggedIn, showSidebarNotifications }) => {
	//sj
	const store = initializeStore();

	// const handleChangeLocale = (e) => {
	// 	console.log(e.target.value, 'e.target.value');
	// 	store.dispatch(IntlActions.setLocale(e.target.value));
	// 	strings.setLanguage('en');
	// };
	const handleChangeLocale = (e) => {
		window.location.replace(window.location.pathname.replaceAt(1, e.target.value));
		store.dispatch(IntlActions.setLocale(e.target.value));
		strings.setLanguage('en');
	};

	const menu = [
		// {
		// 	id: 0,
		// 	title: strings.sidebar.traders,
		// 	icon: <Traders width={18} />,
		// 	iconSelected: <TradersSelected width={18} />,
		// 	path: '/traders',
		// },
		{
			id: 1,
			title: strings.sidebar.support,
			icon: <Support width={18} />,
			path: '',
			link: 'https://coinson.co/help/support/',
			arabic_link:
				'https://coinson.co/ar/need-help/%d8%a7%d8%ad%d8%b5%d9%84-%d8%b9%d9%84%d9%89-%d8%a7%d9%84%d8%af%d8%b9%d9%85/',
		},
		{
			id: 2,
			title: strings.sidebar.academy,
			icon: <Academy width={18} />,
			path: '',
			link: 'https://coinson.co/help/academy/',
			arabic_link: 'https://coinson.co/ar/need-help/%d8%a7%d9%84%d8%a3%d9%83%d8%a7%d8%af%d9%8a%d9%85%d9%8a%d8%a9/',
		},
		{
			id: 3,
			title: strings.sidebar.how_to_use,
			icon: <How width={18} />,
			path: '',
			link: 'https://coinson.co/help/helpcenter/',
			arabic_link: 'https://coinson.co/ar/need-help/%d8%aa%d8%ad%d8%aa%d8%a7%d8%ac-%d9%85%d8%b3%d8%a7%d8%b9%d8%af%d8%a9%d8%9f/',
		},
		// {
		// 	id: 4,
		// 	title: strings.sidebar.invitation,
		// 	icon: <Invitation width={18} />,
		// 	path: '',
		// },
		// {
		// 	id: 15,
		// 	title: strings.sidebar.settings,
		// 	icon: <Settings width={18} />,
		// 	iconSelected: <SettingsSelected width={18} />,
		// 	path: '/settings',
		// 	loggedin: true,
		// },
		{
			id: 16,
			title: strings.sidebar.q_trader,
			icon: <AreYouTrader width={18} />,
			path: '',
			link: 'https://coinson.co/traders/',
		},
		{
			id: 17, //sj => change logout id =17 to 18 and replace 17 here
			title: (
				<div className="flex items-center my-2 flex-row">
					{/* <button className="rtl:mr-2 ltr:ml-2 text-[1.45rem] font-medium" value="fa" onClick={handleChangeLocale}>
						{locale === 'fa' ? null : 'فارسی |'}
					</button> */}
					<button className="rtl:mr-2 ltr:ml-2 text-[1.45rem] font-medium" value="en" onClick={handleChangeLocale}>
						{locale === 'en' ? null : `English ${locale === 'ar' ? '' : ' |'}`}
					</button>
					<button className="rtl:mr-2 ltr:ml-2 text-[1.45rem] font-medium" value="ar" onClick={handleChangeLocale}>
						{locale === 'ar' ? null : 'العربیة'}
					</button>
				</div>
			),
			icon: <GlobeLang width={20} height={20} />,
			path: '',
			color: 'text-[#727D8F]',
		},
		{
			id: 18,
			title: strings.sidebar.logout,
			icon: locale === 'en' ? <Logout width={18} style={{ transform: 'scale(-1,1)' }} /> : <Logout width={18} />,
			path: '',
			color: '#C60F21',
			logout: true,
			loggedin: true,
		},

		// {
		// 	id: 100,
		// 	title: strings.sidebar.logout,
		// 	icon: locale === 'en' ? <Logout width={18} style={{ transform: 'scale(-1,1)' }} /> : <Logout width={18} />,
		// 	path: '',
		// 	color: '#C60F21',
		// 	logout: true,
		// 	loggedin: true,
		// },
	];

	const [state, setState] = useState({ left: false });

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ [anchor]: open });
	};

	const handleShowSidebar = (e) => {};

	const sideDrawerList = (anchor, menu, logOutHandler, isLoggedIn, locale, showSidebarNotifications, activeRoute) => (
		<div className="p-4" role="presentation" onKeyDown={toggleDrawer(anchor, false)} onClick={toggleDrawer(anchor, false)}>
			<div className="flex w-full justify-between items-center">
				<a target="_self" href={'//' + process.env.REACT_APP_HOME_URL} className="flex items-center pt-4 pr-4 pb-2">
					<img className="align-middle h-10" style={{ width: '15rem', height: '3.9rem' }} src={Daraya} alt="coinson" />
					{/* <span className="font-medium text-3xl rtl:mr-3 ltr:ml-3">{strings.sidebar.title}</span> */}
				</a>
				<div className="flex">
					{/* <div className="mx-4 h-16 w-16 flex justify-center item-center rounded-md hover:border-blue-300 bg-[#FAFBFB] rounded-xl">
						<button
							class="relative border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out"
							// onClick={(event) => showSidebarNotifications()}
							aria-label="message">
							<MessageIcon width={18} height={15} />
						</button>
					</div> */}

					<div className="h-16 w-16 flex justify-center item-center rounded-md hover:border-blue-300 bg-[#FAFBFB] rounded-xl">
						<NotificationIcon count={0} showSidebarNotifications={showSidebarNotifications} />
					</div>
					<button
						onClick={toggleDrawer(anchor, false)}
						onKeyDown={toggleDrawer(anchor, false)}
						className="ltr:ml-4 rtl:mr-4 h-16 w-16 flex justify-center items-center rounded-md hover:border-blue-300 rounded-xl">
						<CloseLightIcon width={15} height={15} />
					</button>
				</div>
			</div>
			<hr className="w-full mt-3 mb-1 bg-gray-100" />
			<nav className="py-2 m-0 relative list-none" aria-label="navigation">
				{menu.map((item, key) => {
					// console.log(item, 'support');
					if (item.id < 10)
						return (
							<MenuItemCustom
								activeRoute={activeRoute}
								locale={locale}
								logOutHandler={logOutHandler}
								isLoggedIn={isLoggedIn}
								key={key}
								item={item}
								handler={toggleDrawer(anchor, false)}
							/>
						);
				})}
				<hr className="w-full mt-3 mb-1 bg-gray-100" />
				{menu.map((item, key) => {
					if (item.id > 10)
						return (
							<MenuItemCustom
								activeRoute={activeRoute}
								locale={locale}
								logOutHandler={logOutHandler}
								isLoggedIn={isLoggedIn}
								key={key}
								item={item}
								handler={toggleDrawer(anchor, false)}
							/>
						);
				})}
			</nav>
		</div>
	);
	let location = useLocation();
	const activeRoute = (routeName) => {
		// console.log(location.pathname, 'location');
		return location.pathname.includes(routeName);
	};
	return (
		<React.Fragment>
			<button
				type="button"
				aria-label="menu"
				className="flex border-none cursor-pointer outline-none text-primary items-center justify-center"
				onClick={toggleDrawer('left', true)}>
				<Menu style={{ width: '3rem', height: '3rem' }} />
				{/* <a target="_self" href={'//' + process.env.REACT_APP_HOME_URL} className="flex items-center hidden md:flex">
					<img className="align-middle h-10" style={{ width: '15rem', height: '3.9rem' }} src={Daraya} alt="coinson" />
				</a>
				<a target="_self" href={'//' + process.env.REACT_APP_HOME_URL} className="flex items-center flex md:flexhidden">
					<img
						className="align-middle h-10"
						style={{ width: '2rem', height: '1.9rem' }}
						src={BrandCoinson}
						alt="coinson"
					/>
				</a> */}
			</button>
			<Drawer
				anchor="left"
				open={state.left}
				onClick={toggleDrawer('left', false)}
				onOpen={toggleDrawer('left', true)}
				onClose={toggleDrawer('left', false)}>
				{sideDrawerList('left', menu, logOutHandler, isLoggedIn, locale, showSidebarNotifications, activeRoute)}
			</Drawer>
		</React.Fragment>
	);
};

const MenuItemCustom = ({ item, subMenu = false, handler, logOutHandler, isLoggedIn, locale, activeRoute }) => {
	const Component = hasChildren(item) ? MultiLevel : SingleLevel;
	return (
		<Component
			activeRoute={activeRoute}
			locale={locale}
			item={item}
			subMenu={subMenu}
			handler={handler}
			logOutHandler={logOutHandler}
			isLoggedIn={isLoggedIn}
		/>
	);
};

const SingleLevel = ({ item, handler, subMenu = false, logOutHandler, isLoggedIn, locale, activeRoute }) => {
	const Component = item?.absolute ? CustomLink : Link;
	if (!!item?.loggedin && item.loggedin && !isLoggedIn) {
		return <></>;
	} else {
		if (item.link) {
			return (
				<a
					href={
						item.link
							? locale === 'ar'
								? new URL(item.arabic_link, baseOfAnotherHost).href
								: new URL(item.link, baseOfAnotherHost).href
							: item.path
					}
					// href={new URL('https://coinson.co/traders/', baseOfAnotherHost).href}
					rel="side_bar_link"
					target={item.link ? '_blank' : ''}
					key={item.title}
					onClick={(event) => (item.link ? null : item?.logout ? logOutHandler() : handler)}
					className="no-underline flex  mb-4 w-full p-2 px-8 hover:bg-gray-100">
					<div className="flex items-center my-2 flex-row">
						{item.path === '' ? item.icon : activeRoute(item.path.replace('/', '')) ? item.iconSelected : item.icon}
						<h3
							className={classnames(
								'text-3.5 m-0 px-5 my-2 transition-all duration-500 hover:text-menu-darkLight',
								subMenu ? 'font-normal' : 'font-medium',
								item?.color ? `text-[${item.color}]` : '',
								item.path === ''
									? ''
									: activeRoute(item.path.replace('/', ''))
									? 'text-primary font-semibold'
									: '',
							)}>
							{item.title}
						</h3>
					</div>
				</a>
			);
		} else {
			return (
				<Component
					to={item.link ? (locale === 'ar' ? { pathname: item.arabic_link } : { pathname: item.link }) : item.path}
					rel="side_bar_link"
					target={item.link ? '_blank' : ''}
					key={item.title}
					onClick={(event) => (item.link ? null : item?.logout ? logOutHandler() : handler)}
					className="no-underline flex  mb-4 w-full p-2 px-8 hover:bg-gray-100">
					<div className="flex items-center my-2 flex-row">
						{item.path === '' ? item.icon : activeRoute(item.path.replace('/', '')) ? item.iconSelected : item.icon}
						<h3
							className={classnames(
								'text-3.5 m-0 px-5 my-2 transition-all duration-500 hover:text-menu-darkLight',
								subMenu ? 'font-normal' : 'font-medium',
								item?.color ? `text-[${item.color}]` : '',
								item.path === ''
									? ''
									: activeRoute(item.path.replace('/', ''))
									? 'text-primary font-semibold'
									: '',
							)}>
							{item.title}
						</h3>
					</div>
				</Component>
			);
		}
	}
};

const MultiLevel = ({ item, handler }) => {
	const { items: children } = item;
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	return (
		<React.Fragment>
			<button
				className="p-2 px-8 mt-4 w-full flex relative box-border text-right items-center  flex-start no-underline cursor-pointer align-middle MuiListItem-button "
				onClick={handleClick}>
				<div className="flex-1 my-2">
					<h3 className="text-3.5 font-medium mx-0 my-2 transition-all duration-500 hover:text-menu-darkLight">
						{item.title}
					</h3>
				</div>
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</button>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div">
					{children.map((child, key) => (
						<MenuItemCustom key={key} item={child} subMenu={true} handler={handler} />
					))}
				</List>
			</Collapse>
		</React.Fragment>
	);
};

export default connect(null, { showSidebarNotifications })(withRouter(SideDrawer));
