import * as actionTypes from './actionTypes';

export const setCount = ({ count }) => ({
	type: actionTypes.GET_COUNT,
	payload: { count },
});

export const updateConfig = ({ user }) => ({
	type: actionTypes.UPDATE_CONFIG,
	payload: { user },
});
