export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const SET_ACCOUNT_DATA_REQUEST = 'SET_ACCOUNT_DATA_REQUEST';
export const SET_ACCOUNT_DATA_SUCCESS = 'SET_ACCOUNT_DATA_SUCCESS';
export const SET_ACCOUNT_DATA_FAILURE = 'SET_ACCOUNT_DATA_FAILURE';

export const GET_COUNT = 'GET_COUNT';

export const UPDATE_CONFIG = 'UPDATE_CONFIG';

export const GET_USER_RESERVATIONS_REQUEST = 'GET_USER_RESERVATIONS_REQUEST';
export const GET_USER_RESERVATIONS_SUCCESS = 'GET_USER_RESERVATIONS_SUCCESS';
export const GET_USER_RESERVATIONS_FAILURE = 'GET_USER_RESERVATIONS_FAILURE';

export const CHECK_REGISTERED_REQUEST = 'CHECK_REGISTERED_REQUEST';
export const CHECK_REGISTERED_SUCCESS = 'CHECK_REGISTERED_SUCCESS';
export const CHECK_REGISTERED_FAILURE = 'CHECK_REGISTERED_FAILURE';

export const CHECK_REGISTERED_REQUEST_EMAIL = 'CHECK_REGISTERED_REQUEST_EMAIL';
export const CHECK_REGISTERED_SUCCESS_EMAIL = 'CHECK_REGISTERED_SUCCESS_EMAIL';
export const CHECK_REGISTERED_FAILURE_EMAIL = 'CHECK_REGISTERED_FAILURE_EMAIL';

export const PHONE_AUTH_REQUEST = 'PHONE_AUTH_REQUEST';
export const PHONE_AUTH_SUCCESS = 'PHONE_AUTH_SUCCESS';
export const PHONE_AUTH_FAILURE = 'PHONE_AUTH_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const UPLOAD_PROFILE_PICTURE_REQUEST = 'UPLOAD_PROFILE_PICTURE_REQUEST';
export const UPLOAD_PROFILE_PICTURE_SUCCESS = 'UPLOAD_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_PROFILE_PICTURE_FAILURE = 'UPLOAD_PROFILE_PICTURE_FAILURE';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const RESET_AUTH = 'RESET_AUTH';

export const RESET_PHONE = 'RESET_PHONE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const CHANGE_MARK_REQUEST = 'CHANGE_MARK_REQUEST';
export const CHANGE_MARK_SUCCESS = 'CHANGE_MARK_SUCCESS';
export const CHANGE_MARK_FAILURE = 'CHANGE_MARK_FAILURE';

export const GET_EXPERT_LIST_REQUEST = 'GET_EXPERT_LIST_REQUEST';
export const GET_EXPERT_LIST_SUCCESS = 'GET_EXPERT_LIST_SUCCESS';
export const GET_EXPERT_LIST_FAILURE = 'GET_EXPERT_LIST_FAILURE';

export const GET_EXPERT_REQUEST = 'GET_EXPERT_REQUEST';
export const GET_EXPERT_SUCCESS = 'GET_EXPERT_SUCCESS';
export const GET_EXPERT_FAILURE = 'GET_EXPERT_FAILURE';

export const GET_MARKED_LIST_REQUEST = 'GET_MARKED_LIST_REQUEST';
export const GET_MARKED_LIST_SUCCESS = 'GET_MARKED_LIST_SUCCESS';
export const GET_MARKED_LIST_FAILURE = 'GET_MARKED_LIST_FAILURE';

export const GET_AVAILABLE_TIME_REQUEST = 'GET_AVAILABLE_TIME_REQUEST';
export const GET_AVAILABLE_TIME_SUCCESS = 'GET_AVAILABLE_TIME_SUCCESS';
export const GET_AVAILABLE_TIME_FAILURE = 'GET_AVAILABLE_TIME_FAILURE';

export const GET_AVAILABLE_HOUR_REQUEST = 'GET_AVAILABLE_HOUR_REQUEST';
export const GET_AVAILABLE_HOUR_SUCCESS = 'GET_AVAILABLE_HOUR_SUCCESS';
export const GET_AVAILABLE_HOUR_FAILURE = 'GET_AVAILABLE_HOUR_FAILURE';

export const GET_AVAILABLE_DURATION_REQUEST = 'GET_AVAILABLE_DURATION_REQUEST';
export const GET_AVAILABLE_DURATION_SUCCESS = 'GET_AVAILABLE_DURATION_SUCCESS';
export const GET_AVAILABLE_DURATION_FAILURE = 'GET_AVAILABLE_DURATION_FAILURE';

export const GET_AVAILABLE_PRICE_REQUEST = 'GET_AVAILABLE_PRICE_REQUEST';
export const GET_AVAILABLE_PRICE_SUCCESS = 'GET_AVAILABLE_PRICE_SUCCESS';
export const GET_AVAILABLE_PRICE_FAILURE = 'GET_AVAILABLE_PRICE_FAILURE';

export const GET_NOW_AVAILABLE_DURATIONS_REQUEST = 'GET_NOW_AVAILABLE_DURATIONS_REQUEST';
export const GET_NOW_AVAILABLE_DURATIONS_SUCCESS = 'GET_NOW_AVAILABLE_DURATIONS_SUCCESS';
export const GET_NOW_AVAILABLE_DURATIONS_FAILURE = 'GET_NOW_AVAILABLE_DURATIONS_FAILURE';

export const GET_NOW_AVAILABLE_PRICE_REQUEST = 'GET_NOW_AVAILABLE_PRICE_REQUEST';
export const GET_NOW_AVAILABLE_PRICE_SUCCESS = 'GET_NOW_AVAILABLE_PRICE_SUCCESS';
export const GET_NOW_AVAILABLE_PRICE_FAILURE = 'GET_NOW_AVAILABLE_PRICE_FAILURE';

export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';

export const GET_COMMENTS_DASHBOARD_REQUEST = 'GET_COMMENTS_DASHBOARD_REQUEST';
export const GET_COMMENTS_DASHBOARD_SUCCESS = 'GET_COMMENTS_DASHBOARD_SUCCESS';
export const GET_COMMENTS_DASHBOARD_FAILURE = 'GET_COMMENTS_DASHBOARD_FAILURE';

export const RESPONSE_TO_COMMENTS_REQUEST = 'RESPONSE_TO_COMMENTS_REQUEST';
export const RESPONSE_TO_COMMENTS_SUCCESS = 'RESPONSE_TO_COMMENTS_SUCCESS';
export const RESPONSE_TO_COMMENTS_FAILURE = 'RESPONSE_TO_COMMENTS_FAILURE';

export const ADD_WORK_SCHEDULE_REQUEST = 'ADD_WORK_SCHEDULE_REQUEST';
export const ADD_WORK_SCHEDULE_SUCCESS = 'ADD_WORK_SCHEDULE_SUCCESS';
export const ADD_WORK_SCHEDULE_FAILURE = 'ADD_WORK_SCHEDULE_FAILURE';

export const REMOVE_WORK_SCHEDULE_REQUEST = 'REMOVE_WORK_SCHEDULE_REQUEST';
export const REMOVE_WORK_SCHEDULE_SUCCESS = 'REMOVE_WORK_SCHEDULE_SUCCESS';
export const REMOVE_WORK_SCHEDULE_FAILURE = 'REMOVE_WORK_SCHEDULE_FAILURE';

export const GET_TODAY_RESERVATIONS_REQUEST = 'GET_TODAY_RESERVATIONS_REQUEST';
export const GET_TODAY_RESERVATIONS_SUCCESS = 'GET_TODAY_RESERVATIONS_SUCCESS';
export const GET_TODAY_RESERVATIONS_FAILURE = 'GET_TODAY_RESERVATIONS_FAILURE';

export const GET_CALL_LIST_REQUEST = 'GET_CALL_LIST_REQUEST';
export const GET_CALL_LIST_SUCCESS = 'GET_CALL_LIST_SUCCESS';
export const GET_CALL_LIST_FAILURE = 'GET_CALL_LIST_FAILURE';

export const EXPERT_RESERVE_REQUEST = 'EXPERT_RESERVE_REQUEST';
export const EXPERT_RESERVE_SUCCESS = 'EXPERT_RESERVE_SUCCESS';
export const EXPERT_RESERVE_FAILURE = 'EXPERT_RESERVE_FAILURE';

export const CANCEL_RESERVATION_REQUEST = 'CANCEL_RESERVATION_REQUEST';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const CANCEL_RESERVATION_FAILURE = 'CANCEL_RESERVATION_FAILURE';

export const SELF_RESERVATION_REQUEST = 'SELF_RESERVATION_REQUEST';
export const SELF_RESERVATION_SUCCESS = 'SELF_RESERVATION_SUCCESS';
export const SELF_RESERVATION_FAILURE = 'SELF_RESERVATION_FAILURE';

export const GET_NOTIFICATION_INBOX_REQUEST = 'GET_NOTIFICATION_INBOX_REQUEST';
export const GET_NOTIFICATION_INBOX_SUCCESS = 'GET_NOTIFICATION_INBOX_SUCCESS';
export const GET_NOTIFICATION_INBOX_FAILURE = 'GET_NOTIFICATION_INBOX_FAILURE';

export const EXPERT_DASHBOARD_REQUEST = 'EXPERT_DASHBOARD_REQUEST';
export const EXPERT_DASHBOARD_SUCCESS = 'EXPERT_DASHBOARD_SUCCESS';
export const EXPERT_DASHBOARD_FAILURE = 'EXPERT_DASHBOARD_FAILURE';

export const RESET_WORK_SCHEDULE_REQUEST = 'RESET_WORK_SCHEDULE_REQUEST';
export const RESET_WORK_SCHEDULE_SUCCESS = 'RESET_WORK_SCHEDULE_SUCCESS';
export const RESET_WORK_SCHEDULE_FAILURE = 'RESET_WORK_SCHEDULE_FAILURE';

export const GET_WORK_SCHEDULE_DISPLAY_REQUEST = 'GET_WORK_SCHEDULE_DISPLAY_REQUEST';
export const GET_WORK_SCHEDULE_DISPLAY_SUCCESS = 'GET_WORK_SCHEDULE_DISPLAY_SUCCESS';
export const GET_WORK_SCHEDULE_DISPLAY_FAILURE = 'GET_WORK_SCHEDULE_DISPLAY_FAILURE';

export const EXPERT_SETTINGS_REQUEST = 'EXPERT_SETTINGS_REQUEST';
export const EXPERT_SETTINGS_SUCCESS = 'EXPERT_SETTINGS_SUCCESS';
export const EXPERT_SETTINGS_FAILURE = 'EXPERT_SETTINGS_FAILURE';

export const EXPERT_NEW_SETTINGS_REQUEST = 'EXPERT_NEW_SETTINGS_REQUEST';
export const EXPERT_NEW_SETTINGS_SUCCESS = 'EXPERT_NEW_SETTINGS_SUCCESS';
export const EXPERT_NEW_SETTINGS_FAILURE = 'EXPERT_NEW_SETTINGS_FAILURE';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

export const GET_RESERVED_TIMES_LIST_REQUEST = 'GET_RESERVED_TIMES_LIST_REQUEST';
export const GET_RESERVED_TIMES_LIST_SUCCESS = 'GET_RESERVED_TIMES_LIST_SUCCESS';
export const GET_RESERVED_TIMES_LIST_FAILURE = 'GET_RESERVED_TIMES_LIST_FAILURE';

export const RESERVE_TIME_REQUEST = 'RESERVE_TIME_REQUEST';
export const RESERVE_TIME_SUCCESS = 'RESERVE_TIME_SUCCESS';
export const RESERVE_TIME_FAILURE = 'RESERVE_TIME_FAILURE';

export const SUBMIT_RATE_REQUEST = 'SUBMIT_RATE_REQUEST';
export const SUBMIT_RATE_SUCCESS = 'SUBMIT_RATE_SUCCESS';
export const SUBMIT_RATE_FAILURE = 'SUBMIT_RATE_FAILURE';

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const CHECK_COUPON_REQUEST = 'CHECK_COUPON_REQUEST';
export const CHECK_COUPON_SUCCESS = 'CHECK_COUPON_SUCCESS';
export const CHECK_COUPON_FAILURE = 'CHECK_COUPON_FAILURE';

export const P_REQUEST = 'P_REQUEST';
export const P_SUCCESS = 'P_SUCCESS';
export const P_FAILURE = 'P_FAILURE';

export const T_REQUEST = 'T_REQUEST';
export const T_SUCCESS = 'T_SUCCESS';
export const T_FAILURE = 'T_FAILURE';

export const GET_USER_MESSAGES_REQUEST = 'GET_USER_MESSAGES_REQUEST';
export const GET_USER_MESSAGES_SUCCESS = 'GET_USER_MESSAGES_SUCCESS';
export const GET_USER_MESSAGES_FAILURE = 'GET_USER_MESSAGES_FAILURE';

export const SEND_MESSAGES_REQUEST = 'SEND_MESSAGES_REQUEST';
export const SEND_MESSAGES_SUCCESS = 'SEND_MESSAGES_SUCCESS';
export const SEND_MESSAGES_FAILURE = 'SEND_MESSAGES_FAILURE';

export const GET_THREADS_REQUEST = 'GET_THREADS_REQUEST';
export const GET_THREADS_SUCCESS = 'GET_THREADS_SUCCESS';
export const GET_THREADS_FAILURE = 'GET_THREADS_FAILURE';

export const MESSAGE_REPLY_REQUEST = 'MESSAGE_REPLY_REQUEST';
export const MESSAGE_REPLY_SUCCESS = 'MESSAGE_REPLY_SUCCESS';
export const MESSAGE_REPLY_FAILURE = 'MESSAGE_REPLY_FAILURE';

export const RESERVATION_SEND_MESSAGES_REQUEST = 'RESERVATION_SEND_MESSAGES_REQUEST';
export const RESERVATION_SEND_MESSAGES_SUCCESS = 'RESERVATION_SEND_MESSAGES_SUCCESS';
export const RESERVATION_SEND_MESSAGES_FAILURE = 'RESERVATION_SEND_MESSAGES_FAILURE';

export const RESERVATIONS_REQUEST = 'RESERVATIONS_REQUEST';
export const RESERVATIONS_SUCCESS = 'RESERVATIONS_SUCCESS';
export const RESERVATIONS_FAILURE = 'RESERVATIONS_FAILURE';

export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';

export const GET_PUBLICATIONS_CONTENTS_REQUEST = 'GET_PUBLICATIONS_CONTENTS_REQUEST';
export const GET_PUBLICATIONS_CONTENTS_SUCCESS = 'GET_PUBLICATIONS_CONTENTS_SUCCESS';
export const GET_PUBLICATIONS_CONTENTS_FAILURE = 'GET_PUBLICATIONS_CONTENTS_FAILURE';

export const GET_MARKED_PUBLICATIONS_LIST_REQUEST = 'GET_MARKED_PUBLICATIONS_LIST_REQUEST';
export const GET_MARKED_PUBLICATIONS_LIST_SUCCESS = 'GET_MARKED_PUBLICATIONS_LIST_SUCCESS';
export const GET_MARKED_PUBLICATIONS_LIST_FAILURE = 'GET_MARKED_PUBLICATIONS_LIST_FAILURE';

export const CHANGE_MARK_CONTENTS_REQUEST = 'CHANGE_MARK_CONTENTS_REQUEST';
export const CHANGE_MARK_CONTENTS_SUCCESS = 'CHANGE_MARK_CONTENTS_SUCCESS';
export const CHANGE_MARK_CONTENTS_FAILURE = 'CHANGE_MARK_CONTENTS_FAILURE';

export const PUBLICATION_GET_LIST_REQUEST = 'PUBLICATION_GET_LIST_REQUEST';
export const PUBLICATION_GET_LIST_SUCCESS = 'PUBLICATION_GET_LIST_SUCCESS';
export const PUBLICATION_GET_LIST_FAILURE = 'PUBLICATION_GET_LIST_FAILURE';

export const DISPLAY_PUBLICATION_REQUEST = 'DISPLAY_PUBLICATION_REQUEST';
export const DISPLAY_PUBLICATION_SUCCESS = 'DISPLAY_PUBLICATION_SUCCESS';
export const DISPLAY_PUBLICATION_FAILURE = 'DISPLAY_PUBLICATION_FAILURE';

export const SUBMIT_TWEET_REQUEST = 'SUBMIT_TWEET_REQUEST';
export const SUBMIT_TWEET_SUCCESS = 'SUBMIT_TWEET_SUCCESS';
export const SUBMIT_TWEET_FAILURE = 'SUBMIT_TWEET_FAILURE';

export const DELETE_TWEET_REQUEST = 'DELETE_TWEET_REQUEST';
export const DELETE_TWEET_SUCCESS = 'DELETE_TWEET_SUCCESS';
export const DELETE_TWEET_FAILURE = 'DELETE_TWEET_FAILURE';

export const LIKE_TWEET_REQUEST = 'LIKE_TWEET_REQUEST';
export const LIKE_TWEET_SUCCESS = 'LIKE_TWEET_SUCCESS';
export const LIKE_TWEET_FAILURE = 'LIKE_TWEET_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const CHANGE_PHONE_NUMBER_REQUEST = 'CHANGE_PHONE_NUMBER_REQUEST';
export const CHANGE_PHONE_NUMBER_SUCCESS = 'CHANGE_PHONE_NUMBER_SUCCESS';
export const CHANGE_PHONE_NUMBER_FAILURE = 'CHANGE_PHONE_NUMBER_FAILURE';

export const TOTAL_INDICATOR_REQUEST = 'TOTAL_INDICATOR_REQUEST';
export const TOTAL_INDICATOR_SUCCESS = 'TOTAL_INDICATOR_SUCCESS';
export const TOTAL_INDICATOR_FAILURE = 'TOTAL_INDICATOR_FAILURE';

export const GET_INVOICES_DETAILS_REQUEST = 'GET_INVOICES_DETAILS_REQUEST';
export const GET_INVOICES_DETAILS_SUCCESS = 'GET_INVOICES_DETAILS_SUCCESS';
export const GET_INVOICES_DETAILS_FAILURE = 'GET_INVOICES_DETAILS_FAILURE';

export const ADD_WALLET_REQUEST = 'ADD_WALLET_REQUEST';
export const ADD_WALLET_SUCCESS = 'ADD_WALLET_SUCCESS';
export const ADD_WALLET_FAILURE = 'ADD_WALLET_FAILURE';

export const RESET_PAYMENT = 'RESET_PAYMENT';

export const COPY_TRADING_REQUEST = 'COPY_TRADING_REQUEST';
export const COPY_TRADING_SUCCESS = 'COPY_TRADING_SUCCESS';
export const COPY_TRADING_FAILURE = 'COPY_TRADING_FAILURE';

export const OPEN_AUTH_DIALOG = 'OPEN_AUTH_DIALOG';
export const CLOSE_AUTH_DIALOG = 'CLOSE_AUTH_DIALOG';

export const SHOW_SIDEBAR_NOTIFICATIONS = 'SHOW_SIDEBAR_NOTIFICATIONS';
export const CLOSE_SIDEBAR_NOTIFICATIONS = 'CLOSE_SIDEBAR_NOTIFICATIONS';

export const ADD_TAB_COURSE = 'ADD_TAB_COURSE';
