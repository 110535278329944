export const ROOT = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_DEV_URL;

export const REGISTER = ROOT.concat('auth/register/customer');
export const SET_ACCOUNT_DATA = ROOT.concat('account/user_settings');
export const CHECK_REGISTERED = ROOT.concat('auth/register/check');
export const PHONE_AUTH = ROOT.concat('auth/send_code');
export const LOGIN = ROOT.concat('auth/login');
export const LOGOUT = ROOT.concat('auth/logout');
export const UPLOAD_PROFILE_PICTURE = ROOT.concat('account/upload_profile_picture');
export const GET_PROFILE = ROOT.concat('account/get_profile');

export const CHANGE_MARK = ROOT.concat('customer/change_mark');
//change url
export const GET_EXPERT = (expertId) => {
	return ROOT.concat(`expert/${expertId}/profile`);
};

export const GET_CONFIG = ROOT.concat('account/configs');
export const GET_EXPERT_LIST = ROOT.concat('expert/get_list');
export const ADD_WORK_SCHEDULE = ROOT.concat('expert/add_work_schedule');
export const REMOVE_WORK_SCHEDULE = ROOT.concat('expert/remove_work_schedule');
export const GET_AVAILABLE_TIME = (expertId) => {
	return ROOT.concat(`expert/${expertId}/get_available_time`);
};
export const GET_TODAY_RESERVATIONS = (expertId, availableDate) => {
	return ROOT.concat(`expert/${expertId}/get_available_time?date=${availableDate}`);
};
export const GET_AVAILABLE_HOUR = (expertId, availableDate) => {
	return ROOT.concat(`expert/${expertId}/get_available_time?date=${availableDate}`);
};
export const GET_AVAILABLE_DURATION = (expertId, availableDate, availableHour) => {
	return ROOT.concat(`expert/${expertId}/get_available_time?date=${availableDate}&start_hour=${availableHour}`);
};
export const GET_AVAILABLE_PRICE = (expertId, availableDate, availableHour, availableDuration) => {
	return ROOT.concat(
		`expert/${expertId}/get_available_time?date=${availableDate}&start_hour=${availableHour}&duration=${availableDuration}`,
	);
};
export const GET_NOW_AVAILABLE_DURATIONS = (expertId) => {
	return ROOT.concat(`expert/${expertId}/get_now_available_durations`);
};
export const GET_NOW_AVAILABLE_PRICE = (expertId, duration) => {
	return ROOT.concat(`expert/${expertId}/get_now_available_durations?duration=${duration}`);
};
export const GET_COMMENTS = (expertId) => {
	return ROOT.concat(`expert/${expertId}/get_comments`);
};
export const GET_COMMENTS_DASHBOARD = ROOT.concat(`expert/get_comments`);
export const RESPONSE_TO_COMMENTS = (commentId) => {
	return ROOT.concat(`expert/comments/${commentId}/update`);
};
export const GET_MARKED_LIST = ROOT.concat('customer/get_marked_expert_list');
export const GET_CALL_LIST = ROOT.concat('call/get_list');
export const EXPERT_RESERVE = (expertId) => {
	return ROOT.concat(`expert/${expertId}/reserve`);
};
export const SELF_RESERVATION = ROOT.concat('expert/self_reservation');
export const CANCEL_RESERVATION = (reservationId) => {
	return ROOT.concat(`reservation/${reservationId}/cancel`);
};
export const GET_NOTIFICATION_INBOX = ROOT.concat('expert/get_notification_inbox');
export const SEND_MESSAGE = (expertId) => {
	return ROOT.concat(`expert/${expertId}/send_message`);
};
export const MESSAGE_REPLY = (messageId) => {
	return ROOT.concat(`messages/${messageId}/reply`);
};
export const GET_RESERVED_TIMES_LIST = ROOT.concat('reservation/get_reserved_times_list');
export const GET_USER_RESERVATIONS = ROOT.concat('account/get_user_reservations');
export const EXPERT_DASHBOARD = ROOT.concat('expert/dashboard');
export const EXPERT_SETTINGS = ROOT.concat('expert/settings');
export const RESET_WORK_SCHEDULE = ROOT.concat('expert/reset_work_schedule');
export const GET_WORK_SCHEDULE_DISPLAY = ROOT.concat('expert/get_work_schedule_display');

export const RESERVE_TIME = ROOT.concat('reservation/reserve_time');
export const SUBMIT_RATE = ROOT.concat('call/submit_rate');
export const GET_INVOICES = ROOT.concat('payment/invoices/getInvoicesList');
export const GET_INVOCE_DETAIL = ROOT.concat('payment/invoices');
export const CHECK_COUPON = ROOT.concat('payment/check_coupon');
export const GET_INVOICES_DETAILS = (invoiceId) => {
	return ROOT.concat(`payment/invoices/${invoiceId}/getInvoiceDetails`);
};
export const P = ROOT.concat('payment/p/{slug}/');
export const T = ROOT.concat('payment/t/{slug}/{gateway}/');
export const ADD_WALLET = ROOT.concat('payment/addwallet');
export const GET_USER_MESSAGES = ROOT.concat('messages/get_user_messages');
export const SEND_MESSAGES = ROOT.concat('messages/send_message');
export const RESERVATION_SEND_MESSAGES = (reservationId) => {
	return ROOT.concat(`reservation/${reservationId}/send_message`);
};
export const GET_THREADS = ROOT.concat('messages/get_threads');
export const RESERVATIONS = ROOT.concat('reservation/reservations');
export const GET_PUBLICATIONS_CONTENTS = (expertId) => {
	return ROOT.concat(`expert/${expertId}/get_publications`);
};
export const GET_MARKED_PUBLICATIONS_LIST = ROOT.concat(`customer/get_marked_publications_list`);
export const CHANGE_MARK_CONTENTS = (courseId) => {
	return ROOT.concat(`publication/content/${courseId}/change_mark`);
};
export const PUBLICATION_GET_LIST = ROOT.concat('publication/get_list?academy=true');
export const DISPLAY_PUBLICATION = (publication_type, publication_id) => {
	return ROOT.concat(`publication/${publication_type}/${publication_id}/display`);
};
export const SUBMIT_TWEET = ROOT.concat('tweet/submit');
export const DELETE_TWEET = (tweetId) => {
	return ROOT.concat(`tweet/${tweetId}/delete`);
};
export const LIKE_TWEET = (tweetId) => {
	return ROOT.concat(`tweet/${tweetId}/like`);
};
export const CHANGE_PASSWORD = ROOT.concat('auth/change_password');
export const CHANGE_PHONE_NUMBER = ROOT.concat('account/change_phone_number');

export const COURSE_URL = (id) => ROOT.concat(`period/stage/${id}`);
