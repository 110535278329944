import { useState } from 'react';
import { connect } from 'react-redux';

import { tLocalNum } from '../../utils/translateNumber';

import { Row, Input } from './Styles';

const PhoneInput = ({ errors, onChange = () => { }, ...props }) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Row>
      <Input
        errors={
          errors &&
          errors.length > 0 &&
          errors[0].message.includes('phone_number') &&
          true
        }
        required
        maxLength="11"
        value={tLocalNum(value)}
        onChange={handleChange}
        {...props}
      />
      {errors &&
        errors.length > 0 &&
        errors[0].message.includes('phone_number') && (
          <span>{errors[0].message.split(' ').slice(1).join(' ')}</span>
        )}
    </Row>
  );
};

const mapStateToProps = ({ notifications }) => ({
  errors: notifications.notifications.errors,
});

export default connect(mapStateToProps)(PhoneInput);
