import API from '../base';

export const getNotifications = () => {
	const path = `messages/site_notification`;
	return API.GetMethod({ path });
};

export const postReadNotification = ({ data, ...props }) => {
	const path = `messages/site_notification`;
	return API.PostMethod({ path, data, ...props });
};

export const postReadAllNotifications = () => {
	const path = `messages/site_notification/mark_as_read_all`;
	return API.PostMethod({ path });
};

export const getRichNotification = () => {
	const path = `messages/site_notification?rich`;
	return API.GetMethod({ path });
};
