import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import strings from 'translations/translations';

import { phoneAuth } from '../../../../redux/actions/account';
import CustomButton from '../../../CustomButton';

function SendCodeBtn({ phoneAuth, auth_data }) {
	const [remainingTime, setRemainingTime] = useState(60);
	const resendCode = () => {
		phoneAuth({ phone_number: auth_data.phone_number });
		setRemainingTime(60);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setRemainingTime(remainingTime - 1);
		}, 1000);
		return () => clearTimeout(timer);
	});

	return (
		<>
			{remainingTime > 0 ? (
				<Typography component="h5" variant="h6" align="left" style={{ color: '#727D8F', opacity: '0.8' }}>
					{strings.resend_code_auth}
					{` (${Math.floor(remainingTime / 60)}:${Math.floor((remainingTime % 60) / 10)}${remainingTime % 10})`}
				</Typography>
			) : (
				<div>
					<CustomButton type="button" style={{ color: '#201C68', fontWeight: 500 }} onClick={resendCode}>
						{strings.resend_code_auth}
					</CustomButton>
				</div>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	auth_data: state.account.auth_data,
});

export default connect(mapStateToProps, { phoneAuth })(SendCodeBtn);
