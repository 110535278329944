import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { showSidebarNotifications } from 'redux/actions/notifications';
import { connect } from 'react-redux';
import { tLocalNum } from 'utils/translateNumber';
import { CustomizedBadges } from 'components/Badge/CustomizedBadges';
import strings from 'translations/translations';
const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
		borderRadius: '0.5rem !important',
		minWidth: '31rem',
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const NotificationItem = ({ data }) => {
	return (
		<div className='px-4 border-b border-gray-200'>
			<div className='text-blue-500 text-right py-4 mb-4 text-lg font-medium'>
				ایپسوم لورم ایپسوم لورم ایپسوملورم ایپسوم لورم
			</div>
			<div className='flex justify-between text-400 text-base font-normal'>
				<div className='text-gray-400'>
					۱۴۰۰/۱۰/۱۹
				</div>
				<div className='text-gray-600 font-medium'>
					۱۲:۱۰
				</div>
			</div>
		</div>
	)
}

const NotificationHeader = ({ showSidebarNotifications, isLoggined }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);


	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			{isLoggined && (
				<button
					type="button"
					aria-label="menu"
					className='flex border-none cursor-pointer outline-none text-gray-500 hover:bg-gray-50 rounded-full hover:text-opacity-75 p-2 hover:duration-300'
					onClick={handleClick}>
					<CustomizedBadges
						badgeContent={tLocalNum(3)}
						color="primary"
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						children={
							<NotificationsIcon style={{ width: '2rem', height: '2rem' }} />
						}
					/>
				</button>
			)}
			<StyledMenu
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<NotificationItem />
				<NotificationItem />
				<NotificationItem />
				<button
					onClick={() =>
						showSidebarNotifications()
					}
					className='text-btn-primary  text-lg font-normal py-5 w-full'>
					{strings.see_all}
				</button>
			</StyledMenu>
		</div>
	);

}
const mapStateToProps = (state) => ({
	isLoggined: state.account.token,
});


export default connect(mapStateToProps, { showSidebarNotifications })(NotificationHeader);
