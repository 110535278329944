import * as actionTypes from '../actions/actionTypes';

const initState = {
  expertMarkedList: [],
  contentMarkedList: [],
};

function customer(state = initState, action) {
  switch (action.type) {
    case actionTypes.GET_MARKED_LIST_SUCCESS:
      return {
        ...state,
        expertMarkedList: action.response,
      };
    case actionTypes.CHANGE_MARK_SUCCESS:
      return {
        ...state,
        expertMarkedList: state.expertMarkedList.filter(
          (marked) => marked.id !== action.payload.expert
        ),
      };
    case actionTypes.GET_MARKED_PUBLICATIONS_LIST_SUCCESS:
      return {
        ...state,
        contentMarkedList: action.response,
      };
    case actionTypes.CHANGE_MARK_CONTENTS_SUCCESS:
      return {
        ...state,
        contentMarkedList: state.contentMarkedList.filter(
          (marked) => marked.id !== action.payload.courseId
        ),
      };
    default:
      return state;
  }
}

export default customer;
