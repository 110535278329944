import * as actionTypes from '../actions/actionTypes';

const initState = {
  calls: [],
};
function call(state = initState, action) {
  switch (action.type) {
    case actionTypes.GET_CALL_LIST_SUCCESS:
      return {
        ...state,
        calls: action.response,
      };
    case actionTypes.SUBMIT_RATE_SUCCESS:
      const index = state.calls.findIndex(
        (e) => +e.id === +action.response.new_rate.id
      );

      if (index === -1) {
        return {
          ...state,
          calls: [...state.calls, action.response.new_rate],
        };
      }
      state.calls[index] = action.response.new_rate;
      return {
        ...state,
        calls: state.calls,
      };
    default:
      return state;
  }
}

export default call;
