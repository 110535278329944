import { IntlReducer as Intl } from 'react-redux-multilingual';
import { combineReducers } from 'redux';

import account from './account';
import call from './call';
import customer from './customer';
import expert from './expert';
import message from './message';
import notifications from './notifications';
import payment from './payment';
import reservation from './reservation';
import copyTrading from './copyTrading';
import config from './config';

const allReducers = combineReducers({
	account,
	notifications,
	config,
	expert,
	call,
	reservation,
	payment,
	Intl,
	customer,
	message,
	copyTrading,
});
export default allReducers;
