import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as User } from 'assets/icon/user-black.svg';
import strings from 'translations/translations';

const NotLoggedInAccountButton = () => {
	const [loginDialogOpen, setLoginDialogOpen] = useState(false);
	const history = useHistory();

	return (
		<>
			<button
				className="rounded-md w-44 h-16 flex items-center justify-center p-4 hover:border-blue-300 border-1 border-slate-300"
				onClick={() => history.push('/auth')}>
				<span className="text-primary">{strings.enter_user}</span>
				<User className="rtl:mr-4 ltr:ml-4" />
			</button>
		</>
	);
};

export default NotLoggedInAccountButton;
