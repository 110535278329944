import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getExpert } from 'redux/actions/expert';
import { resetAuth } from 'redux/actions/account';
import ReservePageHeader from 'components/SpecialComponents/ReservePage/ReservePageHeader';
import ReserveHours from 'components/ReserveTimes/ReserveHours';
import ReserveCalender from 'components/ReserveTimes/ReserveCalender';
import classnames from 'classnames';
import { tLocalNum } from 'utils/translateNumber';
import { CircularProgress } from '@material-ui/core';
import { getCustomFormatDayJalali, getSomeFirstTimes, plusHour } from 'utils/timeFunctions';
import strings from 'translations/translations';
import momentJalaali from 'moment-jalaali';
import ReserveTimes from 'components/ReserveTimes/ReserveTimes';
import { getAvailableTimes } from 'api/reserve/reserveExpert';
import { Logger, redirectPaymentPage } from 'utils/functions';
import { postExpertReserve, getExpertWithId } from 'api/expert/experts';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';

import { makeStyles, Typography } from '@material-ui/core';

import ProfilePicture from 'components/User/ProfilePicture';
import CustomButton from 'components/CustomButton';
import { ReactComponent as Star } from '../assets/icon/star7.svg';
import { ReactComponent as Users } from '../assets/icon/usersCard.svg';
import { ReactComponent as Return } from '../assets/icon/time-past.svg';
import { ReactComponent as Wallet } from '../assets/icon/walletCard.svg';
import { ReactComponent as TachometerFast } from 'assets/icon/tachometer-fast.svg';

//me
import constants from 'configs/constants';

const useStyles = makeStyles((theme) => ({
	expertFullName: {
		marginRight: 5,
		fontSize: 20,
		color: theme.palette.primary.main,
	},
	expertPictureContainer: {
		padding: 0,
		borderRadius: 60,
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			display: 'table',
		},
	},
}));

const consultation_time = [
	{
		id: 1,
		title: strings.short,
		subTitle: strings.formatString(strings.time.the_minute, { minute: tLocalNum(15) }),
		value: 4,
	},
	{
		id: 2,
		title: strings.intermediate,
		subTitle: strings.formatString(strings.time.the_minute, { minute: tLocalNum(30) }),
		value: 2,
	},
	{
		id: 3,
		title: strings.long,
		subTitle: strings.formatString(strings.time.the_minute, { minute: tLocalNum(60) }),
		value: 1,
	},
];

const vecation = ['1401/9/7', '1411/9/14', '1411/9/21', '1411/9/28'];

const ReservePageNew = ({ match, resetAuth, roleOfUser }) => {
	const classes = useStyles();

	const [consultationTime, setConsultationTime] = useState();
	const [dataReserve, setDataReserve] = useState([]);
	const [expert, setExpert] = useState();
	const [loading, setLoading] = useState(true);
	const [loadingConsultationTime, setloadingConsultationTime] = useState(false);
	const [dayRezerve, setDayRezerve] = useState();
	const [easyWay, setEasyWay] = useState(true);
	const [easyWayId, setEasyWayId] = useState();
	const [hoursReserve, setHoursReserve] = useState({ start: '', end: '' });
	const [avaliableTimes, setAvaliableTimes] = useState([]);
	const [hassleFreeTimes, setHassleFreeTimes] = useState();
	const [highlightRanges, setHighlightRanges] = useState();
	const [price, setPrice] = useState(0);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const handleGetExpert = async (expert_id) => {
		try {
			const result = await getExpertWithId({ expert_id: expert_id });
			if (result.status / 100 < 3) {
				setExpert(result.data);
				setLoading(false);
			}
		} catch (e) {
			Logger(e, 'getExpertWithId');
		}
	};

	const handleGetAvailableTimes = async (duration) => {
		setloadingConsultationTime(true);
		try {
			const result = await getAvailableTimes({ expert_id: expert.id, duration: duration });
			if (result.status / 100 < 3) {
				setPrice(result.data.price);
				setAvaliableTimes(result.data.available_times);
				setHassleFreeTimes(getSomeFirstTimes(result.data.available_times, 3));
				setHighlightRanges(Object.keys(result.data.available_times));
				setloadingConsultationTime(false);
			}
		} catch (e) {
			Logger(e, 'getAvailableTimes');
		}
	};

	const handleReserveExpert = async (duration) => {
		setLoading(true);
		try {
			const result = await postExpertReserve({
				expert_id: expert.id,
				data: {
					duration: duration,
					date: getCustomFormatDayJalali(momentJalaali(dayRezerve, 'jYYYY/jM/jD'), true, 'YYYY-MM-DD'),
					start_hour: hoursReserve.start,
				},
			});
			if (result.status / 100 < 3) {
				handleClickVariant('success');
				redirectPaymentPage(result.data.new_reservation.invoice.id, history);
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
			Logger(e, 'postExpertReserve');
		}
	};

	useEffect(() => {
		if (!roleOfUser) {
			resetAuth();
		}
		if (consultationTime) {
			handleGetAvailableTimes(60 / consultationTime);
		}
		handleGetExpert(match.params.id);
	}, [consultationTime, match.params.id, roleOfUser, resetAuth]);

	useEffect(() => {
		let avaliableTimes_tmp = avaliableTimes;
		let mockData = [];
		avaliableTimes_tmp[dayRezerve]?.map((item, index) => {
			mockData.push({
				id: index,
				time: item.from.split(':')[0] + ':' + item.from.split(':')[1],
				free: true,
			});
		});
		setDataReserve(mockData);
	}, [avaliableTimes, dayRezerve]);

	const handleSelectClock = (button_id, value) => {
		let end_consultation;
		let start;
		if (button_id === 'easy_way') {
			setEasyWayId(value.id);
			setDayRezerve(value.day);
			start = value.value;
			end_consultation = plusHour(value.value, 60 / consultationTime);
		} else {
			start = value;
			end_consultation = plusHour(value, 60 / consultationTime);
		}

		setHoursReserve((prv) => {
			return {
				start: start,
				end: end_consultation,
			};
		});
	};

	const handleSelectDay = (day) => {
		setDayRezerve(day);
	};

	const changeWay = () => {
		setEasyWay((prv) => !prv);
		setDayRezerve(getCustomFormatDayJalali(momentJalaali()));
		setHoursReserve({ start: '', end: '' });
		setEasyWayId();
	};
	const handleSubmitReserve = () => {
		if (hoursReserve.start.length) {
			handleReserveExpert(60 / consultationTime);
		}
	};

	const handleClickVariant = (variant) => {
		let message = strings.registerMessage.success_reserve;
		enqueueSnackbar(message, {
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'right',
			},
			TransitionComponent: Fade,
			variant,
		});
	};

	return (
		<>
			<div className="justify-center container-custom bg-reservePage-background">
				<div className="bg-white mb-0 mt-32 rounded-none items-center flex-start w-full sm:w-5/6 md:w-4/5 md:mb-20 md:rounded-2xl">
					{expert && (
						<div className="mx-6 md:mx-10">
							<div className="flex flex-col md:flex-row">
								<div className="basis-1/2 my-8 flex flex-row">
									<Link to={`/experts/${expert.id}`}>
										<ProfilePicture
											className="w-32 h-32 basis-1/3"
											radius={0.4}
											background="#fff"
											size={8}
											status={expert.status}
											img={expert.user.image && expert.user.image}
											name={expert?.user.full_name}
										/>
									</Link>
									<div className="m-6 flex flex-col basis-2/3">
										<Link to={`/experts/${expert.id}`}>
											<CustomButton>
												<Typography
													component="h3"
													variant="h4"
													className={classes.expertFullName}>
													{expert.user.full_name}
												</Typography>
											</CustomButton>
										</Link>
										<div className="flex flex-row mt-10 w-full">
											<button
												className={`py-2 rounded-lg text-center font-normal text-lg bg-lightBlue text-primary w-full`}>
												{strings.crypto_exchange.kukoin_future}
											</button>
											<button
												className={`mr-2 py-2 rounded-lg text-center font-normal text-lg bg-lightBlue text-primary w-full`}>
												{strings.crypto_exchange.kukoin_spot}
											</button>
											<div className="mr-2 p-2 bg-lightGreen rounded-lg flex justify-center items-center">
												<span className="text-darkGreen font-medium text-lg rtl:ml-2 rtl:-mb-1 ltr:mr-2 ltr:-mb-1">
													{expert?.score.toFixed(1)}
												</span>
												<Star className="w-5 h-5" />
											</div>
										</div>
									</div>
								</div>
								<div className="flex flex-row my-8 w-full basis-1/2">
									<div className="w-full mb-2 p-4 rtl:mr-2 ltr:ml-2 rounded-lg flex justify-center items-center flex-col bg-lightBlue">
										<Wallet
											width={17}
											height={17}
											className="ltr:hidden"
											style={{ marginTop: '.5rem' }}
										/>
										<span className="text-primary text-xlg mt-2 rtl:font-medium ltr:font-normal">
											$
											<span className="rtl:!font-sans rtl:font-medium ltr:font-normal">
												{expert?.total_aum.toFixed(2)}
											</span>
										</span>
										<span className="text-primary text-xlg font-normal text-center">
											{strings.courseCard.aum}
										</span>
									</div>

									<div className="w-full mb-2 rtl:mr-2 ltr:ml-2 rounded-lg flex justify-center items-center flex-col bg-lightGreen">
										<Return width={17} height={17} style={{ marginTop: '.5rem' }} />
										<span className="text-darkGreen text-xlg mt-2 rtl:font-medium ltr:font-normal flex flex-row-reverse">
											<div className="rtl:font-medium ltr:font-normal rtl:dir-ltr">
												{expert?.monthly_profit}
											</div>
											<span>%</span>
										</span>
										<span className="text-darkGreen text-xlg font-normal">
											{strings.courseCard.return_of_investment.amonth}
										</span>
									</div>
								</div>
							</div>
							<hr />
							<div className="my-6 py-5 pr-5 pl-8 rounded-lg text-primary text-xl leading-loose border border-payment">
								سوف يتم موعد الاستشارة الخاص بك في المنصة دارايا المخصصة عبر اتصال مرئي على الهواء مباشرة مع
								توفير إمكانية مبادلة الملفات ومشاركة الشاشة. فقد يمكنك على سبيل المثال مشاركة الرسوم
								البيانية والأخبار وما إلى ذلك مع المستشار الخاص بك. بعد الحجز وفي الساعة المحددة، سوف يتم
								إرسال رابط غرفة الاستشارة إلى رقم هاتفك المحمول. أيضا يمكنك دخول غرفة الاستشارة عن طريق
								الدخول في حسابك على دارايا.
							</div>
							<div>
								<div className="py-4">
									<h3 className="text-[20px]">{strings.reservePage.consultationـtime}</h3>

									<div className="py-4">
										<div className="container-custom mt-2 grid gap-y-2 gap-x-2 grid-cols-1 md:grid-cols-3">
											{consultation_time.map((groupingItem) => (
												<div
													key={groupingItem.id}
													className="container-custom box-border justify-center mt-4 rounded-lg">
													<button
														onClick={() => setConsultationTime(groupingItem.value)}
														className={classnames(
															'groupingItem bg-lightBlue bg-opacity-90 focus:bg-primary focus:text-white flex flex-col justify-center items-center rounded-lg w-full p-4 mb-0 md:m-0',
															consultationTime === groupingItem.value &&
																'expertsPage-groupingItem-focus text-blue-400',
														)}>
														<span className="m-auto text-2xl font-bold">
															{groupingItem.title}
														</span>
														<span className="m-auto text-normal font-normal mt-3">
															{groupingItem.subTitle}
														</span>
													</button>
												</div>
											))}
										</div>
									</div>
								</div>
								{consultationTime && (
									<div>
										<h3 className="text-[20px] my-4">{strings.reservePage.date_consultation}</h3>
										<div className="">
											{consultationTime && easyWay && (
												<div>
													{!hassleFreeTimes ? (
														<CircularProgress
															size={30}
															style={{ margin: 'auto' }}
														/>
													) : (
														<div className="container-custom mt-2 grid gap-y-2 gap-x-2 grid-cols-1 md:grid-cols-3">
															{hassleFreeTimes?.map((day) => (
																<div
																	key={day.id}
																	className="container-custom justify-center m-0 mt-4 rounded-lg">
																	<button
																		onClick={() =>
																			handleSelectClock(
																				'easy_way',
																				{
																					id: day.id,
																					day: day.day,
																					value: day.time,
																				},
																			)
																		}
																		className={classnames(
																			'groupingItem bg-lightBlue bg-opacity-90 focus:bg-primary focus:text-white flex justify-center items-center rounded-lg w-full p-8 mb-0 md:m-0',
																			easyWayId === day.id &&
																				'expertsPage-groupingItem-focus text-blue-400',
																		)}>
																		<span className="text-2xl font-medium mx-2">
																			{tLocalNum(day.label)}
																		</span>
																		{' , '}
																		<span className="text-2xl font-medium mx-2">
																			{strings.formatString(
																				strings.time.hour,
																				{
																					value: tLocalNum(
																						day.time,
																					),
																				},
																			)}
																		</span>
																	</button>
																</div>
															))}
															<button
																className="text-primary font-normal mt-4 text-right"
																onClick={changeWay}>
																{strings.reservePage.choose_custom_date}
															</button>
														</div>
													)}
												</div>
											)}
											{consultationTime && !easyWay && (
												<div>
													{highlightRanges ? (
														<div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
															<div className="rounded-lg mt-6">
																<ReserveCalender
																	consultationDays={highlightRanges}
																	closeDays={vecation ? vecation : []}
																	handlerSelect={(day) => {
																		handleSelectDay(day);
																	}}
																/>
															</div>
															<div className="rounded-lg mt-6">
																<ReserveHours
																	data={dataReserve}
																	loading={loadingConsultationTime}
																	handlerId={(id, value) => {
																		handleSelectClock(
																			'custom',
																			value,
																		);
																	}}
																/>
															</div>
														</div>
													) : (
														<div className=""></div>
													)}
													<button
														className="text-primary font-normal mt-4 text-right"
														onClick={changeWay}>
														{strings.reservePage.return_quick_choice}
													</button>
												</div>
											)}
										</div>
									</div>
								)}

								{hoursReserve.start.length > 0 && (
									<div className=" mt-5 font-medium">
										<div className="p-6 w-full flex justify-center bg-reservePage-resultReserve text-reservePage-resultReserve rounded-lg">
											{strings.formatString(
												strings.reservePage.resultReserve,
												<span className="font-semibold mx-3">
													{dayRezerve ? tLocalNum(dayRezerve) : ''}
												</span>,
												<span className="mx-3 font-bold">
													{strings.formatString(strings.reservePage.time_reserve, {
														from: tLocalNum(hoursReserve.start),
														to: tLocalNum(hoursReserve.end),
													})}
												</span>,
											)}
										</div>

										<div className="my-2 p-6 w-full flex flex-row justify-center bg-reservePage-resultReserve text-reservePage-resultReserve rounded-lg">
											<span className="mt-2">{strings.pay.payable}</span>

											<span
												className={classnames(
													'text-xl mr-2 mt-2',
													loading || !hoursReserve.start.length ? 'hidden' : '',
												)}>
												{strings.formatString(strings.currency.toman, {
													amount: tLocalNum(price),
												})}{' '}
											</span>
										</div>
									</div>
								)}

								<div className="py-4">
									<h3 className="text-[20px] my-4">{strings.reservePage.response_hours}</h3>
								</div>
								<ReserveTimes schedule={expert.schedule} />

								<div className="py-4">
									<div className=" w-full">
										<button
											className={classnames(
												'py-4 w-full flex justify-center items-center cursor-pointer  rounded-xl',
												hoursReserve.start.length
													? 'bg-blue-600 text-white class-hover-opacity'
													: 'bg-gray-200 text-gray-600',
											)}
											disabled={!consultationTime}
											onClick={handleSubmitReserve}>
											<span
												className={classnames(
													'text-3xl text-bold',
													loading ? 'hidden' : '',
												)}>
												{/* {strings.reserve} */}
												{strings.pay.payment_reserve}
											</span>
											<div className={classnames('my-1', loading ? '' : 'hidden')}>
												<CircularProgress color="white" size={30} />
											</div>
										</button>
										<div className="flex justify-center text-primary text-lg mt-4 cursor-pointer hover:opacity-80">
											<Link to={`/experts/${expert.id}`}>{strings.return}</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	expert: state.expert.experts.find((expert) => +expert.id === +ownProps.match.params.id),
	canReserveNow: state.reservation.availableNow.can_reserve_now,
	roleOfUser: state.account.user.role,
});

export default connect(mapStateToProps, { getExpert, resetAuth })(ReservePageNew);
