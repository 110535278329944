import './assets/styles/App.scss';
import './assets/styles/tailwind.scss';

import { SnackbarProvider } from 'notistack';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-redux-multilingual';
import ReactGA from 'react-ga';
import Root from './root/Root.jsx';
import { IntlActions } from 'react-redux-multilingual';
import { BrowserRouter, useLocation, useHistory } from 'react-router-dom';
import MuiTheme from './theme/MuiTheme';
import { Route, Switch } from 'react-router-dom';
import RTLMuiTheme from './theme/RTLMuiTheme';
import translations from './translations';
import jss from './utils/jssRTL';
import Errors from './components/Notifications/Errors';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { requestFirebaseNotificationPermission } from './firebase/firebaseInit';
import { initializeStore } from 'redux/store/configureStore.dev';
import { postFcmRegisterDevice } from 'api/notification/fcmRegisterDevice';
import { getConfig as getCounter } from 'api/config';
import { Logger } from 'utils/functions';
import { setCount, updateConfig } from './redux/actions/config';
import SwipeableTemporaryDrawer from './components/Notifications/NotificationsSideBar';
import strings from 'translations/translations';

const enStyle = `
body,
body.iransans *,
body.iransans .ui.form input:not([type]),
body.iransans .ui.form input[type='number'],
body.iransans .ui.form input,
body.iransans .ui.text,
body.iransans *,
body.iransans,
body.iransans .ui.modal *,
body.iransans .ui,
body.iransans .ui *,
body.iransans .ui.input > input,
body.iransans .ui.card *,
body.iransans .ui.card .content .header,
body.iransans .ui .item *,
body.iransans .ui .item .content .header {
	font-family: Poppins;
}
* {
	font-family: Poppins;
}

  body {
	font-family: Poppins;
  }
  font-family: Poppins;
`;

const faStyle = `
body,
body.iransans *,
body.iransans .ui.form input:not([type]),
body.iransans .ui.form input[type='number'],
body.iransans .ui.form input,
body.iransans .ui.text,
body.iransans *,
body.iransans,
body.iransans .ui.modal *,
body.iransans .ui,
body.iransans .ui *,
body.iransans .ui.input > input,
body.iransans .ui.card *,
body.iransans .ui.card .content .header,
body.iransans .ui .item *,
body.iransans .ui .item .content .header {
	font-family: IRANSans;
}
* {
	font-family: IRANSans;
}

  body {
	font-family: IRANSans;
  }
  font-family: IRANSans;
`;

const arStyle = `
body,
body.iransans *,
body.iransans .ui.form input:not([type]),
body.iransans .ui.form input[type='number'],
body.iransans .ui.form input,
body.iransans .ui.text,
body.iransans *,
body.iransans,
body.iransans .ui.modal *,
body.iransans .ui,
body.iransans .ui *,
body.iransans .ui.input > input,
body.iransans .ui.card *,
body.iransans .ui.card .content .header,
body.iransans .ui .item *,
body.iransans .ui .item .content .header {
	font-family: LamaSans;
}
* {
	font-family: LamaSans;
}

  body {
	font-family: LamaSans;
  }
  font-family: LamaSans;
`;
const AppRout = ({ locale }) => (
	<SnackbarProvider max={3}>
		<Errors />
		<BrowserRouter forceRefresh={false} basename={`/${locale}`} key={`/${locale}`}>
			<Root />
		</BrowserRouter>
		<SwipeableTemporaryDrawer />
	</SnackbarProvider>
);
const languages_Enum = ['en', 'ar', 'fa'];

const App = ({ dir, locale, isLoggedIn, setCount, updateConfig }) => {
	const handleRegisterDevice = async (firebaseToken) => {
		try {
			const result = await postFcmRegisterDevice({
				data: {
					registration_id: firebaseToken,
					device_id: uuidv4(),
					type: 'web',
				},
			});
			if (result.status / 100 < 3) {
			}
		} catch (e) {
			Logger(e, 'handleRegisterDevice');
		}
	};

	document.body.dir = dir;

	var elems = document.body.getElementsByTagName('*');

	const location = useLocation();
	let history = useHistory();
	const style = document.createElement('style');

	if (locale === 'en') {
		style.textContent = enStyle;
		document.head.appendChild(style);
	} else if (locale === 'fa') {
		style.textContent = faStyle;
		document.head.appendChild(style);
	} else {
		style.textContent = arStyle;
		document.head.appendChild(style);
	}

	const hadleConfig = async () => {
		const result = await getCounter();
		setCount({ count: result.data.configs.notification.count });
		updateConfig({ user: result.data });
	};

	useEffect(() => {
		if (isLoggedIn) {
			hadleConfig();
		}
	}, [isLoggedIn]);

	useEffect(() => {
		const saved = localStorage.getItem('ftoken');
		const initialValue = JSON.parse(saved);
		if (!initialValue) {
			requestFirebaseNotificationPermission()
				.then((firebaseToken) => {
					// eslint-disable-next-line no-console
					localStorage.setItem('ftoken', JSON.stringify(firebaseToken));
					handleRegisterDevice(firebaseToken);
				})
				.catch((err) => {
					return err;
				});
		}
	}, []);

	if (location.pathname.split('/')[1] !== locale) {
		if (languages_Enum.includes(location.pathname.split('/')[1])) {
			strings.setLanguage(location.pathname.split('/')[1]);
			const store = initializeStore();

			store.dispatch(IntlActions.setLocale(location.pathname.split('/')[1]));
			strings.setLanguage(location.pathname.split('/')[1]);
		} else {
			history.push({ pathname: '/en' + location.pathname });
		}
	}
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	return (
		<IntlProvider translations={translations}>
			{dir === 'rtl' ? (
				<ThemeProvider theme={RTLMuiTheme}>
					<StylesProvider jss={jss}>
						<AppRout locale={locale} />
					</StylesProvider>
				</ThemeProvider>
			) : (
				<ThemeProvider theme={MuiTheme}>
					<AppRout locale={locale} />
				</ThemeProvider>
			)}
		</IntlProvider>
	);
};

const mapStateToProps = (state) => ({
	dir: state.Intl.locale === 'en' ? 'ltr' : 'rtl',
	// dir: 'ltr',
	locale: state.Intl.locale,
	isLoggedIn: !!state.account.token,
});

export default connect(mapStateToProps, { setCount, updateConfig })(App);
