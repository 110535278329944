const colors = {
	primary: '#201C68',
	darkGreen: '#52C4A9',
	primaryLight: '#495368',
	primaryDark: '#09142a',
	secondary: '#2a5077',
	inheritDefault1: '#fefefe',
	inheritDefault2: '#f8f9ff',
	main: '#2b2b2b',
	white: '#ffffff',
	light: '#eaeaea',

	statusGreen: '#18d684',

	second: '#070919',
	indigo: '#7420ff',
	purple: '#793de6',
	pink: '#fc26a4',
	red: '#ff5a5a',
	orange: '#f4772e',
	yellow: '#ffc926',
	green: '#1bc943',
	teal: '#18e1a5',
	cyan: '#27dcf3',
};

export default colors;
