import EmptyContentLottie from 'components/Lottie/EmptyContentLottie';
import { Link } from 'react-router-dom';
import strings from 'translations/translations';

const NotFound = ({ text, toLink = '', fullText = false }) => {
	return (
		<div className="w-1/2 mx-auto flex flex-col justify-center items-center">
			<div className="m-auto mt-20">
				<EmptyContentLottie />
			</div>
			<div className="font-normal py-8 px-0 text-center text-gray-500">
				{fullText ? text : strings.formatString(strings.NotFounded.not_found, text)}‍‍
			</div>
			{toLink !== '' && (
				<Link className="text-center text-primary underline" to={toLink}>
					{strings.NotFounded.toTraders}
				</Link>
			)}
		</div>
	);
};

export default NotFound;
