import * as React from 'react';
import { AppBar } from '@material-ui/core';
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom';

import SideDrawer from './menu/SideDrawer';
import AccountButton from './AccountButton';
import { logout } from 'redux/actions/account';
import { ReactComponent as LeftArrow } from 'assets/icon/left.svg';
import Daraya from 'assets/icon/coinson.png';
import Notification from './Notification';
import connect from 'react-redux/es/connect/connect';
import strings from 'translations/translations';
import { MenuAppBar } from './menu/MenuAppBar';
import NotificationHeader from '../../../Notifications/NotificationsInHeader/NotificationHeader';

import { ReactComponent as Home } from 'assets/icon/home2.svg';
import { ReactComponent as HomeSelected } from 'assets/icon/home3.svg';

import { ReactComponent as Investment } from 'assets/icon/investment_portfolio.svg';
import { ReactComponent as InvestmentSelected } from 'assets/icon/investment_portfolio2.svg';

import { ReactComponent as Wallet } from 'assets/icon/wallet.svg';
import { ReactComponent as WalletSelected } from 'assets/icon/wall2et.svg';

import { ReactComponent as Traders } from 'assets/icon/user_account.svg';
import { ReactComponent as TradersSelected } from 'assets/icon/user_accoun3t.svg';

import { ReactComponent as Consultation } from 'assets/icon/ask_question2.svg';
import { ReactComponent as ConsultationSelected } from 'assets/icon/ask_question.svg';

import { ReactComponent as Support } from 'assets/icon/online_support.svg';
import { ReactComponent as SupportSelected } from 'assets/icon/online_support2.svg';

import { ReactComponent as Academy } from 'assets/icon/student_male2.svg';
import { ReactComponent as AcademySelected } from 'assets/icon/student_male.svg';

import { ReactComponent as How } from 'assets/icon/safety_float.svg';
import { ReactComponent as HowSelected } from 'assets/icon/safety_floaqt.svg';

import { ReactComponent as Invitation } from 'assets/icon/trus2.svg';
import { ReactComponent as InvitationSelected } from 'assets/icon/trust.svg';

import { ReactComponent as Settings } from 'assets/icon/settings3.svg';
import { ReactComponent as SettingsSelected } from 'assets/icon/setting2s.svg';

import { ReactComponent as AreYouTrader } from 'assets/icon/user_account.svg';
import { ReactComponent as AreYouTraderSelected } from 'assets/icon/user_accoun3t.svg';

import { ReactComponent as Logout } from 'assets/icon/logout.svg';
import { ReactComponent as LogoutSelected } from 'assets/icon/logout-1.svg';

import { ReactComponent as QTrader } from 'assets/icon/q_trader.svg';

const baseOfAnotherHost = 'https://coinson.co';

const Header = ({ user, fullWidth, backmap, locale, isLoggedInCustomer, isLoggedInExpert, logout }) => {
	// const isNotif = user.has_call;
	const isNotif = false;
	const location = useLocation();
	const history = useHistory();

	const activeRoute = (routeName) => {
		// console.log(location.pathname, 'location');
		return location.pathname.includes(routeName);
	};

	var showback = false;
	// var url = '';
	for (let i = 0; i < backmap.length; i++) {
		if (!backmap[i].exact) {
			var res = location.pathname.match(backmap[i].regex);
			if (res) {
				if (res.length === backmap[i].length) {
					showback = true;
					// url = backmap[i].url;
				}
			}
		} else {
			if (backmap[i].regex === location.pathname + location.search) {
				showback = true;
			}
		}
	}

	const logOutHandler = async () => {
		logout();
		// history.push({ pathname: '/traders' });
	};

	return (
		<AppBar
			className={`app-bar border-b-1 rtl:border-r-1 ltr:border-l-1 border-[#EAEAEA] ${fullWidth ? '' : 'header-width'}`}
			style={{ boxShadow: 'none', height: isNotif ? 'max-content' : 60 }}
			color="inherit">
			{isNotif && <Notification />}
			<div className="m-auto relative box-border px-8 md:px-14 w-full">
				<div className="flex justify-between md:justify-between">
					<div
						className={`${
							isLoggedInCustomer || isLoggedInExpert
								? 'grid grid-cols-1 gap-4'
								: 'grid grid-cols-1 gap-4 md:hidden'
						}`}>
						<div className="min-height-64px mx-0 flex relative items-center md:ml-8">
							{/* <a target="_self" href={'//' + process.env.REACT_APP_HOME_URL} className="hidden md:block">
								<img className="align-middle w-16 h-16" src={Daraya} alt="daraya" />
							</a> */}
							<div className="hidden md:block">
								{showback && (
									<button
										onClick={(e) => history.goBack()}
										className="bg-[#F7FAFE] py-3 px-4 mx-2 rounded-lg h-full text-primary text-xl font-normal flex items-center">
										<LeftArrow
											width={10}
											height={10}
											style={locale === 'en' ? { transform: 'scale(-1,1)' } : {}}
										/>
										<span className="rtl:pr-4 ltr:pl-4 hidden md:block">{strings.back}</span>
									</button>
								)}
							</div>
							<nav className="hidden mr-8 md:mr-32 md:flex">
								<MenuAppBar />
							</nav>
							<div className="block md:hidden">
								<SideDrawer
									locale={locale}
									logOutHandler={logOutHandler}
									isLoggedIn={isLoggedInCustomer || isLoggedInExpert}
								/>
							</div>
							<div className="block md:hidden">
								{showback && (
									<button
										onClick={(e) => history.goBack()}
										className="bg-[#F7FAFE] py-3 px-4 mx-2 rounded-lg h-full text-primary text-xl font-normal flex items-center">
										<LeftArrow
											width={10}
											height={10}
											style={locale === 'en' ? { transform: 'scale(-1,1)' } : {}}
										/>
										<span className="rtl:pr-4 ltr:pl-4 hidden md:block">{strings.back}</span>
									</button>
								)}
							</div>
						</div>
					</div>

					<div
						className={`hidden ${
							!isLoggedInCustomer && !isLoggedInExpert ? 'md:flex flex-row items-center gap-x-4' : ''
						}`}>
						<a target="_self" href={'//' + process.env.REACT_APP_HOME_URL} className="hidden md:flex items-center">
							<img
								className="align-middle h-10"
								style={{ width: '15rem', height: '3.9rem' }}
								src={Daraya}
								alt="coinson"
							/>
							{/* <span className="rtl:mr-2 ltr:ml-2 font-medium">{strings.sidebar.title}</span> */}
						</a>
						<Link className="flex items-center " to="/traders">
							{/* {activeRoute('traders') ? <TradersSelected width={15} /> : <Traders width={15} />} */}
							<span
								className={`${
									activeRoute('traders') ? 'text-primary font-semibold' : 'text-[#727D8F] font-normal'
								}  rtl:mr-6 ltr:ml-6 text-[1.45rem]`}>
								{strings.sidebar.traders}
							</span>
						</Link>
						{/* <Link className="flex items-center ">
							// <Consultation width={15} /> DO NOT Show when not auth
							<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
								{strings.sidebar.consultations}
							</span>
						</Link> */}
						<a
							className="flex items-center "
							// to={
							// 	locale === 'ar'
							// 		? {
							// 				pathname: 'https://coinson.co/ar/need-help/%d8%a7%d8%ad%d8%b5%d9%84-%d8%b9%d9%84%d9%89-%d8%a7%d9%84%d8%af%d8%b9%d9%85/',
							// 		  }
							// 		: { pathname: 'https://coinson.co/help/support/' }
							// }
							href={
								locale === 'ar'
									? new URL(
											'https://coinson.co/ar/need-help/%d8%a7%d8%ad%d8%b5%d9%84-%d8%b9%d9%84%d9%89-%d8%a7%d9%84%d8%af%d8%b9%d9%85/',
											baseOfAnotherHost,
									  ).href
									: new URL('https://coinson.co/help/support/', baseOfAnotherHost).href
							}
							target="_blank">
							{/* <Support width={15} /> */}
							<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
								{strings.sidebar.support}
							</span>
						</a>
						<a
							className="flex items-center"
							href={
								locale === 'ar'
									? new URL(
											'https://coinson.co/ar/need-help/%d8%a7%d9%84%d8%a3%d9%83%d8%a7%d8%af%d9%8a%d9%85%d9%8a%d8%a9/',
											baseOfAnotherHost,
									  ).href
									: new URL('https://coinson.co/help/academy/', baseOfAnotherHost).href
							}
							// to={
							// 	locale === 'ar'
							// 		? {
							// 				pathname: 'https://coinson.co/ar/need-help/%d8%a7%d9%84%d8%a3%d9%83%d8%a7%d8%af%d9%8a%d9%85%d9%8a%d8%a9/',
							// 		  }
							// 		: { pathname: 'https://coinson.co/help/academy/' }
							// }
							target="_blank">
							{/* <Academy width={15} /> */}
							<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
								{strings.sidebar.academy}
							</span>
						</a>
						<a
							className="flex items-center"
							href={
								locale === 'ar'
									? new URL(
											'https://coinson.co/ar/need-help/%d8%aa%d8%ad%d8%aa%d8%a7%d8%ac-%d9%85%d8%b3%d8%a7%d8%b9%d8%af%d8%a9%d8%9f/',
											baseOfAnotherHost,
									  ).href
									: new URL('https://coinson.co/help/helpcenter/', baseOfAnotherHost).href
							}
							target="_blank">
							{/* <How width={15} /> */}
							<span className="text-[#727D8F] rtl:mr-6 ltr:ml-6 text-[1.45rem] font-normal">
								{strings.sidebar.how_to_use}
							</span>
						</a>
					</div>

					<div className="flex justify-end place-items-center">
						<AccountButton location={location} />
					</div>
				</div>
			</div>
		</AppBar>
	);
};

const mapStateToProps = ({ account, Intl }) => ({
	user: account.user,
	locale: Intl.locale,
	isLoggedInCustomer:
		account.token &&
		account.user.role === 'CUSTOMER' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
	isLoggedInExpert:
		account.token &&
		account.user.role === 'EXPERT' &&
		(typeof account.auth_data.registered === 'undefined' || account.auth_data.registered),
});

export default connect(mapStateToProps, { logout })(withRouter(Header));
