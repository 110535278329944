import API from '../base';
import { query_generator, clean } from 'utils/queryGenerator';

export const getInvestmentPeriodList = ({
	start_amount = null,
	end_amount = null,
	status = null,
	title = null,
	creator_name = null,
	page_size = null,
	page = null,
	my = null,
	profit_start = null,
	profit_end = null,
	trader_id = null,
	profile_best_basket = null,
}) => {
	let query_params = clean({
		start_amount,
		end_amount,
		status,
		title,
		creator_name,
		page_size,
		page,
		my,
		profit_start,
		profit_end,
		trader_id,
		profile_best_basket,
	});
	const params = query_generator(query_params);
	const path = `periods/${params}`;
	return API.GetMethod({ path });
};

export const getInvestmentPeriodInfo = ({ id, timedelta = null }, base) => {
	const params = query_generator(
		clean({
			timedelta,
		}),
	);
	const path = `periods/${id}${params}`;
	return API.GetMethod({ base, path });
};

export const postInvestmentPeriodEnrollment = ({ course_id, data, ...props }) => {
	const path = `periods/${course_id}/enroll`;
	return API.PostMethod({ path, data, ...props });
};

export const getExchangeAccess = () => {
	const path = `periods/add_exchange_access`;
	return API.GetMethod({ path });
};

export const postExchangeAccess = ({ data }) => {
	const path = `periods/add_exchange_access`;
	return API.PostMethod({ path, data });
};

export const deleteInvestmentData = ({ id }) => {
	const path = `period/setting/${id}`;
	return API.DeleteMethod({ path });
};

export const editInvestmentData = ({ id, data }) => {
	const path = `period/setting/${id}`;
	return API.PatchMethod({ path, data });
};

export const getStagePlanInfoWithStageId = ({ stage_id }) => {
	const path = `periods/stages/${stage_id}`;
	return API.GetMethod({ path });
};

export const getStagePlanInfoWithPeriodId = ({ period_id, stage_id }) => {
	const path = `periods/${period_id}/stages/${stage_id}`;
	return API.GetMethod({ path });
};

export const getPaymentNextStage = ({ course_id }) => {
	const path = `periods/${course_id}/pay`;
	return API.GetMethod({ path });
};

export const getEnrollmentKey = ({ course_id }) => {
	const path = `periods/${course_id}/enroll`;
	return API.GetMethod({ path });
};

export const getFuturePositionList = ({ course_id }) => {
	const path = `periods/${course_id}/future_position_list`;
	return API.GetMethod({ path });
};

export const getFutureOrdersList = ({ course_id }) => {
	const path = `periods/${course_id}/future_open_orders_list`;
	return API.GetMethod({ path });
};

export const getFutureHistory = ({ course_id }) => {
	const path = `periods/${course_id}/future_history_list`;
	return API.GetMethod({ path });
};

export const getAssets = ({ course_id }) => {
	const path = `periods/${course_id}/chart_of_assets`;
	return API.GetMethod({ path });
};

export const getCopiers = ({ course_id }) => {
	const path = `periods/${course_id}/copiers_detail`;
	return API.GetMethod({ path });
};
