import Axios from 'axios';
import { initializeStore } from '../redux/store/configureStore.dev';
import * as actionTypes from '../redux/actions/actionTypes';

export const baseURL = process.env.REACT_APP_BASE_URL;

let AuthToken;
let axiosInstance;
const store = initializeStore();

const getHeaders = () => {
	const headers = {};
	if (AuthToken) {
		headers.Authorization = AuthToken;
	}
	return headers;
};

const InitialToken = async () => {
	if (!localStorage.getItem('financeState')) return;
	const userToken = JSON.parse(localStorage.getItem('financeState')).account.token;
	AuthToken = userToken ?? null;
};

const destroy = () => {
	AuthToken = null;
};

const startInstance = async () => {
	await InitialToken();
	CreateAxiosInstance();
};

const axiosWrapper = (axiosArgument) => {
	return new Promise((resolve, reject) => {
		axiosInstance(axiosArgument)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => reject(error));
	});
};

const CreateAxiosInstance = () => {
	axiosInstance = Axios.create({
		maxRedirects: 5, //TODO: is it neccesary?!
		headers: getHeaders(),
	});

	axiosInstance.interceptors.request.use(
		function (config) {
			return config;
		},
		function (error) {
			return Promise.reject(error);
		},
	);

	axiosInstance.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			// TODO: handle errors better
			if (!error?.response?.status || Math.floor(error?.response?.status / 100) === 5) {
				store.dispatch({
					type: actionTypes.SHOW_ERROR_MESSAGE,
					error: 'خظا سرور',
				});
			} else if (error?.response && error?.response?.status === 401) {
				destroy();
			} else if (error?.response && error?.response?.status === 403) {
			} else if (error?.response && error?.response?.status === 404) {
			} else {
			}
			return Promise.reject(error.response || error);
		},
	);
};

const BaseAPIs = {
	async PostMethod(props) {
		const { path, data, url, signal } = props;
		await startInstance();
		let options = {
			headers: props.content_type
				? {
						...this.headers,
						'Content-Type': props.content_type,
				  }
				: this.headers,
			maxContentLength: Infinity,
			maxBodyLength: Infinity,
		};
		return axiosWrapper({
			method: 'POST',
			url: !url ? baseURL + path : url,
			options,
			data,
			signal,
		});
	},

	async GetMethod(props) {
		var { base = baseURL, path, data, signal } = props;
		if (base.length === 0) {
			base = baseURL;
		}
		await startInstance();
		return props.isBlob
			? axiosWrapper({
					method: 'GET',
					url: base + path,
					headers: getHeaders(),
					params: data,
					responseType: 'blob',
					signal,
			  })
			: axiosWrapper({
					method: 'GET',
					url: base + path,
					headers: getHeaders(),
					params: data,
					signal,
			  });
	},

	PatchMethod(props) {
		const { path, data, signal } = props;
		startInstance();
		return axiosWrapper({
			method: 'PATCH',
			url: baseURL + path,
			headers: getHeaders(),
			data,
			signal,
		});
	},

	DeleteMethod(props) {
		const { path, data, signal } = props;
		startInstance();
		return axiosWrapper({
			method: 'DELETE',
			url: baseURL + path,
			headers: getHeaders(),
			data,
			signal,
		});
	},
};

export default BaseAPIs;
