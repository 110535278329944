import * as React from 'react';
import { ReactComponent as Bell } from 'assets/icon/bell.svg';
import { Typography } from '@material-ui/core';
import CustomButton from '../../../CustomButton';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { ReactComponent as LeftArrow } from 'assets/icon/left-arrow.svg';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import connect from 'react-redux/es/connect/connect';
import { dateFormatter } from 'utils/dateFormatter';

const useStyles = makeStyles((theme) => ({
	head: {
		color: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '2rem',
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
			background: '#ffffff',
		},
	},
	notif_container: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 0,
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
		},
	},
	preNotice: {
		backgroundColor: theme.palette.success.status,
	},
	inProgress: {
		backgroundColor: theme.palette.primary.main,
	},
	notif: {
		// backgroundColor:'blue',
		width: '65%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '0.5rem',
		margin: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			width: '90%',
		},
		[theme.breakpoints.between('sm', 'md')]: {
			width: '85%',
		},
	},
	preNotice_inside: {
		justifyContent: 'center',
	},
	inProgress_inside: {
		justifyContent: 'space-between',
	},
	textContainer: {
		// backgroundColor:'red',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	notif_text: {
		color: 'white',
	},
	enter_room_text: {
		color: theme.palette.primary.main,
	},
	enterRoom: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0.5 1 rem',
		borderRadius: '0.5rem',
	},
	backButton: {
		flex: 1,
		float: 'left',
		position: 'absolute',
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
}));

const Notification = (user) => {
	const classes = useStyles();
	const history = useHistory();

	const callStarted = !!user.user.call_link;
	let callInfo = null;
	if (user.user && user.user.call_info) {
		callInfo = {
			expertName: user.user.call_info.expert,
			fromDate: dateFormatter({
				date: user.user.call_info.from_date,
				format: 'HH:mm',
			}),
			toDate: dateFormatter({
				date: user.user.call_info.to_date,
				format: 'HH:mm',
			}),
		};
	} else {
		callInfo = {
			expertName: 'دارایا',
			fromDate: dateFormatter({ date: Date.now(), format: 'HH:mm' }),
			toDate: dateFormatter({ date: Date.now(), format: 'HH:mm' }),
		};
	}
	const notifData = [
		{
			id: 0,
			styleClass: classes.preNotice,
			styleClassInside: classes.preNotice_inside,
			text: `تماس شما با کارشناس ${callInfo.expertName} در ساعت ${callInfo.fromDate} برقرار خواهد شد.`,
		},
		{
			id: 1,
			styleClass: classes.inProgress,
			styleClassInside: classes.inProgress_inside,
			text: `تماس شما با کارشناس ${callInfo.expertName} در ساعت ${callInfo.fromDate} برقرار شده و تا ساعت ${callInfo.toDate} ادامه خواهد داشت.`,
			link: callStarted ? user.user.call_link : 'https://daraya.ir',
		},
	];

	const routeChange = () => {
		let path = notifData[1].link;
		history.push(path);
	};

	return (
		<div className={clsx(classes.notif_container, callStarted ? notifData[1].styleClass : notifData[0].styleClass)}>
			<div className={clsx(classes.notif, callStarted ? notifData[1].styleClassInside : notifData[0].styleClassInside)}>
				<div className={classes.textContainer}>
					<Bell
						style={{
							verticalAlign: 'middle',
							display: 'inline-block',
							width: '2rem',
							height: '2rem',
							marginLeft: '1rem',
						}}
					/>
					<Typography className={classes.notif_text} variant="caption">
						{callStarted ? notifData[1].text : notifData[0].text}
					</Typography>
				</div>
				{callStarted && (
					<div>
						<Link to={{ pathname: notifData[1].link }} target="_blank">
							<CustomButton
								className={classes.enterRoom}
								style={{ backgroundColor: 'white' }}
								onClick={routeChange}>
								<Typography className={classes.enter_room_text} variant="body">
									ورود به اتاق مشاوره
								</Typography>
								<LeftArrow
									style={{
										verticalAlign: 'middle',
										display: 'inline-block',
										width: '1rem',
										height: '1rem',
										marginRight: '1rem',
									}}
								/>
							</CustomButton>
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = ({ account }) => ({
	user: account.user,
});

export default connect(mapStateToProps, {})(withRouter(Notification));
