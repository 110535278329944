import API from '../base';
import { query_generator, clean } from 'utils/queryGenerator';

export const getExpertsList = (
	{
		sort = null,
		search_query = null,
		status = null,
		counsel_type = null,
		job = null,
		page_size = null,
		page = null,
		expert_type = null,
		rate = null,
		category = null,
	},
	base,
) => {
	let query_params = clean({
		sort,
		search_query,
		status,
		counsel_type,
		job,
		page_size,
		page,
		expert_type,
		rate,
		category,
	});
	const params = query_generator(query_params);
	const path = `expert/get_list${params}`;
	return API.GetMethod({
		base,
		path,
	});
};
export const getExpertWithId = ({ expert_id }) => {
	const path = `expert/${expert_id}/profile`;
	return API.GetMethod({
		path,
	});
};

export const postExpertReserve = ({ expert_id, data }) => {
	const path = `expert/${expert_id}/reserve`;
	return API.PostMethod({ path, data });
};

export const getPerformanceChart = ({ course_id }, base) => {
	const path = `periods/${course_id}/performance_chart`;
	return API.GetMethod({
		base,
		path,
	});
};

export const getRoiChart = ({ course_id }, base) => {
	const path = `periods/${course_id}/roi_chart`;
	return API.GetMethod({
		base,
		path,
	});
};
