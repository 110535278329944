import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import api from '../middleware/api/api';
import rootReducer from '../reducers';

let enhancer;
let store;

// if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
// 	enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(thunk, api));
// } else {
// }
enhancer = compose(applyMiddleware(thunk, api));

const configureStore = (preloadedState) => {
	let _store = createStore(rootReducer, { Intl: { locale: 'fa' }, ...preloadedState }, enhancer);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers', () => {
			_store.replaceReducer(rootReducer);
		});
	}

	return _store;
};

export const initializeStore = (preloadedState) => {
	let _store = store;
	if (_store === undefined || _store === null) _store = configureStore(preloadedState);

	if (preloadedState && store) {
		_store = configureStore({
			...store.getState(),
			...preloadedState,
		});
		store = undefined;
	}

	if (typeof window === 'undefined') return _store;
	if (!store) store = _store;

	return _store;
};

export const useStore = (initialState) => initializeStore(initialState);
