import momentJalaali from 'moment-jalaali';
import strings from 'translations/translations';
import { tLocalNum } from './translateNumber';
/* eslint-disable no-loop-func */
export const divideHoursOfDay = (unit) => {
	let remind = 60 / unit;
	let clocks = [];
	for (let i = 8; i < 24; i++) {
		for (let j = 0; j < unit; j++) {
			if (j === 0) {
				clocks.push(`${i}:00`);
			} else {
				clocks.push(`${i}:${remind * j}`);
			}
		}
	}
	return clocks;
};

export const plusHour = (time, amount) => {
	let plus = 60 / amount;
	let minutes = time.split(':')[1];
	let hour = time.split(':')[0];
	let new_time = '';

	if (plus === 1) {
		new_time = `${parseInt(hour) + 1}:${minutes}`;
	} else if (plus === 2) {
		if (minutes + 30 >= 60) {
			new_time = `${parseInt(hour) + 1}:00`;
		} else {
			new_time = `${hour}:${parseInt(minutes) + 30}`;
		}
	} else if (plus === 4) {
		if (minutes < 45) {
			new_time = `${hour}:${parseInt(minutes) + 15}`;
		} else {
			new_time = `${parseInt(hour) + 1}:00`;
		}
	}

	return new_time;
};

export const getCustomFormatDayJalali = (inputValue, isGregorian = false, format = 'jYYYY/jM/jD') => {
	if (!inputValue) return '';
	const inputFormat = format;
	return inputValue.locale('fa').format(inputFormat);
};

export const getSomeFirstTimes = (arr, count) => {
	let result = [];
	for (const [key, value] of Object.entries(arr)) {
		value.map((item) => {
			if (count > 0) {
				result.push({
					id: count,
					day: key,
					label: reservationLabelDate(key),
					time: item.from.split(':')[0] + ':' + item.from.split(':')[1],
				});
				count--;
			} else {
				return 0;
			}
		});
	}
	return result;
};

export const reservationLabelDate = (day) => {
	let today = new Date();
	let tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);
	today = dateToJalali(today);
	tomorrow = dateToJalali(tomorrow);

	let result = day;
	switch (day) {
		case today:
			result = strings.time.today;
			break;
		case tomorrow:
			result = strings.time.tomorrow;
			break;
		default:
			break;
	}
	return result;
};

export const dateToJalali = (date) => {
	date = date.toLocaleDateString('fa-IR').replace(/([۰-۹])/g, (token) => String.fromCharCode(token.charCodeAt(0) - 1728));
	return date
		.split('/')
		.map((item) => {
			if (item.length == 1) {
				return '0' + item;
			} else return item;
		})
		.join('/');
};

export const getDateTimeFormatISO = (date) => {
	const options = {
		hour: 'numeric',
		minute: 'numeric',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		weekday: 'long',
	};
	if (typeof date === 'string' && date.includes('USDT')) return date;
	const convertDate = getCustomeFormatISO(date, options);
	if (convertDate === 'Invalid Date') return date;
	return convertDate;
};

export const getDateFormatISO = (date) => {
	const options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	return getCustomeFormatISO(date, options);
};
export const getCustomeFormatISO = (date, options, lang = 'fa-IR') => {
	const convertDate = new Date(Date.parse(date)).toLocaleDateString(lang, options);
	return convertDate;
};

const setFormatDigitClock = (digit) => {
	if (!digit) return '00';
	return digit < 10 ? '0' + digit : digit;
};

export const getTimeFormatISO = (date) => {
	const nw = new Date(Date.parse(date));
	return setFormatDigitClock(nw.getHours()) + ':' + setFormatDigitClock(nw.getMinutes());
};

export const getCustomeFormatRtlISO = (date, options = {}) => {
	return (
		getCustomeFormatISO(date, { weekday: 'long' }) +
		' ' +
		getCustomeFormatISO(date, { day: 'numeric' }) +
		' ' +
		getCustomeFormatISO(date, { month: 'long' }) +
		' ' +
		getCustomeFormatISO(date, { year: 'numeric' })
	);
};

export const getCustomeFormatLtrISO = (date, options = {}) => {
	return (
		getCustomeFormatISO(date, { weekday: 'long' }, 'en-US') +
		' ' +
		getCustomeFormatISO(date, { day: 'numeric' }, 'en-US') +
		' ' +
		getCustomeFormatISO(date, { month: 'long' }, 'en-US') +
		' ' +
		getCustomeFormatISO(date, { year: 'numeric' }, 'en-US')
	);
};

export const getDateTimeRtlFormat = (date) => {
	return (
		getCustomeFormatISO(date, { day: 'numeric' }) +
		' ' +
		getCustomeFormatISO(date, { month: 'long' }) +
		tLocalNum(getTimeFormatISO(date))
	);
};

export const getDateTimeLtrFormat = (date) => {
	return (
		getCustomeFormatISO(date, { day: 'numeric' }, 'en-US') +
		' ' +
		getCustomeFormatISO(date, { month: 'long' }, 'en-US') +
		' ' +
		getTimeFormatISO(date)
	);
};

export const remainingTimeFromNow = (date) => {
	let end_time = new Date(date).getTime();
	let start_time = new Date().getTime();
	let result = {};
	result.minute = Math.floor((end_time - start_time) / (60 * 1000));
	result.second = Math.floor((end_time - start_time) / 1000) - result.minute * 60;
	return result;
};

export const amountTimeElapsed = (date, limit) => {
	let now = new Date().getTime();
	let pastDate = new Date(date).getTime();
	let second = Math.floor((now - pastDate) / 1000);
	let minute = Math.floor((now - pastDate) / (60 * 1000));
	let hour = Math.floor((now - pastDate) / (60 * 60 * 1000));
	let day = Math.floor((now - pastDate) / (24 * 60 * 60 * 1000));
	if (day >= limit) {
		return new Date(date).toLocaleDateString('fa');
	}
	if (day < limit && day > 1) {
		return strings.formatString(strings.time.past, {
			val: strings.cardinal_number[day],
			unit: strings.time.day,
		});
	}
	if (day === 1) {
		return strings.time.yesterday;
	}
	if (hour > 6) {
		return strings.time.today;
	}
	if (hour > 0) {
		return strings.formatString(strings.time.past, {
			val: strings.cardinal_number[hour],
			unit: strings.time.hours,
		});
	}
	if (minute > 0) {
		return strings.time.few_minutes_ago;
	}
	return strings.time.few_moments_ago;
};
