import { Box } from '@material-ui/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import { OutlinedInput, IconButton, makeStyles, InputAdornment, Button } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { checkRegisteredEmail } from '../../../redux/actions/account';
import strings from 'translations/translations';
import { loginByEmailPassword, resetAuth } from '../../../redux/actions/account';
import EmailInput from '../../Input/EmailInput';
import PasswordInput from '../../Input/PasswordInput';
import * as pages from './pages';
import FromTemplate from './pageTemplate/FormTemplate';
import CustomButton from '../../CustomButton';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	input: {
		borderWidth: '1.6px',
		'&.Mui-focused': {
			borderColor: 'rgb(59 130 246)',
			borderWidth: '1.6px',
		},
	},
}));

function EmailAndPassword({ resetAuth, setStep, setCurrentPage, checkRegisteredEmail, loginByEmailPassword, handleSubmit = () => {} }) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const classes = useStyles();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	// const onSubmit = async () => {
	// 	await loginByEmailPassword({ email, password });
	// };

	const onSubmit = async (e) => {
		setStep(1);
		setIsSubmitting(true);
		await checkRegisteredEmail({
			email: email,
		});
		setIsSubmitting(false);
	};

	return (
		<div
			className={
				'shadow-white sm:shadow-lg bg-white flex justify-center h-[44rem] flex-col rounded-2xl sm:border-1 border-slate-300 px-10 pt-5 pb-2 w-full'
			}>
			<div className="self-start text-center text-3xl font-bold py-5 sm:pb-10">
				<span>{strings.auth.email.title}</span>
			</div>

			<div className="my-4">
				<div className="">
					<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
						{strings.auth.email.email}
					</label>
					<label className="relative block">
						<input
							style={{ height: '60px' }}
							className=" font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none"
							onChange={(event) => setEmail(event.target.value)}
							value={email}
							type="email"
							name="email"
						/>
					</label>
				</div>
			</div>

			<div className="my-4">
				<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
					{strings.auth.email.password}
				</label>
				<OutlinedInput
					id="outlined-adornment-password-login"
					type={showPassword ? 'text' : 'password'}
					value={password}
					className={classNames(
						classes.input,
						'pr-0 font-4xl font-bold text-4xl px-4 text-blue-600 rounded-xl py-0 focus:ring-blue-500 focus:border-blue-500 focus:ring-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1',
					)}
					onChange={(event) => setPassword(event.target.value)}
					dir="rtl"
					endAdornment={
						<InputAdornment position="end">
							<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
								{showPassword ? (
									<Visibility style={{ fontSize: '2rem' }} />
								) : (
									<VisibilityOff style={{ fontSize: '2rem' }} />
								)}
							</IconButton>
						</InputAdornment>
					}
					style={{ fontSize: '1.5rem', fontWeight: '500' }}
					inputProps={{
						paddingRight: '0',
						classes: {
							direction: 'rtl',
							paddingRight: '0',
							fontSize: '2rem',
						},
					}}
				/>
			</div>

			<div className="my-4 pb-5">
				<button onClick={() => setCurrentPage(pages.PHONE)} className="block mb-1 text-2xl font-medium text-blue-600">
					{strings.auth.email.login_with_phone}
				</button>
			</div>
			<div className="my-4 pb-5">
				<Button
					disableElevation
					disabled={isSubmitting}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					color="primary"
					onClick={onSubmit}>
					<span className="text-3xl py-4 font-bold">{strings.auth.login}</span>
				</Button>
			</div>
		</div>
	);
}

export default connect(null, { loginByEmailPassword, resetAuth, checkRegisteredEmail })(EmailAndPassword);
