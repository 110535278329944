import React from 'react';
import { ReactComponent as Empty } from 'assets/icon/empty.svg';
import Lottie from 'react-lottie';
import animationData from 'lotties/emptyContent.json';
import classnames from 'classnames';

const EmptyContentLottie = ({ height, width, className }) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	const customHeight = height ? height : '100%';
	const customWeight = width ? width : '100%';
	const customStyle = { cursor: 'default', customHeight, customWeight };
	return (
		<div className={classnames(className ? className : 'flex justify-center')}>
			<Empty isClickToPauseDisabled={true} style={customStyle} />
		</div>
	);
};

export default EmptyContentLottie;
