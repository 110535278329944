import * as actionTypes from './actionTypes';

export const enqueueSnackbar = ({ key = new Date().getTime() + Math.random(), ...notification }) => ({
	type: actionTypes.ENQUEUE_SNACKBAR,
	notification: {
		...notification,
		key,
	},
});

export const closeSnackbar = (key) => ({
	type: actionTypes.CLOSE_SNACKBAR,
	dismissAll: !key,
	key,
});

export const removeSnackbar = (key) => ({
	type: actionTypes.REMOVE_SNACKBAR,
	key,
});

export const showSuccessMessage = () => ({
	type: actionTypes.SHOW_SUCCESS_MESSAGE,
});

export const showErrorMessage = () => ({
	type: actionTypes.SHOW_ERROR_MESSAGE,
});

export const removeNotifications = () => ({
	type: actionTypes.REMOVE_NOTIFICATIONS,
});

export const showSidebarNotifications = () => ({
	type : actionTypes.SHOW_SIDEBAR_NOTIFICATIONS
});

export const closeSidebarNotifications = () => ({
	type : actionTypes.CLOSE_SIDEBAR_NOTIFICATIONS
});