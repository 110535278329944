import { CircularProgress } from '@material-ui/core';

const Loading = ({size = 80}) => {
  return (
    <div className='w-full h-screen flex justify-center items-center p-8'>
      <CircularProgress size={size} />
    </div>
  );
};

export default Loading;
