import { IconButton, makeStyles } from '@material-ui/core';
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import strings from 'translations/translations';

import { logout } from 'redux/actions/account';
import CustomButton from '../../../../CustomButton';

import { UserIcon } from './AccountManagement';

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: 'none',
	},
	exitButton: {
		marginLeft: theme.spacing(1),
	},
}));

const AccountManagement = connect(null, { logout })(
	withRouter(({ history, logout }) => {
		const classes = useStyles();

		const logOutHandler = () => {
			history.push('/courses');
			logout();
		};

		return (
			<>
				<Link to="/dashboard">
					<CustomButton className="rounded-md w-48 h-16 flex items-center justify-center p-2 border-zeroPlus border-solid border-account_button-logged hover:opacity-60">
						<span className="text-primary">{strings.account_management}</span>
						<UserIcon />
					</CustomButton>
				</Link>
				<IconButton className={classes.exitButton} onClick={logOutHandler}>
					<ExitToAppIcon className="text-2base" />
				</IconButton>
			</>
		);
	}),
);

export default AccountManagement;
