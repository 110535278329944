import { Button } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from 'react-redux-multilingual/lib/context';

import { removeSnackbar } from '../../redux/actions/notifications';
import AuthDialog from '../Dialog/AuthDialog';

let displayed = [];

const Errors = ({ errors = [], removeSnackbar }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [loginDialogOpen, setLoginDialogOpen] = useState(false);

	const storeDisplayed = (id) => {
		displayed = [...displayed, id];
	};

	const removeDisplayed = (id) => {
		displayed = [...displayed.filter((key) => id !== key)];
	};

	const t = useTranslate();

	useEffect(() => {
		errors &&
			errors.length > 0 &&
			errors.forEach(({ key, message, options = {}, dismissed = false }) => {
				if (
					!message.includes('phone_number') &&
					!message.includes('cc_number') &&
					!message.includes('password') &&
					!message.includes('email') &&
					!message.includes('first_name') &&
					!message.includes('last_name') &&
					!message.includes('price') &&
					!message.includes('code')
				) {
					if (message !== '401') {
						if (dismissed) {
							closeSnackbar(key);
							return;
						}
						if (displayed.includes(key)) return;

						enqueueSnackbar(message, {
							key,
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'left',
							},
							TransitionComponent: (props) => <Grow {...props} />,
							...options,
							onClose: (event, reason, myKey) => {
								if (options.onClose) {
									options.onClose(event, reason, myKey);
								}
							},
							onExited: (event, myKey) => {
								if (errors.length > 0) {
									removeSnackbar(myKey);
									removeDisplayed(myKey);
								}
							},
							action: (
								<Button
									onClick={() => {
										if (errors.length > 0) {
											closeSnackbar(key);
											removeSnackbar(key);
											removeDisplayed(key);
										}
									}}>
									{t('dismiss')}
								</Button>
							),
						});
					} else {
						setLoginDialogOpen(true);
					}
				}

				storeDisplayed(key);
			});
	}, [errors, closeSnackbar, enqueueSnackbar, t, removeSnackbar]);

	return loginDialogOpen ? <AuthDialog open={loginDialogOpen} handleClose={() => setLoginDialogOpen(false)} /> : null;
};

const mapStateToProps = (state) => ({
	errors: state.notifications.notifications.errors,
});

export default connect(mapStateToProps, { removeSnackbar })(Errors);
