import { Fragment } from 'react';
import { connect } from 'react-redux';

import { removeNotifications } from '../../../redux/actions/notifications';

function Success({ notifications, removeNotifications }) {
	return (
		<Fragment>
			{notifications.messages && notifications.messages.length > 0 && <div>{notifications.messages}</div>}
			<button onClick={() => removeNotifications()} className="bg-primary">
				<span>أكمل</span>
			</button>
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	notifications: state.notifications.notifications,
});

export default connect(mapStateToProps, { removeNotifications })(Success);
