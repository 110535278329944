export function hasChildren(item) {
	const {
		items: children
	} = item;

	if (children === undefined || children.constructor !== Array || children.length === 0) {
		return false;
	}


	return true;
}