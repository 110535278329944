import * as actionTypes from '../actions/actionTypes';

const initState = {
	coursesTabList: [],
};

function copyTrading(state = initState, action) {
	switch (action.type) {
		case actionTypes.ADD_TAB_COURSE:
			if (state.coursesTabList.map((item) => item.id).indexOf(action.payload.course.id) !== -1) {
				return {
					...state,
					coursesTabList: [
						...state.coursesTabList.filter((item) => item.id !== action.payload.course.id),
						action.payload.course,
					],
				};
			} else {
				return {
					...state,
					coursesTabList: state.coursesTabList.concat(action.payload.course),
				};
			}

		default:
			return state;
	}
}

export default copyTrading;
