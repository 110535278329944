export const query_generator = (q) => {
	let query = "?";

	for (const property in q) {
		query += `${property}=${q[property]}&`;
	}

	return query.substr(0, query.length - 1);
}

export const convertArrayToObj = (arr) => {
	return Object.assign({}, ...arr);
}

export const clean = (obj) => {
	for (let propName in obj) {
		if (obj[propName] === null || obj[propName] === undefined) {
			delete obj[propName];
		}
	}
	return obj;
}