import { makeStyles, Typography } from '@material-ui/core';

import statusColor from './constants/statusColors';
import strings from '../../translations/translations'
import { string } from 'yup/lib/locale';

const useStyles = makeStyles((theme) => ({
  root: ({ size, status }) => ({
    color: statusColor[status],
  }),
}));

const Status = ({ size, status }) => {
  const classes = useStyles({ size, status });
  const statusStrings = {
    online : strings.online,
    offline : strings.offline,
    busy : strings.busy,
  }
  return (
    <Typography className={classes.root} component="small" variant="subtitle2">
      {status ? statusStrings.status: ''}
    </Typography>
  );
};

export default Status;
