import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';

import CustomButton from '../../../CustomButton';

const useStyles = makeStyles((theme) => ({
	title: {
		textAlign: 'center',
		width: '100%',
		marginBottom: '1.5rem',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(2, 0),
			textAlign: 'center',
		},
	},
	subTitle: {
		fontWeight: 'normal',
		color: '#999999',
	},
	grid: {
		width: '80%',
		minHeight: '100%',
		margin: '0 auto',
		padding: theme.spacing(2, 0, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1, 0),
		},

		'& > div': {
			padding: '1rem 0',
		},
	},
	fullHeight: {
		height: '100%',
		fontWeight: 500,
	},
	fullWidth: {
		width: '100%',
		margin: '0 auto',
	},
	formAction: {
		margin: theme.spacing(1, 0),
	},
	submitButton: {
		background: `${theme.palette.primary.main} !important`,
		width: '100% !important',
		height: '4.5rem !important',
		padding: '1rem',
		borderRadius: '3rem',
		margin: '0 auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '1.5rem',
		fontWeight: '500',
		color: theme.palette.white.main,
		outline: 'none',
	},
	backButton: {
		background: `${theme.palette.white.main} !important`,
		width: '100% !important',
		height: '4.5rem !important',
		padding: '1rem',
		borderRadius: '3rem',
		margin: '1rem auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '1.5rem',
		fontWeight: '500',
		color: theme.palette.primary.main,
		border: `0.1rem solid ${theme.palette.primary.main} !important`,
		outline: 'none',
	},
}));

export default function FromTemplate({ title, subTitle, formContent, formAction, submitText = 'أكمل', onSubmit, backText, backAction }) {
	const classes = useStyles();

	return (
		<ValidatorForm className={classes.fullHeight} onSubmit={onSubmit} instantValidate={false}>
			<Grid container direction="column" justify="space-between" alignItems="stretch" className={classes.grid}>
				<Grid container alignItems="center" justify="center">
					<h2 className={classes.title}>{title}</h2>
					<span className={classes.subTitle}>{subTitle}</span>
				</Grid>
				<div className={classes.fullWidth}>{formContent}</div>
				<div className={classes.fullWidth}>
					<div className={classes.formAction}>{formAction}</div>
					<button className="btn-primary my-4 py-3" type="submit">
						{submitText}
					</button>
					{backText && (
						<button
							className={'outline-btn-primary my-2 py-3 hover:shadow-md hover:bg-white/95'}
							onClick={backAction}>
							{backText}
						</button>
					)}
				</div>
			</Grid>
		</ValidatorForm>
	);
}
