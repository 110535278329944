import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { openAuthDialog } from '../../redux/actions/account';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const HomepageWrapper = ({ isLoggedIn, openAuthDialog }) => {
	const history = useHistory();
	const classes = useStyles();
	const { search } = useLocation();

	const [openBackDrop, setOpenBackDrop] = useState(false);

	useEffect(() => {
		let auth = new URLSearchParams(search).get('auth');
		if (!auth) {
			history.push(!isLoggedIn ? '/traders' : '/traders');
		} else {
			history.push('/traders');
			if (!isLoggedIn) {
				openAuthDialog();
			}
		}

		return () => {
			setOpenBackDrop(false);
		};
	}, []);

	return (
		<>
			<Backdrop className={classes.backdrop} open={openBackDrop}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

const mapStateToProps = (state) => ({
	isLoggedIn: state.account.token,
});

export default connect(mapStateToProps, { openAuthDialog })(HomepageWrapper);
