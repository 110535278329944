import { useState } from 'react';
import { connect } from 'react-redux';

import { Row, Input } from './Styles';

const PasswordInput = ({ passErrors, errors, onChange = () => {}, ...props }) => {
	const [value, setValue] = useState('');

	const handleChange = (e) => {
		setValue(e.target.value);
		onChange(e.target.value);
	};

	return (
		<Row>
			<Input
				errors={errors && errors.length > 0 && errors[0].message.includes('password') && true}
				required
				type="password"
				value={value}
				onChange={handleChange}
				{...props}
			/>
			{errors && errors.length > 0 && errors[0].message.includes('password') && (
				<span>{errors[0].message.split(' ').slice(1).join(' ')}</span>
			)}
			{passErrors && <span>{passErrors}</span>}
		</Row>
	);
};

const mapStateToProps = ({ notifications }) => ({
	errors: notifications.notifications.errors,
});

export default connect(mapStateToProps)(PasswordInput);
