import { Box } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import strings from 'translations/translations';
import { register } from '../../../redux/actions/account';
import CustomVerificationCode from '../../Input/CustomVerificationCode';
import { OutlinedInput, IconButton, makeStyles, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import translateNumber from '../../../utils/translateNumber.js';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Checkbox from '@material-ui/core/Checkbox';
import PasswordInput from '../../Input/PasswordInput';
import SendCodeBtn from './components/SendCodeBtn';
import FromTemplate from './pageTemplate/FormTemplate';
import classNames from 'classnames';
import * as pages from './pages';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import { ReactComponent as Cross } from '../../../assets/icon/cross-circle.svg';
import { ReactComponent as Tick } from '../../../assets/icon/tick-circle.svg';

const useStyles = makeStyles((theme) => ({
	input: {
		borderWidth: '1.6px',
		'&.Mui-focused': {
			borderColor: 'rgb(59 130 246)',
			borderWidth: '1.6px',
		},
	},
	backIcon: {
		'& svg': {
			fontSize: 30,
			color: 'rgb(59 130 246)',
			opacity: 0.8,
		},
	},
}));

function CodeAndPassword({ auth_data, register, errors, locale, setCurrentPage, setStep }) {
	const [code, setCode] = useState('');
	const [password, setPassword] = useState('');
	const [phoneCountry, setPhoneCountry] = useState('');
	const [country, setCountry] = useState('ae');
	const [passErrors, setPassErrors] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [passwordValidation, setPasswordValidation] = useState([true, true, true, true]);
	const [checkBool, setCheckBool] = useState(false);
	const classes = useStyles();
	const passwordEl = useRef();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	function containsSpecialChars(str) {
		const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		return specialChars.test(str);
	}

	function containsNumber(str) {
		return /[0-9]/.test(str);
	}

	function isUpper(str) {
		return /[A-Z]/.test(str);
	}

	function isLower(str) {
		return /[a-z]/.test(str);
	}

	const phoneCountryFormat = React.useMemo(() => phoneCountry?.format || undefined, [phoneCountry]);
	const placeholder = React.useMemo(() => {
		if (phoneCountryFormat) {
			const words = phoneCountryFormat.split(' ');
			words.shift(); // I'm using only local numbers so here I remove the country code from the format
			let text = words.join(' ');
			// first digit is special on french numbers, these 3 lines could be removed
			if (country === 'fr') {
				text = text.replace('.', '6');
			}
			while (text.indexOf('.') > -1) {
				text = text.replace('.', `${Math.min(9, Math.max(1, Math.floor(Math.random() * 10)))}`);
			}
			return text;
		}
		return '';
	}, [phoneCountryFormat, country]);

	const onSubmit = async () => {
		setStep(2);
		await register({
			code,
			password,
			email: auth_data.phone_number,
			phone_number: translateNumber({ lang: 'en', num: phoneCountry.toString().slice(-11) }),
			referal: auth_data.referal.length > 0 ? auth_data.referal : '.',
		});
	};

	useEffect(() => {
		const cp_validation = [...passwordValidation];
		if (password.length < 8) {
			cp_validation[0] = true;
		} else {
			cp_validation[0] = false;
		}
		if (!isUpper(password)) {
			cp_validation[1] = true;
		} else {
			cp_validation[1] = false;
		}
		if (!isLower(password)) {
			cp_validation[2] = true;
		} else {
			cp_validation[2] = false;
		}
		if (!containsNumber(password)) {
			cp_validation[3] = true;
		} else {
			cp_validation[3] = false;
		}
		setPasswordValidation(cp_validation);
	}, [password]);
	const styles = {
		largeIcon: {
			width: 160,
			height: 160,
		},
	};

	const back = (e) => {
		setStep(0);
		setCurrentPage(pages.PHONE);
	};
	return (
		<div className="relative shadow-white sm:shadow-md sm:border sm:border-[#EAEBEC] bg-white flex justify-center h-[82rem] flex-col rounded-2xl sm:border-1 border-slate-300 px-10 pt-5 pb-2 w-full">
			<div className="self-start text-center text-3xl font-bold pt-5">
				<span>{strings.auth.create_account}</span>
			</div>

			<div className="mb-4 mt-2">
				<div className="">
					<Box mb={3} mt={1}>
						<div
							className="text-right pb-6 font-normal ltr:text-left rtl:text-right"
							style={{
								fontSize: '17px',
								lineHeight: '3rem',
							}}>
							{strings.formatString(strings.auth.enter_code, {
								phone_number: auth_data.phone_number,
							})}
						</div>

						<CustomVerificationCode
							length={6}
							placeholder=""
							isInvalid={false}
							onChange={(newCode) => {
								if (newCode.length === 6 && newCode !== code) {
									document.getElementById('outlined-adornment-login').focus();
								}
								setCode(newCode);
							}}
						/>
					</Box>
				</div>
				<SendCodeBtn />
				<div className="my-4 pb-0">
					<button onClick={back} for="Name" className="block mb-1 text-2xl font-medium text-blue-600">
						{strings.auth.change_email}
					</button>
				</div>
				<hr className="block w-full mt-8 opacity-80 bg-blue-200 mb-6" />
				<div className="">
					<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
						{strings.auth.password}
					</label>
					<OutlinedInput
						id="outlined-adornment-login"
						type={showPassword ? 'text' : 'password'}
						value={password}
						ref={passwordEl}
						inputRef={passwordEl}
						itemRef={passwordEl}
						className={classNames(
							classes.input,
							'pr-0 font-4xl font-bold text-4xl px-4 text-blue-600 rounded-xl py-0 focus:ring-blue-500 focus:border-blue-500 focus:ring-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-gray-200 rounded-md py-4  ltr:pl-3 ltr:pr-9 rtl:pl-9 rtl:pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1',
						)}
						onChange={(event) => setPassword(event.target.value)}
						dir={locale === 'en' ? 'ltr' : 'rtl'}
						endAdornment={
							<InputAdornment position="end" className={`${locale === 'en' ? 'hidden' : ''}`}>
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end">
									{showPassword ? (
										<Visibility style={{ fontSize: '2rem' }} />
									) : (
										<VisibilityOff style={{ fontSize: '2rem' }} />
									)}
								</IconButton>
							</InputAdornment>
						}
						style={{ fontSize: '1.5rem', fontWeight: '500', height: '59px' }}
						// placeholder="رمز عبور"
						inputProps={{
							paddingRight: '0',
							classes: {
								direction: 'rtl',
								paddingRight: '0',
								fontSize: '2rem',
							},
						}}
					/>
					<div className="mt-4 ltr:ml-3">
						<div className="flex items-center my-2">
							{passwordValidation[0] ? <Cross /> : <Tick />}
							<span
								className={`rtl:mr-4 ltr:ml-4 ${
									passwordValidation[0] ? 'text-validationError' : 'text-validationSucces'
								} font-normal`}>
								{strings.formatString(strings.auth.validation.character_number, {
									num: 8,
								})}
							</span>
						</div>
						<div className="flex items-center my-2">
							{passwordValidation[1] ? <Cross /> : <Tick />}
							<span
								className={`rtl:mr-4 ltr:ml-4 ${
									passwordValidation[1] ? 'text-validationError' : 'text-validationSucces'
								} font-normal`}>
								{strings.auth.validation.captal_character}
							</span>
						</div>
						<div className="flex items-center my-2">
							{passwordValidation[2] ? <Cross /> : <Tick />}
							<span
								className={`rtl:mr-4 ltr:ml-4 ${
									passwordValidation[2] ? 'text-validationError' : 'text-validationSucces'
								} font-normal`}>
								{strings.auth.validation.small_character}
							</span>
						</div>
						<div className="flex items-center my-2">
							{passwordValidation[3] ? <Cross /> : <Tick />}
							<span
								className={`rtl:mr-4 ltr:ml-4 ${
									passwordValidation[3] ? 'text-validationError' : 'text-validationSucces'
								} font-normal`}>
								{strings.auth.validation.number}
							</span>
						</div>
						<div></div>
					</div>
				</div>

				<hr className="block w-full mt-8 opacity-80 bg-blue-200 mb-6" />
				<div className="my-4">
					<div className="">
						<label for="Name" className="block mb-1 pb-4 text-2xl font-medium">
							{strings.auth.phone_num}
						</label>
						<div className="flex flex-row">
							{/* <input
								style={{ width: '7rem' }}
								className="ltr:mr-3 rtl:ml-3 font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 focus:outline-none"
								onChange={(event) => setCountry(event.target.value)}
								value={country}
							/> */}
							{/* <PhoneInput
								style={{ height: '60px' }}
								className="phoneInput font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none"
								onChange={setEmail}
								value={email}
								type="phone_number"
								name="phone_number"
								placeholder="536948481"
								containerStyle={{
									border: '0px !important',
									outline: 'none !important',
								}}
								// inputComponent={}
								inputStyle={{
									border: '0px !important',
									outline: 'none !important',
									// background: "transpa"
								}}
							/> */}
							<PhoneInput
								// country={'us'}
								containerStyle={{}}
								excludeCountries={['il']}
								// className="phoneInput font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none"
								// onChange={setEmail}
								preferredCountries={['ae', 'sa', 'eg', 'tr', 'lb', 'bh', 'qa', 'iq', 'om', 'sy']}
								regions={['middle-east', 'north-africa']}
								value={phoneCountry}
								type="phone_number"
								name="phone_number"
								placeholder={placeholder}
								country={country}
								// value={this.state.phone}
								onChange={(phone) => setPhoneCountry(phone)}
							/>
							{/* <label className="relative block flex flex-auto">
								<input
									style={{ height: '60px' }}
									className=" font-medium text-2xl px-4 text-blue-600 rounded-xl py-6 focus:border-blue-500 focus:border-blue-500 focus:border-1.6 placeholder:italic placeholder:text-slate-400 block bg-white w-full border-1.6 border-gray-200 rounded-md py-4 pl-9 pr-3 focus:outline-none"
									onChange={(event) => setEmail(event.target.value)}
									value={email}
									type="phone_number"
									name="phone_number"
									placeholder="536948481"
								/>
							</label> */}
						</div>
					</div>
				</div>
			</div>
			<div className="flex items-center rtl:-mr-2 ltr:-ml-2 my-4 pb-5">
				<Checkbox
					classes={{ root: 'custom-checkbox-root' }}
					checked={checkBool}
					onChange={(e) => {
						setCheckBool(e.target.checked);
					}}
					color="primary"
					inputProps={{ 'aria-label': 'secondary checkbox', fontSize: '5rem' }}
				/>
				<span className="">
					<a href="https://coinson.co/terms" class="text-blue-600 dark:text-blue-500 hover:underline">
						{strings.auth.term_of_service}
					</a>{' '}
					<span className="">{strings.auth.accept_term_of_service}</span>
				</span>
			</div>

			<div className="my-4 pb-5">
				<Button
					disableElevation
					id="registered"
					disabled={
						phoneCountry.length === 0 ||
						isSubmitting ||
						passwordValidation.filter((e) => e).length > 0 ||
						code.length < 6 ||
						!checkBool
					}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					color="primary"
					className="btn-disabled"
					onClick={onSubmit}>
					<span className="text-3xl py-4">{strings.continues}</span>
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = ({ account, notifications, Intl }) => ({
	locale: Intl.locale,
	auth_data: account.auth_data,
	errors: notifications.notifications.errors,
});

export default connect(mapStateToProps, { register })(CodeAndPassword);
