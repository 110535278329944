import * as actionTypes from '../actions/actionTypes';

const initState = {
  reservations: [],
  availableTimes: {
    date: [],
    reservations: [],
    start_hour: [],
    duration: [],
    price: '',
    price_str: '',
  },
  availableNow: {
    start_hour: [],
    available_durations: [],
    price: '',
    price_str: '',
    can_reserve_now: {},
  },
};
function reservation(state = initState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_RESERVATIONS_SUCCESS:
      return {
        ...state,
        reservations: action.response,
      };
    case actionTypes.CANCEL_RESERVATION_SUCCESS:
      const index = state.reservations.findIndex(
        (e) => +e.id === +action.response.reservation.id
      );

      if (index === -1) {
        return {
          ...state,
          reservations: [...state.reservations, action.response.reservation],
        };
      }
      state.reservations[index] = action.response.reservation;
      return {
        ...state,
        reservations: state.reservations,
      };
    case actionTypes.GET_TODAY_RESERVATIONS_SUCCESS:
      return {
        ...state,
        availableTimes: action.response,
      };
    case actionTypes.GET_AVAILABLE_TIME_SUCCESS:
      return {
        ...state,
        availableTimes: action.response,
      };
    case actionTypes.GET_AVAILABLE_HOUR_SUCCESS:
      return {
        ...state,
        availableTimes: action.response,
      };
    case actionTypes.GET_AVAILABLE_DURATION_SUCCESS:
      return {
        ...state,
        availableTimes: action.response,
      };
    case actionTypes.GET_AVAILABLE_PRICE_SUCCESS:
      return {
        ...state,
        availableTimes: action.response,
      };
    case actionTypes.GET_NOW_AVAILABLE_DURATIONS_SUCCESS:
      return {
        ...state,
        availableNow: action.response,
      };
    case actionTypes.GET_NOW_AVAILABLE_PRICE_SUCCESS:
      return {
        ...state,
        availableNow: action.response,
      };
    case actionTypes.RESERVATIONS_SUCCESS:
      return {
        ...state,
        reservations: action.response,
      };
    case actionTypes.SELF_RESERVATION_SUCCESS:
      return {
        ...state,
        reservations: [...state.reservations, action.response.new_reservation],
      };
    default:
      return state;
  }
}

export default reservation;
