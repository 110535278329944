import * as actionTypes from '../actions/actionTypes';

const initState = {
    experts: [],
    comments: {},
    reports: [],
    publications: [],
    contents: [],
    schedules: {},
    scheduleList: {},
    tweets: [],
    reserve_payment: { new_reservation: { id: null } },
    course_data: null,
};

function expert(state = initState, action) {
    switch (action.type) {
        case actionTypes.EXPERT_DASHBOARD_SUCCESS:
            return {
                ...state,
                tweets: action.response.tweets,
            };
        case actionTypes.GET_EXPERT_LIST_SUCCESS:
            return {
                ...state,
                experts: action.response,
            };
        case actionTypes.GET_EXPERT_SUCCESS:
            const index = state.experts.findIndex((e) => +e.id === +action.response.id);

            if (index === -1) {
                return {
                    ...state,
                    experts: [...state.experts, action.response],
                };
            }
            state.experts[index] = action.response;
            return {
                ...state,
                experts: state.experts,
                tweets: action.response.tweets,
            };
        case actionTypes.CHANGE_MARK_SUCCESS:
            return {
                ...state,
                experts: state.experts.map((expert) =>
                    expert.id === action.payload.expert
                        ? {
                              ...expert,
                              is_marked: !expert.is_marked,
                          }
                        : expert,
                ),
            };
        case actionTypes.EXPERT_RESERVE_SUCCESS:
            return {
                ...state,
                reserve_payment: action.response,
            };
        case actionTypes.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
            };
        case actionTypes.GET_COMMENTS_SUCCESS:
            return {
                ...state,
                comments: action.response,
            };
        case actionTypes.GET_COMMENTS_DASHBOARD_SUCCESS:
            return {
                ...state,
                comments: action.response,
            };
        case actionTypes.GET_NOTIFICATION_INBOX_SUCCESS:
            return {
                ...state,
                reports: action.response,
            };
        case actionTypes.RESPONSE_TO_COMMENTS_SUCCESS:
            return {
                ...state,
            };
        case actionTypes.RESET_WORK_SCHEDULE_SUCCESS:
            return {
                ...state,
            };
        case actionTypes.GET_WORK_SCHEDULE_DISPLAY_SUCCESS:
            return {
                ...state,
                scheduleList: action.response,
            };
        case actionTypes.GET_PUBLICATIONS_CONTENTS_SUCCESS:
            return {
                ...state,
                contents: action.response,
            };
        case actionTypes.CHANGE_MARK_CONTENTS_SUCCESS:
            return {
                ...state,
                contents: state.contents.map((content) =>
                    content.id === action.payload.courseId
                        ? {
                              ...content,
                              is_marked: !content.is_marked,
                          }
                        : content,
                ),
            };
        case actionTypes.PUBLICATION_GET_LIST_SUCCESS:
            return {
                ...state,
                publications: action.response,
            };
        case actionTypes.DISPLAY_PUBLICATION_SUCCESS:
            const indexPublication = state.publications.findIndex((e) => +e.id === +action.response.id);

            if (indexPublication === -1) {
                return {
                    ...state,
                    publications: [...state.publications, action.response],
                };
            }
            state.publications[indexPublication] = action.response;
            return {
                ...state,
                publications: state.publications,
            };
        case actionTypes.SUBMIT_TWEET_SUCCESS:
            return {
                ...state,
                tweets: [...state.tweets, action.response.new_tweet],
            };
        case actionTypes.DELETE_TWEET_SUCCESS:
            return {
                ...state,
                tweets: state.tweets.filter((tweet) => tweet.id !== action.response.tweet.id),
            };
        case actionTypes.LIKE_TWEET_SUCCESS:
            return {
                ...state,
                tweets: state.tweets.map((tweet) =>
                    tweet.id === action.response.tweets.id
                        ? {
                              ...tweet,
                              is_liked: !tweet.is_liked,
                              total_likes: tweet.is_liked ? tweet.total_likes - 1 : tweet.total_likes + 1,
                          }
                        : tweet,
                ),
            };
        case actionTypes.RESET_PAYMENT:
            return {
                ...state,
                reserve_payment: { new_reservation: { id: null } },
            };
        case actionTypes.COPY_TRADING_SUCCESS:
            return {
                ...state,
                course_data: action.response,
            };
        default:
            return state;
    }
}

export default expert;
