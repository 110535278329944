import { useState } from 'react';
import { connect } from 'react-redux';

import { Row, Input } from './Styles';

const EmailInput = ({ errors, onChange = () => {}, ...props }) => {
	const [value, setValue] = useState('');

	const handleChange = (e) => {
		setValue(e.target.value);
		onChange(e.target.value);
	};

	return (
		<Row>
			<Input
				errors={errors && errors.length > 0 && errors[0].message.includes('email') && true}
				required
				type="email"
				value={value}
				placeholder="بريد الإلكتروني"
				onChange={handleChange}
				{...props}
			/>
			{errors && errors.length > 0 && errors[0].message.includes('email') && (
				<span>{errors[0].message.split(' ').slice(1).join(' ')}</span>
			)}
		</Row>
	);
};

const mapStateToProps = ({ notifications }) => ({
	errors: notifications.notifications.errors,
});

export default connect(mapStateToProps)(EmailInput);
