import { useInfiniteQuery, useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { clean } from 'utils/queryGenerator';
import API from '../api/base';

export const fetcher = async ({ queryKey, pageParam }) => {
	const [url, params] = queryKey;
	return await API.GetMethod({ path: url, data: params }).then((res) => res.data);
};

export const useFetch = (url, params = {}, config = {}) => {
	const clean_params = clean(params);
	const context = useQuery([url, clean_params], ({ queryKey }) => fetcher({ queryKey }), {
		...config,
	});

	return context;
};
