import * as actionTypes from '../actions/actionTypes';

const initState = {
	token: null,
	user: {
		role: '',
		full_name: '',
		image: '',
		first_name: '',
		last_name: '',
		email: '',
		cc_number: '',
		phone_number: '',
	},
	id: null,
	about: '',
	job: {
		title: '',
	},
	auth_data: {
		password: '',
		email: '',
		referal: '',
	},
	schedule: [],
	authModal: false,
	question: {},
};

function account(state = initState, action) {
	switch (action.type) {
		case actionTypes.UPDATE_CONFIG:
			return {
				...state,
				user: {
					...state.user,
					image: action.payload.user.image,
					full_name: action.payload.user.name,
				},
			};
		case actionTypes.CHECK_REGISTERED_SUCCESS:
			return {
				...state,
				auth_data: {
					...state.auth_data,
					registered: action.response.is_registered,
					phone_number: action.payload.phone_number,
					referal: action.payload.referal,
				},
			};
		case actionTypes.CHECK_REGISTERED_SUCCESS_EMAIL:
			return {
				...state,
				auth_data: {
					...state.auth_data,
					registered: action.response.is_registered,
					email: action.payload.email,
				},
			};
		case actionTypes.REGISTER_SUCCESS:
			return {
				...state,
				token: action.response.token,
				question: action.response.Question,
				auth_data: {
					password: action.payload.password,
					...state.auth_data,
				},
			};
		case actionTypes.SET_ACCOUNT_DATA_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					...action.response,
				},
				auth_data: {
					...state.auth_data,
					registered: true,
				},
				authModal: false,
			};
		case actionTypes.GET_PROFILE_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					...action.response,
				},
			};
		case actionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					image: action.response.image,
				},
			};
		case actionTypes.PHONE_AUTH_SUCCESS:
			return {
				...state,
				auth_data: {
					...state.auth_data,
					code_sent: true,
				},
				// user: {
				// 	...state.user,
				// 	role: action.payload.phone_number,
				// },
			};
		case actionTypes.LOGIN_SUCCESS:
			return {
				...state,
				token: action.response.token,
				auth_data: {
					...state.auth_data,
					code_sent: action.payload.code,
					password: action.payload.password,
				},
				user: {
					...state.user,
					role: action.response.role,
					phone_number: action.payload.phone_number,
				},
				authModal: false,
			};
		case actionTypes.EXPERT_SETTINGS_SUCCESS:
			return {
				...state,
				user: action.response.new_settings.user,
				about: action.response.new_settings.about,
			};
		case actionTypes.EXPERT_DASHBOARD_SUCCESS:
			return {
				...state,
				id: action.response.id,
				user: action.response.user,
				job: action.response.job,
			};
		case actionTypes.EXPERT_NEW_SETTINGS_SUCCESS:
			return {
				...state,
				user: action.response.user,
				about: action.response.about,
				schedule: action.response.schedule,
			};
		case actionTypes.RESET_WORK_SCHEDULE_SUCCESS:
			return {
				...state,
				schedule: action.response.schedule,
			};
		case actionTypes.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				token: action.response.token,
				auth_data: {
					password: action.payload.new_password,
					...state.auth_data,
				},
			};
		case actionTypes.CHANGE_PHONE_NUMBER_SUCCESS:
			return {
				...state,
				token: action.response.token,
				user: {
					phone_number: action.payload.phone_number,
					...state.user,
				},
			};
		case actionTypes.RESET_AUTH:
			return {
				...state,
				auth_data: {},
				user: {},
				token: null,
			};
		case actionTypes.RESET_PHONE:
			return {
				...state,
				auth_data: {
					...state.auth_data,
					registered: false,
					phone_number: '',
					referal: '',
				},
			};

		case actionTypes.OPEN_AUTH_DIALOG:
			return {
				...state,
				authModal: true,
			};
		case actionTypes.CLOSE_AUTH_DIALOG:
			return {
				...state,
				authModal: false,
			};
		case actionTypes.LOGOUT_REQUEST:
			return initState;
		default:
			return state;
	}
}

export default account;
