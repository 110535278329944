import React, { useRef, useState, useEffect, Suspense } from 'react';
import { getInvestmentPeriodList } from '../../api/expert/copyTrading';
import { Logger } from '../../utils/functions.js';
import { ExpertShortCard } from 'components/Card/ExpertCard/ExpertShortCard';
import strings from 'translations/translations';
import Loading from 'components/Loading';
import AnchorElTooltips from './AnchorElTooltips';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Timer from '../Timer/Timer';
import Avatar from 'react-avatar';
import classnames from 'classnames'
import { removeSnackbar } from '../../redux/actions/notifications';
import * as actionTypes from '../../redux/actions/actionTypes';
import { initializeStore } from 'redux/store/configureStore.dev';
import ReactGA from 'react-ga'
import { openAuthDialog } from 'redux/actions/account';
import SwipeableTemporaryDrawer from 'components/Notifications/NotificationsSideBar';
import { showSidebarNotifications, closeSidebarNotifications } from 'redux/actions/notifications';



const TestPage = ({ state = [], errors = [], removeSnackbar, openAuthDialog, showSidebarNotifications }) => {
	const store = initializeStore();
	const [dataReserve, setDataReserve] = useState([]);
	///dispatch({ type: RESET_PAYMENT });

	const expertTradingListHandler = async () => {
		try {
			const result = await getInvestmentPeriodList({ trader_id: 1 });
			if (result.status / 100 < 3) {
				// setTradingList(result.data.results);
				// console.log('trader ->', result.data.results)
			}
		} catch (e) {
			Logger(e, 'expertTradingListAPI');
		}
	};

	useEffect(() => {
		// console.log( encryptText('Hello World') )

		setTimeout(() => {
			store.dispatch({
				type: actionTypes.SHOW_ERROR_MESSAGE,
				error: 'خطایی رخ داده است!',
			})
		}, 4000);
	}, []);

	useEffect(() => {
		expertTradingListHandler();
		let clocks = divideHours(2);
		let mockData = [];

		for (let i = 0; i < clocks.length; i++) {
			mockData.push({
				id: i + 1,
				busy:
					(clocks[i].split(':')[0] > 8 && clocks[i].split(':')[0] < 14) ||
					(clocks[i].split(':')[0] > 20 && clocks[i].split(':')[0] < 24),
				time: clocks[i],
			});
		}
		setDataReserve(mockData);
	}, []);

	const divideHours = (unit) => {
		let remind = 60 / unit;
		let clocks = [];
		for (let i = 8; i < 24; i++) {
			for (let j = 0; j < unit; j++) {
				if (j === 0) {
					clocks.push(`${i}:00`);
				} else {
					clocks.push(`${i}:${remind * j}`);
				}
			}
		}
		return clocks;
	};


	return (
		<div className="divide-y-4 divide-gray-100 mt-32">
			<div className="bg-[#1ff222]/25 text-center">
				<div className="group text-xl">
					<h3 className="block my-4 group-hover:text-green-500">one</h3>
					<h3 className="block my-4 group-hover:text-blue-500">two</h3>
				</div>
			</div>
			<button
				className="btn-primary w-24"
			>
				{strings.cancel}
			</button>

			<button
				onClick={() => openAuthDialog()}
				className='bg-gray-600 text-gray-200 rounded-md  p-4 block mx-auto class-hover-opacity'
			>
				open modal
			</button>
			<button
				onClick={() => showSidebarNotifications()}
				className='bg-green-600 text-gray-200 rounded-md  p-4 block mx-auto class-hover-opacity'
			>
				open notification side bar
			</button>
			<SwipeableTemporaryDrawer />

			<div className="box-border m-0 mt-2 w-full">
				<div className="mt-8">
					<div className="flex px-8 justify-between mb-4">
						<h3>{strings.trader.courses_being_held}</h3>
						<h4 className="text-primary">{strings.trader.see_all_periods}</h4>
					</div>

					<div className="divide-y-4 divide-gray-100">
						<div className="flex p-3 overflow-x-auto overflow-y-hidden">
							<Suspense fallback={<Loading />}>
								{[...Array(3).keys()].map((item, index) => (
									<ExpertShortCard expert={mockTrader[index]} className={'w-max h-84'} />
								))}
							</Suspense>
						</div>
					</div>
				</div>
			</div>
			<button
				onClick={() => {
					ReactGA.event({
						category: 'User',
						action: 'Created an Account'
					});
				}}
				className='bg-purple-300 text-blue-900 p-4'
			>
				test
			</button>

			<ListScrollToItem />
			{/* <div className='w-100 h-100 bg-purple-400 text-center text-2base font-medium text-white m-auto'>
                <div className='mt-1/2'>hover</div>
            </div> */}
			<AnchorElTooltips />

			{/* <div className="relative w-48 h-48">
				<img className="rounded-full border-4 border-status-offline absolute" src={Avatar} alt="user" />
				<img className="absolute bottom-0 right-0 h-12 w-12 z-2" src={MedalWinner} alt="medal" />
			</div> */}
			{/* <ProfilePicture status={'online'} img={Avatar} /> */}
			<div className='flex justify-around'>
				<Avatar name="Foo Bar" round={true} />

			</div>
			<Link
				to={{
					pathname: '/experts/2',
					state: 'ali',
					profile: mockTrader[0].user,
				}}
				className="p-4 mx-auto">
				{' '}
				ho{' '}
			</Link>
			<Timer minute={0} second={10} endAction={(values) => { }} />
			{/* <Loading /> */}
			{/* <input id="test" type="password" readonly onfocus="this.removeAttribute('readonly');" /> */}
			<input type="password" name="Password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"></input>
		</div>
	);
};
const mapStateToProps = (state) => ({
	errors: state.notifications.notifications.errors,
	state: state
});

export default connect(mapStateToProps, { removeSnackbar, openAuthDialog, showSidebarNotifications })(TestPage);


const mockTrader = [
	{
		id: 1,
		user: {
			id: 3,
			full_name: 'مصطفی جدیدی',
			role: 'TRADER',
			first_name: 'مصطفی',
			last_name: 'جدیدی',
			image: 'https://s3.daraya.ir/staging/media/profile_images/3.jpeg',
		},
		job: 'متخصص',
		status: 'online',
		is_marked: false,
		about: 'متخصص بازار ارزهای دیجیتال و فارکس',
		is_chat_active: false,
		is_telephone_active: true,
		counsel_type: 'Crypto',
		call_rate: 4,
		total_contents: 120,
		total_comments: 10,
		total_reservations: 1,
		detail_period: {
			number_courses: 12,
			followers: 932,
		},
		tags: ['crypto', 'forex', 'exchange'],
		efficiency_monthly: 10,
		efficiency_activity: 35,
		rate: 2,
	},
	{
		id: 2,
		user: {
			id: 18,
			full_name: 'سروش قنبرپور',
			role: 'TRADER',
			first_name: 'سروش',
			last_name: 'قنبرپور',
			image: 'https://s3.daraya.ir/staging/media/profile_images/18.jpeg',
		},
		job: 'متخصص',
		status: 'online',
		is_marked: false,
		about: 'متخصص بورس و بازارهای مالی',
		is_chat_active: true,
		is_telephone_active: true,
		counsel_type: 'Crypto',
		call_rate: 2,
		total_contents: 2,
		total_comments: 5,
		total_reservations: 1,
		detail_period: {
			number_courses: 12,
			followers: 932,
		},
		tags: ['crypto'],
		efficiency_monthly: 40,
		efficiency_activity: '12-',
		rate: 1,
	},
	{
		id: 3,
		user: {
			id: 2840,
			full_name: 'آرش قنبرپور',
			role: 'TRADER',
			first_name: 'آرش',
			last_name: 'قنبرپور',
			image: 'https://s3.daraya.ir/staging/media/profile_images/2840.jpeg',
		},
		job: 'متخصص',
		status: 'busy',
		is_marked: false,
		about: 'متخصص بازار ارزهای دیجیتال',
		is_chat_active: true,
		is_telephone_active: true,
		counsel_type: 'Crypto',
		call_rate: 5,
		total_contents: 12,
		total_comments: 0,
		total_reservations: 21,
		detail_period: {
			number_courses: 1,
			followers: 92,
		},
		tags: ['crypto', 'forex', 'exchange'],
		efficiency_monthly: 10,
		efficiency_activity: '10',
		rate: 5,
	},
];

const mockList = [
	{
		id: 'a',
		firstname: 'Robin',
		lastname: 'Wieruch',
		year: 1988,
		to: 'c',
	},
	{
		id: 'b',
		firstname: 'Dave',
		lastname: 'Davidds',
		year: 1990,
		to: 'd',
	},
	{
		id: 'c',
		firstname: 'Robin',
		lastname: 'Davidds',
		year: 1988,
		to: 'b',
	},
	{
		id: 'd',
		firstname: 'Dave',
		lastname: 'Wieruch',
		year: 1990,
		to: 'a',
	},
	{
		id: 'e',
		firstname: 'ali',
		lastname: 'tai',
		year: 1990,
		to: 'a',
	},
];

const ListScrollToItem = () => {

	const [list, setList] = useState();
	const [refs, setRefs] = useState();
	const [select, setSelect] = useState();

	useEffect(() => {
		setTimeout(() => {
			setList(mockList)
			let tmp_ref = mockList.reduce((acc, value) => {
				acc[value.id] = React.createRef();
				return acc;
			}, {});
			// console.log(tmp_ref)
			setRefs(tmp_ref)
		}, 5000)

	}, [list])



	// Scroll Into View API: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
	const handleClick = (id) => {
		setSelect(id)
		refs[id].current.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
		setTimeout(() => setSelect(null), 1000)
	}

	return (
		<ul>
			{list && refs && list?.map((item) => {
				return (
					<div
						key={item.id}
						ref={refs[item.id]}
						className={classnames(
							' w-64 p-6 mx-auto my-3',
							select === item.id ? 'border-blue-300 shadow-md bg-blue-100' : 'border-blue-100 bg-gray-100'
						)}
					>
						<div>{item.id}</div>
						<div>{item.firstname}</div>
						<div>{item.lastname}</div>
						<div>{item.year}</div>
						<button type="button"
							className='inline-block px-3 ml-3 rounded-lg font-medium bg-purple-100 text-primary'
							onClick={() => handleClick(item.to)}>
							Scroll Into {item.to}
						</button>
					</div>
				);
			})}
		</ul>
	);
};