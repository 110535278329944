import styled from 'styled-components';
import '../../assets/styles/CustomVerificationCode.scss';

import { useEffect } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';

export const StyledSeconds = styled.div`
	margin-top: 20px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.002em;
	color: rgba(255, 255, 255, 0.4);
`;

const StyledReactInputVerificationCode = styled.div`
	display: flex;
	justify-content: center;

	--ReactInputVerificationCode-itemWidth: 40px;
	--ReactInputVerificationCode-itemHeight: 48px;
	--ReactInputVerificationCode-itemSpacing: 8px;

	.ReactInputVerificationCode__item {
		font-size: 16px;
		font-weight: 500;
		color: #201c68;

		background: #fff;
		border: 1px solid ${({ isInvalid }) => (isInvalid ? '#EF6C65' : 'rgba(28, 30, 60, 0.4)')};
		border-radius: 4px;
		box-shadow: none;
	}

	.ReactInputVerificationCode__item.is-active {
		box-shadow: 0 0 4px #201c68;
		border: 1px solid #201c68;
	}
`;

export default function CustomVerificationCode({ isInvalid, ...rest }) {
	useEffect(() => {
		document.querySelector('.ReactInputVerificationCode__item').focus();
	}, []);
	return (
		<div style={{ direction: 'ltr' }}>
			<StyledReactInputVerificationCode isInvalid={isInvalid}>
				<ReactInputVerificationCode {...rest} />
			</StyledReactInputVerificationCode>
		</div>
	);
}
