import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import { ReactComponent as Users } from '../../../assets/icon/usersCard.svg';
import { ReactComponent as Return } from '../../../assets/icon/time-past.svg';
import { ReactComponent as Wallet } from '../../../assets/icon/walletCard.svg';

import strings from 'translations/translations';
import { tLocalNum } from '../../../utils/translateNumber';
import ProfilePicture from '../../User/ProfilePicture';
import Status from '../../User/Status';
import CustomButton from '../../CustomButton';

const useStyles = makeStyles((theme) => ({
	divider: {
		width: '100%',
		height: '0.6rem',
		background: '#e9f4ff',
		opacity: '0.86',
	},
	header: {
		backgroundColor: '#f6fbff',
		padding: theme.spacing(2, 4, 2, 2),
		borderTopLeftRadius: '1rem',
		borderTopRightRadius: '1rem',
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1),
		},
	},
	expert: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			justifyContent: 'center',
			textAlign: 'center',
			margin: theme.spacing(2, 'auto'),
		},
	},
	expertPictureContainer: {
		padding: 0,
		borderRadius: 60,
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			display: 'table',
		},
	},
	credit: {
		background: '#fff5d5',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: '1rem 2rem',
		maxWidth: 'max-content',
		borderRadius: '0.5rem',
		color: '#ffbc00',
		'& > *': {
			marginRight: 3,
		},
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
			margin: 'auto',
		},
	},
	expertNameStatus: {
		marginBottom: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
		},
	},
	expertFullName: {
		marginRight: 3,
		color: theme.palette.primary.main,
	},
}));

const RISK_MAP = {
	0: '1',
	1: '3',
	3: '5',
};

const ReservePageHeader = ({ expert, account, risk_state = 0, monthly_profit = 50, aum = 1000 }) => {
	const classes = useStyles();

	return (
		<Fragment>
			<Grid className={classes.header} container justify="space-between" alignItems="center" style={{ position: 'relative' }}>
				<Grid item className={classes.expert} lg={8} md={8} xs={12} sm={6}>
					<Link to={`/experts/${expert.id}`}>
						<CustomButton className={classes.expertPictureContainer}>
							<ProfilePicture
								radius={0.4}
								background="#fff"
								size={8}
								status={expert.status}
								img={expert.user.image && expert.user.image}
								name={expert?.user.full_name}
							/>
						</CustomButton>
					</Link>
					<div>
						<div className={classes.expertNameStatus}>
							<Link to={`/experts/${expert.id}`}>
								<CustomButton style={{ padding: 0 }}>
									<Typography component="h3" variant="h4" className={classes.expertFullName}>
										{expert.user.full_name}
									</Typography>
								</CustomButton>
							</Link>
							<Status status={expert.status} />
						</div>
						<Typography component="div" variant="h5">
							{expert.job && expert.job.title}
						</Typography>
					</div>
				</Grid>
				<Grid>
					<div className="w-28 h-28 mb-2 rtl:mr-2 ltr:ml-2 rounded-lg flex justify-center items-center flex-col bg-lightBlue">
						<Wallet width={17} height={17} className="ltr:hidden" style={{ marginTop: '.5rem' }} />
						<span className="text-primary text-2xl mt-2 rtl:font-medium ltr:font-normal">
							$<span className="rtl:!font-sans rtl:font-medium ltr:font-normal">{aum}</span>
						</span>
						<span className="text-primary text-base font-normal text-center">{strings.courseCard.aum}</span>
					</div>
				</Grid>
				<Grid>
					<div className="w-28 h-28 mb-2 rtl:mr-2 ltr:ml-2 rounded-lg flex justify-center items-center flex-col bg-lightGreen">
						<Return width={17} height={17} style={{ marginTop: '.5rem' }} />
						<span className="text-darkGreen text-2xl mt-2 rtl:font-medium ltr:font-normal flex flex-row-reverse">
							<div className="rtl:font-medium ltr:font-normal rtl:dir-ltr">{monthly_profit}</div>
							<span>%</span>
						</span>
						<span className="text-darkGreen text-lg font-normal">
							{strings.courseCard.return_of_investment.amonth}
						</span>
					</div>
				</Grid>
				<Grid>
					<div className="w-28 h-28 bg-[#ffae4a40] mb-2 rtl:mr-2 ltr:ml-2 rounded-lg flex justify-center items-center flex-col">
						<span className="text-[#F07726] text-3xl font-bold -mb-2">{RISK_MAP[risk_state]}</span>
						<span className="text-[#F07726] text-lg -mb-2">{strings.risk}</span>
					</div>
				</Grid>
				{/* <Grid item lg={4} md={4} xs={12} sm={6} className={classes.credit}>
					<Typography component="span" variant="h5" style={{ color: '#ffbc00' }}>
						اعتبار فعلی شما:
					</Typography>
					<Typography component="h3" variant="h4">
						{account.user.wallet_str || tLocalNum(0)}
					</Typography>
				</Grid> */}
			</Grid>
			<div className={classes.divider} />
		</Fragment>
	);
};

const mapStateToProps = ({ account }) => ({
	account: account,
});

export default connect(mapStateToProps)(ReservePageHeader);
